import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

interface IMenuItem {
  type: string; // Possible values: link/dropDown/separator/extLink
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  icon?: string; // Material icon name
  tooltip?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  icon?: string;
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {
  constructor() {}
  iconMenu: IMenuItem[] = [

    {
      name: "DASHBOARD",
      type: "link",
      tooltip: "DASHBOARD",
      icon: "speed",
      state: "dashboard/analytics"
    },
    {
      name: "GENERAL",
      type: "link",
      tooltip: "GENERAL",
      icon: "settings",
      state: "settings/general"
    },
    {
      name: "USERS",
      type: "link",
      tooltip: "USERS",
      icon: "person",
      state: "users"
    },
    {
      name: "GROUPS",
      type: "link",
      tooltip: "GROUPS",
      icon: "group",
      state: "groups"
    },
    {
      name: "ROLES",
      type: "link",
      tooltip: "ROLES",
      icon: "admin_panel_settings",
      state: "roles"
    },
    {
      name: "EMAIL",
      type: "link",
      tooltip: "EMAIL",
      icon: "email",
      state: "settings/email"
    },
    {
      name: "TEXT_SMS",
      type: "link",
      tooltip: "TEXT_SMS",
      icon: "textsms",
      state: "settings/text_sms"
    },
    {
      name: "LOGO",
      type: "link",
      tooltip: "LOGO",
      icon: "cloud",
      state: "settings/logo"
    },
    {
      name: "RESOURCES",
      type: "dropDown",
      tooltip: "RESOURCES",
      icon: "category",
      state: "resources",
      sub: [
        { name: "LOCATIONS", state: "locations" },
        { name: "BUILDINGS", state: "buildings" },
        { name: "ROOMS", state: "rooms" },
        { name: "LOBBIES", state: "lobbies" }
      ]
    },
    // {
    //   name: "SETTINGS",
    //   type: "dropDown",
    //   tooltip: "SETTINGS",
    //   icon: "settings",
    //   state: "settings",
    //   sub: [
    //     { name: "GENERAL", state: "general" },
    //     //{ name: "DOMAINS", state: "domains" },
    //     { name: "EMAIL", state: "email" },
    //     { name: "TEXT_SMS", state: "text_sms" },
    //     { name: "TRANSLATIONS", state: "translations" },
    //     { name: "CUSTOM_FIELDS", state: "custom_fields" },
    //     { name: "LOGO", state: "logo" }
    //   ]
    // },
    {
      name: "SETUP",
      type: "dropDown",
      tooltip: "SETUP",
      icon: "construction",
      state: "setup",
      sub: [
        {
          name: "CONTROLLER_CMS",
          type: "dropDown",
          state: "controllercms",
          sub: [
            { name: "GENERAL", state: "general" },
            { name: "PHOTOS", state: "photos" },
            { name: "PROFILES", state: "profiles" },
            //{ name: "MESSAGES", state: "messages" },
            { 
              name: "CREDENTIALS",
              type: "dropDown", 
              state: "credentials",
              sub: [
                { name: "GENERAL_CRED_SETTINGS", state: "general_cred_settings" },
                { name: "CRED_CATEGORIES", state: "cred_cats" },
                { name: "CRED_TYPES", state: "cred_types" },
              ]
            },
            // { name: "CREDENTIAL_COLUMNS", state:"credential_columns" },
            // { name: "CREDENTIAL_BLOCK_REASON", state:"credential_block_reason"},
            { name: "CUSTOM_FIELD_LABELS", state: "custom_field_labels" },
            { name: "DEPARTMENTS", state: "departments" },
            { name: "COST_ACCOUNTS", state: "cost_accounts" },
            { name: "PAYMENT_ARTICLES", state: "payment_articles" },
            { name: "EXT_COMPANIES", state: "ext_companies" },
            { name: "NOTIFICATIONS", state: "notifications"}
          ]
        },
        {
          name: "STAND",
          type: "dropDown",
          state: "stand",
          sub: [
            { name: "GENERAL", state: "general" },
            { name: "PHOTOS", state: "photos" },
            { name: "DETAILS_FIELD", state: "details_field" },
            { name: "STAND_WIZARD", state: "wizard" },
            { name: "STAND_TEMPLATES", state: "stand_templates" }
            //{ name: "MESSAGES", state: "messages" },
            // { name: "PAYMENT_ARTICLES", state: "payment_articles" }
          ]
        },
        /*{
          name: "PRESS",
          type: "",
          state: "press",
          sub: [
          ]
        },
        {
          name: "ADMISSION",
          type: "",
          state: "admission",
          sub: [
          ]
        },
        {
          name: "GUEST",
          type: "",
          state: "guest",
          sub: [
          ]
        },
        {
          name: "PLANNER",
          type: "",
          state: "planner",
          sub: [
          ]
        },*/
      ]
    },
    /*{
      name: "CONNECTORS",
      type: "dropDown",
      tooltip: "CONNECTORS",
      icon: "share",
      state: "connectors",
      sub: [
        { name: "IN_CONNECTORS", state: "incoming" },
        { name: "OUT_CONNECTORS", state: "outgoing" }
      ]
    },*/
    {
      name: "PROCESSES",
      type: "dropDown",
      tooltip: "PROCESSES",
      icon: "mediation",
      state: "processes",
      sub: [
        { name: "ACTIONS", state: "actions" },
        { name: "BUSINESS_RULES", state: "business_rules" },
        { name: "WORKFLOWS", state: "workflows" }
      ]
    },
    {
      name: "ADD_INS",
      type: "dropDown",
      tooltip: "ADD_INS",
      icon: "settings_input_component",
      state: "addins",
      sub: [
        {
          name: "HID_CONNECT",
          type: "dropDown",
          state: "hid_connect",
          sub: [
            { name: "GENERAL", state: "general" },
            { name: "TEMPLATES", state: "templates" }
          ]
        },
        {
          name: "SALTO_SPACE",
          type: "dropDown",
          state: "salto_space",
          sub: [
            { name: "GENERAL", state: "general" },
            { name: "MAPPINGS", state: "mappings"}
          ]
        },
        {
          name: "HID_MOBILE_ACCESS",
          state: "hid_mobile",
          type: "link"
        }
        
         /* {
          name: "SAFE_TRUST",
          state: "safe_trust"
        },*/
      ]
    },
    {
      name: "DATA_ARCHIVE",
      type: "dropDown",
      tooltip: "DATA_ARCHIVE",
      icon: "archive",
      state: "archive",
      sub: [
        { name: "ARCHIVE_PERSONS", state: "archive_persons" },
        { name: "RESTORE_PERSONS", state: "restore_persons" }
      ]
    },
    {
      name: "MESSAGES",
      type: "dropDown",
      tooltip: "MESSAGES",
      icon: "message",
      state: "setup",
      sub: [
        { name: "CONTROLLER_CMS", state: "controllercms/messages" },
        { name: "STAND", state: "stand/messages" }
      ]
    },
    {
      name: "CE_CLOUD_NOTIFICATIONS",
      type: "link",
      tooltip: "CE_CLOUD_NOTIFICATIONS",
      icon: "sms",
      state: "ce_cloud_notifications"
    }
  ];


  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle: string = "Frequently Accessed";
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.iconMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    this.menuItems.next(this.iconMenu);
  }
}
