export class CardStatusDb {
    public static status = [
        {
            "code": "Withdrawn",
            "langKey": "Withdrawn"
        },
        {
            "code": "Blocked",
            "langKey": "Blocked"
        },
        {
            "code": "Initialized",
            "langKey": "Initialized"
        },
        {
            "code": "InProduction",
            "langKey": "In Production"
        },
        {
            "code": "Approved",
            "langKey": "Approved"
        },
        {
            "code": "Printed",
            "langKey": "Printed"
        },
        {
            "code": "Activated",
            "langKey": "Activated"
        },
        {
            "code": "Expired",
            "langKey": "Expired"
        }
    ]
}