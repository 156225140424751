export class DigitalCardSystemsDB {
    public static system = [
        {
            "code": "HidMobileAccess",
            "langKey": "hid_mobile_access",
            "id": "423e58f0-334c-4380-9e1b-361300f1c157"
        },
        {
            "code": "SaltoSpace",
            "langKey": "salto_space",
            "id": "a40df49a-3da4-418d-bfd6-b05e63165042"
        }
    ]
}