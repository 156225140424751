export class WorkflowEventsDB {

    public static events = [
        {
            "code": "on_schedule",
            "langKey": "ON_SCHEDULE"
        },
        {
            "code": "on_person_status_change",
            "langKey": "ON_PERSON_STATUS_CHANGE"
        },
        {
            "code": "on_person_created",
            "langKey": "ON_PERSON_CREATED"
        },
        {
            "code": "on_person_updated",
            "langKey": "ON_PERSON_UPDATED"
        },
        {
            "code": "on_person_archived",
            "langKey": "ON_PERSON_ARCHIVED"
        },
        {
            "code": "on_person_restored",
            "langKey": "ON_PERSON_RESTORED"
        },
        {
            "code": "on_person_deleted",
            "langKey": "ON_PERSON_DELETED"
        },
        {
            "code": "on_photo_added",
            "langKey": "ON_PHOTO_ADDED"
        },
        {
            "code": "on_photo_rejected",
            "langKey": "ON_PHOTO_REJECTED"
        },
        {
            "code": "on_photo_approved",
            "langKey": "ON_PHOTO_APPROVED"
        },
        {
            "code": "on_photo_release",
            "langKey": "ON_PHOTO_RELEASE"
        },
        {
            "code": "on_profile_status_change",
            "langKey": "ON_PROFILE_STATUS_CHANGE"
        },
        {
            "code": "on_profile_created",
            "langKey": "ON_PROFILE_CREATED"
        },
        {
            "code": "on_profile_updated",
            "langKey": "ON_PROFILE_UPDATED"
        },
        {
            "code": "on_profile_deleted",
            "langKey": "ON_PROFILE_DELETED"
        },
        {
            "code": "on_credential_status_change",
            "langKey": "ON_CREDENTIAL_STATUS_CHANGE"
        },
        {
            "code": "on_credential_created",
            "langKey": "ON_CREDENTIAL_CREATED"
        },
        {
            "code": "on_credential_updated",
            "langKey": "ON_CREDENTIAL_UPDATED"
        },
        {
            "code": "on_credential_deleted",
            "langKey": "ON_CREDENTIAL_DELETED"
        }
    ]
}