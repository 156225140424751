import { Injectable, NgZone, Injector } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";

import { catchError, retry, tap } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';

import { OidcSecurityService } from 'angular-auth-oidc-client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from '../components/error-snackbar/error-snackbar.component';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class HttpAuthenticationInterception implements HttpInterceptor {
  
  private oidcSecurityService: OidcSecurityService;
  constructor(private zone: NgZone, 
              private injector: Injector, 
              private snackBar: MatSnackBar,
              private toastr: ToastrService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const modifiedRequest = this.getModifiedRequest(request);

    const req$ = next.handle(modifiedRequest).pipe(
      //retry(1),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.oidcSecurityService.authorize();
          retry(1);
        } else if (err.status === 400) {
          this.zone.run(_ => {
            this.getErrors(err.error.errors).forEach(warningMessage => {
              this.toastr.warning(warningMessage, null, {
                timeOut: 5000
              });
            });
          });
        } else if (err.status === 409) {
          this.oidcSecurityService.logoff();
        } else {
          this.zone.run(_ => {
            // this.snackBar.openFromComponent(ErrorSnackbarComponent,
            //   {
            //     data: err,
            //     duration: 5000
            //   });
            this.toastr.error(err.message, err.status.toString(), {
              closeButton: true,
              disableTimeOut: true,
              tapToDismiss: false
            });
          });
        }
        const reqObj = {
          ...err,
          request: request.body || {}
        };
        return throwError(reqObj);
      })
    );
    return req$;
  }

  private getModifiedRequest(req: HttpRequest<any>): HttpRequest<any> {
    if (this.oidcSecurityService === undefined) {
      this.oidcSecurityService = this.injector.get(OidcSecurityService);
    }
    
    const token = this.oidcSecurityService.getToken();
    const reqType = req.method;
     
    if (req.url.includes('.svg')) {
      return req;
    }

    switch (reqType) {
      case 'POST' || 'PUT' || 'PATCH':
        if (req.body instanceof FormData) {
          return req.clone({
            body: req.body,
            setHeaders: {
              Authorization: 'Bearer ' + token
            },
            reportProgress: true,
            url: `${req.url}`
          });
        }
        return req.clone({
          body: this.getCleanReqBody(req.body),
          setHeaders: {
            'Content-Type': req.headers.has('Content-Type') ? req.headers.get('Content-Type')
              : 'application/json',
            Authorization: 'Bearer ' + token
          },
          reportProgress: true,
          url: `${req.url}`
        });
      default:
        return req.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token
          },
          reportProgress: true,
          url: `${req.url}`
        });
    }
}

  private getCleanReqBody(body: any) {
    const reqbody = Object.assign({}, body);
    Object.keys(reqbody).forEach(key => (reqbody[key] === null || reqbody[key] === '') && delete reqbody[key]);
    return reqbody;
  }

  private getErrors(errors: any): Array<string> {
    return Object.keys(errors).map(key => errors[key][0]);
  }
}
