export class CustomFieldsTypeValidtionsDB {

    public static customFieldsTypeValidations = [
      {
        "label": "NONE",
        "code": "none"
      },
      {
        "label": "EMAIL",
        "code": "email"
      },
      {
        "label": "URL",
        "code": "url"
      },
      {
        "label": "NUMBER",
        "code": "number"
      },
      {
        "label": "DATETIME",
        "code": "datetime"
      }
    ]      
}