export class TimeFormatsDB {

    public static timeFormats = [
        {
            "text": "h:mm a",
            "format": "h:mm a"
        },
        {
            "text": "h:mm:ss a",
            "format": "h:mm:ss a"
        },
        {
            "text": "H:mm",
            "format": "H:mm"
        },
        {
            "text": "H:mm:ss",
            "format": "H:mm:ss"
        }
    ]
}