export class LanguagesDB {

    public static languages = [
        "",
        "en",
        "es",
        "pt",
        "nl",
        "de",
        "it",
        "fr"
    ]
}