export class CropRatiosDB {

    public static cropRatios = [
        {
            "value": 0.6,
            "ratio": "5:9"
        },
        {
            "value": 0.75,
            "ratio": "3:4"
        },
        {
            "value": 1,
            "ratio": "1:1"
        },
        {
            "value": 1.3,
            "ratio": "4:3"
        },
        {
            "value": 1.8,
            "ratio": "16:9"
        }
    ]
}