export class CredentialEncryptionModesDB {

    public static credentialEncryptionModes = [
        {
            "code": "aes",
            "langKey": "AES"
        },
        {
            "code": "des",
            "langKey": "DES"
        },
        {
            "code": "triple_des",
            "langKey": "TRIPLE_DES"
        }
    ]
}