export class SystemActionTypesDB {

  public static actionTypes = [
    {
      "label": "SMS",
      "name": "sms",
      "enabled": true,
      "disabled_check": "sms",
      "has_receiver": true,
      "receiver_types": ["person", "user"],
      "fields": ["text"]
    },
    {
      "label": "MAIL_MERGE",
      "name": "mail_merge",
      "enabled": true,
      "disabled_check": "",
      "has_receiver": true,
      "receiver_types": ["profile", "user"],
      "fields": ["text", "attachment"]
    },
    {
      "label": "EMAIL",
      "name": "email",
      "enabled": true,
      "disabled_check": "smtp",
      "has_receiver": true,
      "receiver_types": ["profile", "user"],
      "fields": ["text"]
    },
    // {
    //   "label": "CONNECTOR",
    //   "name": "connector",
    //   "enabled": true,
    //   "disabled_check": "",
    //   "has_receiver": true,
    //   "receiver_types": ["profile","user","person"],
    //   "fields": ["text"]
    // },
    {
      "label": "WEB_HOOK",
      "name": "web_hook",
      "enabled": true,
      "disabled_check": "",
      "has_receiver": false,
      "receiver_types": [],
      "fields": ["url"]
    },
    {
      "label": "DELETE_PHOTO",
      "name": "delete_photo",
      "enabled": true,
      "disabled_check": "",
      "has_receiver": false,
      "receiver_types": [],
      "fields": [""]
    },
    {
      "label": "DELETE_ID_DOCUMENT",
      "name": "delete_id_document",
      "enabled": true,
      "disabled_check": "",
      "has_receiver": false,
      "receiver_types": [],
      "fields": [""]
    },
    {
      "label": "SEND_PHOTO_APPROVAL_VIA_EMAIL",
      "name" : "send_photo_approval_via_email",
      "enabled": true,
      "disabled_check": "smtp",
      "has_receiver": false,
      "receiver_types": [],
      "fields": ["text"]
    },
    {
      "label": "DELETE_SIGNATURE",
      "name": "delete_signature",
      "enabled": true,
      "disabled_check": "",
      "has_receiver": false,
      "receiver_types": [],
      "fields": [""]
    },
  ]      
}