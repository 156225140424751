import { config } from "config";

export const environment = {
    appVersion: require('../../package.json').version,
    production: true,
    config: config,
    subdomains: [
        {
            // Dev server
            subdomain: 'cardexchangecloudadmindev.azurewebsites.net',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://cardexchangecloudauthdev.azurewebsites.net',
            webApiBaseUrl: 'https://cardexchangecloudapidev.azurewebsites.net/api/v1/',
            webApi2BaseUrl: 'https://cardexchangecloudapidev.azurewebsites.net/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://cardexchangecloudapidev.azurewebsites.net/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://cardexchangecloudadmindev.azurewebsites.net'
        },
        {
            // Test server
            subdomain: 'cardexchangecloudadmintest.azurewebsites.net',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://cardexchangecloudauthtest.azurewebsites.net',
            webApiBaseUrl: 'https://cardexchangecloudapitest.azurewebsites.net/api/v1/',
            webApi2BaseUrl: 'https://cardexchangecloudapitest.azurewebsites.net/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://cardexchangecloudapitest.azurewebsites.net/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://cardexchangecloudadmintest.azurewebsites.net'
        },
        {
            // Pre-production server
            subdomain: 'cardexchangecloudadminpreprod.azurewebsites.net',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://cardexchangecloudauthpreprod.azurewebsites.net',
            webApiBaseUrl: 'https://cardexchangecloudapipreprod.azurewebsites.net/api/v1/',
            webApi2BaseUrl: 'https://cardexchangecloudapipreprod.azurewebsites.net/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://cardexchangecloudapipreprod.azurewebsites.net/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://cardexchangecloudadminpreprod.azurewebsites.net'
        },
        {
            // Prod server USA
            subdomain: 'admin.cardexchangecloud.com',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://api.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.cardexchangecloud.com'
        },
        {
            // Prod server USA (IDentiphoto)
            subdomain: 'identiphotoadmin.cardexchangecloud.com',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://identiphotoapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://identiphotoapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://identiphotoapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://identiphotoadmin.cardexchangecloud.com'
        },
        {
            // Prod server USA (Anixter)
            subdomain: 'anixteradmin.cardexchangecloud.com',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://anixterapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://anixterapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://anixterapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://anixteradmin.cardexchangecloud.com'
        },
        {
            // Prod server USA (NYU)
            subdomain: 'nyuadmin.cardexchangecloud.com',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://nyuapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://nyuapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://nyuapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://nyuadmin.cardexchangecloud.com'
        },
        {
            // Prod server USA (Texas A&M University)
            subdomain: 'idadmin.tamuds.tamu.edu',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://tamuapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://tamuapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://tamuapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://idadmin.tamuds.tamu.edu'
        },
        {
            // Prod server EUROPE
            subdomain: 'admin.cardexchangecloud.eu',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.cardexchangecloud.eu/api/v1/',
            webApi2BaseUrl: 'https://api.cardexchangecloud.eu/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.cardexchangecloud.eu/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.cardexchangecloud.eu'
        },
        {
            // Prod server AUSTRALIA
            subdomain: 'admin.cardezecloud.com.au',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.cardezecloud.com.au/api/v1/',
            webApi2BaseUrl: 'https://api.cardezecloud.com.au/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.cardezecloud.com.au/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.cardezecloud.com.au'
        },
        {
            // Prod server MULTICARD
            subdomain: 'multicardadmin.cardexchangecloud.com',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://multicardapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://multicardapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://multicardapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://multicardadmin.cardexchangecloud.com'
        },
        {
            // Prod server SCOPUS (cardexchangecloud.eu)
            subdomain: 'scopusadmin.cardexchangecloud.eu',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://scopusapi.cardexchangecloud.eu/api/v1/',
            webApi2BaseUrl: 'https://scopusapi.cardexchangecloud.eu/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://scopusapi.cardexchangecloud.eu/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://scopusadmin.cardexchangecloud.eu'
        },
        {
            // Prod server SCOPUS (scopus.fr)
            subdomain: 'admin.scopus.fr',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.scopus.fr/api/v1/',
            webApi2BaseUrl: 'https://api.scopus.fr/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.scopus.fr/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.scopus.fr'
        },
        {
            // Prod server BCPE (SCOPUS customer)
            subdomain: 'prod-bpce.cardexchangecloud.com',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.cardexchangecloud.eu/api/v1/',
            webApi2BaseUrl: 'https://api.cardexchangecloud.eu/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.cardexchangecloud.eu/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.cardexchangecloud.eu'
        },
        {
            // Prod server DIGITAL ID  (cardexchangecloud.co.uk)
            subdomain: 'admin.cardexchangecloud.co.uk',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.cardexchangecloud.co.uk/api/v1/',
            webApi2BaseUrl: 'https://api.cardexchangecloud.co.uk/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.cardexchangecloud.co.uk/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.cardexchangecloud.co.uk'
        },
        {
            // Prod server DIGITAL ID  (azurewebsites.net)
            subdomain: 'cardexchangecloudadmincenteruk.azurewebsites.net',
            hostingRegion: 'Somewhere in Azure',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://cardexchangecloudapiuk.azurewebsites.net/api/v1/',
            webApi2BaseUrl: 'https://cardexchangecloudapiuk.azurewebsites.net/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://cardexchangecloudapiuk.azurewebsites.net/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://cardexchangecloudadmincenteruk.azurewebsites.net'
        },
        {
            // Prod server DIGITAL ID  (Ayrshire College)
            subdomain: 'cardexchange.ayrshire.ac.uk',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://ayrshire-api.cardexchangecloud.co.uk/api/v1/',
            webApi2BaseUrl: 'https://ayrshire-api.cardexchangecloud.co.uk/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://ayrshire-api.cardexchangecloud.co.uk/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://cardexchange.ayrshire.ac.uk'
        },
        {
            // Prod server DIGITAL ID  (City Football Group)
            subdomain: 'cfgadmin.cardexchangecloud.co.uk',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://cfgapi.cardexchangecloud.co.uk/api/v1/',
            webApi2BaseUrl: 'https://cfgapi.cardexchangecloud.co.uk/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://cfgapi.cardexchangecloud.co.uk/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://cfgadmin.cardexchangecloud.co.uk'
        },
        {
            // Prod server CANADA (University of Ottawa)
            subdomain: 'uottawa-admin.cardexchangecloud.com',
            hostingRegion: 'Canada Central',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://uottawa-api.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://uottawa-api.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://uottawa-api.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://uottawa-admin.cardexchangecloud.com'
        },
        {
            // Sandbox USA
            subdomain: 'sandbox-admin.cardexchangecloud.com',
            hostingRegion: 'West US',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://sandbox-api.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://sandbox-api.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://sandbox-api.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://sandbox-admin.cardexchangecloud.com'
        },
        {
            // Sandbox Australia
            subdomain: 'sandbox-admin.cardezecloud.com.au',
            hostingRegion: 'Australia East',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://sandbox-api.cardezecloud.com.au/api/v1/',
            webApi2BaseUrl: 'https://sandbox-api.cardezecloud.com.au/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://sandbox-api.cardezecloud.com.au/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://sandbox-admin.cardezecloud.com.au'
        },
        {
            // Sandbox Europe
            subdomain: 'sandbox-admin.cardexchangecloud.eu',
            hostingRegion: 'West Europe',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://sandbox-api.cardexchangecloud.eu/api/v1/',
            webApi2BaseUrl: 'https://sandbox-api.cardexchangecloud.eu/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://sandbox-api.cardexchangecloud.eu/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://sandbox-admin.cardexchangecloud.eu'
        },
        {
            // Sandbox UK
            subdomain: 'sandbox-admin.cardexchangecloud.co.uk',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://sandbox-api.cardexchangecloud.co.uk/api/v1/',
            webApi2BaseUrl: 'https://sandbox-api.cardexchangecloud.co.uk/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://sandbox-api.cardexchangecloud.co.uk/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://sandbox-admin.cardexchangecloud.co.uk'
        },
        {
            // Sandbox Humber
            subdomain: 'humbersandbox-admin.cardexchangecloud.com',
            hostingRegion: 'West US',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://humbersandbox-api.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://humbersandbox-api.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://humbersandbox-api.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://humbersandbox-admin.cardexchangecloud.com'
        },
        {
            // Sandbox Europe
            subdomain: 'eval-bpce.cardexchangecloud.com',
            hostingRegion: 'West Europe',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://sandbox-api.cardexchangecloud.eu/api/v1/',
            webApi2BaseUrl: 'https://sandbox-api.cardexchangecloud.eu/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://sandbox-api.cardexchangecloud.eu/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://sandbox-admin.cardexchangecloud.eu'
        },
        {
            // Prod server CANADA (Humber College)
            subdomain: 'humberadmin.cardexchangecloud.com',
            hostingRegion: 'Canada Central',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://humberapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://humberapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://humberapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://humberadmin.cardexchangecloud.com'
        },
        {
            // Prod server CANADA (Concordia College)
            subdomain: 'concordiaadmin.cardexchangecloud.com',
            hostingRegion: 'Canada Central',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://concordiaapi.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://concordiaapi.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://concordiaapi.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://concordiaadmin.cardexchangecloud.com'
        },
        {
            // Prod server UK (ITV)
            subdomain: 'admin.myitvid.itv.com',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://api.myitvid.itv.com/api/v1/',
            webApi2BaseUrl: 'https://api.myitvid.itv.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://api.myitvid.itv.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.myitvid.itv.com'
        },
        {
            // Prod server UK (University of Edinburgh)
            subdomain: 'admin.card.ed.ac.uk',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://uoe-api.cardexchangecloud.co.uk/api/v1/',
            webApi2BaseUrl: 'https://uoe-api.cardexchangecloud.co.uk/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://uoe-api.cardexchangecloud.co.uk/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://admin.card.ed.ac.uk'
        },
        {
            // Test server UK (University of Edinburgh)
            subdomain: 'test.admin.card.ed.ac.uk',
            hostingRegion: 'UK South',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://uoe-test-api.cardexchangecloud.co.uk/api/v1/',
            webApi2BaseUrl: 'https://uoe-test-api.cardexchangecloud.co.uk/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://uoe-test-api.cardexchangecloud.co.uk/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://test.admin.card.ed.ac.uk'
        },
        {
            // PROD server CANADA (NorQuest College)
            subdomain: 'norquest-admin.cardexchangecloud.com',
            hostingRegion: 'Canada Central',
            authenticationUrl: 'https://auth.cardexchangecloud.com',
            webApiBaseUrl: 'https://norquest-api.cardexchangecloud.com/api/v1/',
            webApi2BaseUrl: 'https://norquest-api.cardexchangecloud.com/api/v2/',
            webApi2BaseAdmincenterUrl: 'https://norquest-api.cardexchangecloud.com/api/v2/admincenter/',
            mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
            adminCenterUrl: 'https://norquest-admin.cardexchangecloud.com'
        }
    ]
};