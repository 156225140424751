export class EmailPlaceholdersProfileDB {

    public static placeholders = [
        {
            "key": "{ExternalCompany}",
            "required": false
        },
        {
            "key": "{Location}",
            "required": false
        },
        {
            "key": "{Department}",
            "required": false
        }
    ];
}