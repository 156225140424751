import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SubscribedAppsComponent } from '../subscribed-apps/subscribed-apps.component';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { environment } from 'environments/environment';
import { SettingsService } from 'app/shared/services/settings/settings.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-side',
  templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit {
  @Input() sidebarPanel;
  @Input() subscriptionsPanel;
  @Output() subscriptionsClicked = new EventEmitter();
  @Output() notificationsClicked = new EventEmitter();
  @Output() settingsClicked = new EventEmitter();
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'flag-icon-us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'flag-icon-es'
  }]
  currentLang = this.availableLangs[0];
  currentUser = {name: "", email: "", live_enabled: ""};
  customerLogo = "";
  logoSrc: string;
  subscription: Subscription

  public matxThemes;
  public layoutConf:any;
  constructor(
    private themeService: ThemeService,
    private layout: LayoutService,
    public oidcSecurityService: OidcSecurityService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private ls: LocalStoreService,
    private settingsService: SettingsService
  ) {}
  ngOnInit() {
    this.matxThemes = this.themeService.matxThemes;
    this.layoutConf = this.layout.layoutConf;
    let user = this.ls.getItem(environment.config.currentUserStorageKey);
    this.loadCustomerLogo();
    if (user) {
      this.currentUser.name = user.name;
      this.currentUser.email = user.email;
      this.currentUser.live_enabled = user.live_enabled;
    }

    this.subscription = this.settingsService.logoSrcChanged.subscribe(data => {
      if (data != '') {
        this.customerLogo = data;
      }
    });
  }
  toggleNotific() {
    this.notificationsClicked.emit();
    this.sidebarPanel.toggle();
  }
  toggleAppSettings() {
    this.settingsClicked.emit();
    this.sidebarPanel.toggle();
  }
  // OBSOLETE
  openSubscriptions(): void {
    const dialogRef = this.dialog.open(SubscribedAppsComponent, {
      position: {left: '0', top: '0'},
      data: 'hello'
    });
  }
  toggleSidenav() {
    if(this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  toggleCollapse() {
    // compact --> full
    if(this.layoutConf.sidebarStyle === 'compact') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, {transitionClass: true})
    }

    // * --> compact
    this.layout.publishLayoutChange({
      sidebarStyle: 'compact',
      sidebarCompactToggle: true
    }, {transitionClass: true})

  }

  onSearch(e) {
  }

  onLogout() {
    this.oidcSecurityService.logoff();
  }

  loadCustomerLogo() {
    this.settingsService.getCustomerLogo()
      .subscribe(resp => {
        if (resp.logoUrl) {
          this.customerLogo = resp.logoUrl;
        } else {
          this.customerLogo = "assets/images/logos/ce_logo.png";
        }
      },
      error => {
        this.customerLogo = "assets/images/logos/ce_logo.png";
    });
  }
}