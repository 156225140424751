import { Component, OnInit, Input } from "@angular/core";
import {TranslateService} from '@ngx-translate/core';
import { OidcSecurityService } from "angular-auth-oidc-client";
import jwt_decode from 'jwt-decode';
import { isArray } from 'lodash';

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.template.html",
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent {
  @Input("items") public menuItems: any[] = [];
  @Input("hasIconMenu") public hasIconTypeMenuItem: boolean;
  @Input("iconMenuTitle") public iconTypeMenuTitle: string;
  hasHidMobileSubsciption: boolean = false;
  hasSaltoSubscription: boolean = false;

  constructor(private translate: TranslateService, private oidcSecurityService: OidcSecurityService) {}
  ngOnInit() {
    let token = this.oidcSecurityService.getToken();
    if(token) {
      let tokenData: any = jwt_decode(token);
      if (tokenData && tokenData.controller_hidmobile == 'licensed') {
        this.hasHidMobileSubsciption = true;
      }
      if (tokenData && tokenData.controller_salto == 'licensed') {
        this.hasSaltoSubscription = true;
      }
    }
  }

  // Only for demo purpose
  addMenuItem() {
    this.menuItems.push({
      name: "ITEM",
      type: "dropDown",
      tooltip: "Item",
      icon: "done",
      state: "material",
      sub: [
        { name: "SUBITEM", state: "cards" },
        { name: "SUBITEM", state: "buttons" }
      ]
    });
  }
}
