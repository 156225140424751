export class CurrenciesDB {

    public static currencies = [
        {
            "code": "USD",
            "symbol": "$"
        },
        {
            "code": "EUR",
            "symbol": "€"
        }
    ]
}