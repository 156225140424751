import { Pipe, PipeTransform } from '@angular/core';
import { IMetadata } from '../../interfaces/omni-cloud/user-metadata.interface';

@Pipe({
    name: 'displayAttribute'
})
export class DisplayAttributePipe implements PipeTransform {

    transform(label: string, element: any, usermetadata: Array<IMetadata>): string {
        if (!element) {
            return '';
        }
        const selectedProperty = Object.keys(element).find(property => property.toLowerCase() === label.toLowerCase());
        if (selectedProperty == null) {
            return label;
        }
        const rawValue = element[selectedProperty];
        if (rawValue == null || rawValue === '' || (typeof (rawValue) === 'object' && rawValue.name == null)) {
            return '-';
        }
        if (label.toLowerCase() === 'attributeid') {
            const index = usermetadata.findIndex(data => data.attribute.id === element.attributeId);
            if (index === -1) {
                return rawValue;
            }
            return usermetadata[index].attribute.translation;
        }

        return rawValue;
    }
}
