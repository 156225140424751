export class PhotoApprovalPlaceholdersDB {

    public static placeholders = [
        {
            "key": "{ApproverName}",
            "required": false
        },
        {
            "key": "{Link}",
            "required": true
        }
    ]
}