import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { EnvSettings } from '../models/env-settings.model';
import { EnvSettingsService } from '../services/env-settings.service';

@Pipe({
    name: 'dateFormatFromSettings'
})
export class DatedateFormatFromSettingsPipe extends 
            DatePipe implements PipeTransform {

    settings: EnvSettings;
    constructor(private envSettings: EnvSettingsService) {
        super('en_us');
        this.settings = this.envSettings.getCurrentEnvSettings();
    }
    transform(value: any, args?: any): any {
        let formatStr = "";
        if (args == 'dateOnly') {
            formatStr = this.settings.dateFormat;
        } else if (args == 'timeOnly') {
            formatStr = this.settings.timeFormat;
        } else {
            formatStr = this.settings.dateFormat + " " + this.settings.timeFormat;
        }
        return super.transform(value, formatStr, this.settings.autoTimezone ? '' : this.settings.tzOffset);
    }
}