export class CredentialStartDateSourcesDB {
    public static credentialStartDateSources = [
        {
            "langKey": "Use_Current_Date",
            "code": "current_date"
        },
        {
            "langKey": "Follow_Profile_Start_Date",
            "code": "profile_start_date"
        }
    ]
}