export class StandColorThemesDB {

    public static colorThemes = [
        {
          name: "GREEN",
          description_label: "GREEN",
          cardTop: {
            background: "#FFF",
            text: "#000"
          },
          cardMiddle: {
            background: "#5DC944",
            text: "#FFF"
          },
          cardBottom: {
            background: "#419E2B",
            text: "#FFF"
          }
        },
        {
          name: "MUSTARD",
          description_label: "MUSTARD",
          cardTop: {
            background: "#FFF",
            text: "#000"
          },
          cardMiddle: {
            background: "#E3B641",
            text: "#FFF"
          },
          cardBottom: {
            background: "#C09422",
            text: "#FFF"
          }
        },
        {
          name: "DEEP_BLUE",
          description_label: "DEEP_BLUE",
          cardTop: {
            background: "#FFF",
            text: "#000"
          },
          cardMiddle: {
            background: "#2958FF",
            text: "#FFF"
          },
          cardBottom: {
            background: "#0E33B8",
            text: "#FFF"
          }
        },
        {
          name: "BLACK_WHITE",
          description_label: "BLACK_WHITE",
          cardTop: {
            background: "#333131",
            text: "#FFF"
          },
          cardMiddle: {
            background: "#000",
            text: "#FFF"
          },
          cardBottom: {
            background: "#FFF",
            text: "#000"
          }
        },
        {
          name: "OLIVE",
          description_label: "OLIVE",
          cardTop: {
            background: "#FFF",
            text: "#000"
          },
          cardMiddle: {
            background: "#ABBB3B",
            text: "#FFF"
          },
          cardBottom: {
            background: "#9AA935",
            text: "#FFF"
          }
        },
        {
          name: "PINK",
          description_label: "PINK",
          cardTop: {
            background: "#FFF",
            text: "#000"
          },
          cardMiddle: {
            background: "#FB6464",
            text: "#FFF"
          },
          cardBottom: {
            background: "#E75757",
            text: "#FFF"
          }
        }   
    ];
}