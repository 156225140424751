import { Component, Inject, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Condition } from 'app/shared/models/setup.controllercms.model';
import { IEntity, IEntityContents, IMetadata } from 'app/interfaces/omni-cloud/user-metadata.interface';
import { AuthenticationState } from 'app/auth/authentication-store/authentication.state';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-conditions-dialog',
  templateUrl: './conditions-dialog.component.html',
  styleUrls: ['./conditions-dialog.component.scss']
})
export class ConditionsDialogComponent {
  @Select(AuthenticationState.omnicloudentities) entitiesList$: Observable<Array<IEntity>>;
  @Select(AuthenticationState.omnicloudmetadata) userMetadata$: Observable<Array<IMetadata>>;
  @Select(AuthenticationState.omnicloudEntityContents) entityContents$: Observable<IEntityContents>;
  
  conditionsData: Condition;
  conditionsForm: FormGroup;
  attributeId: string = null;
  permitedEntities = ['person', 'cardholder', 'address', 'card'];
  action: string = null;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ConditionsDialogComponent>
  ) { 
    this.conditionsData = data;
  }

  ngOnInit() {
    this.action = (this.conditionsData.attributeId) ? 'Edit' : 'Add';
    this.setupForm();
  }
    
  onNoClick(): void {
    this.dialogRef.close();
  }

  setupForm() {
    this.conditionsForm = new FormGroup({
      attribute: new FormControl(this.conditionsData.attributeId, [Validators.required]),
      value: new FormControl(this.conditionsData.value, [Validators.required]),
      prefix: new FormControl(this.conditionsData.prefix, [Validators.required])
    });
  }

  getAttributes(userMetadata: Array<IMetadata>, entity: IEntity) {
    return userMetadata.filter(data => {
      if (entity.name === 'cardholder' && data.attribute.entity === 'profile' && data.attribute.name === 'Name') {
        return data;
      }
      if (this.attributeId != null) {
        return data.attribute.entity === entity.name && data.attribute.listable
          && (data.attribute.dataFormat === 'string' || data.attribute.dataFormat === 'email'
            || data.attribute.dataFormat === 'mobileNumber' || data.attribute.dataFormat === 'note');
      }
      return data.attribute.entity === entity.name && data.attribute.listable;
    });
  }

  getEntities(entitiesList: Array<IEntity>) {
    return entitiesList.filter(entity => {
      if (this.permitedEntities.includes(entity.name)) {
        return true;
      }
      return false;
    });
  }

  isDisabled(userMetadata: Array<IMetadata>, entity: IEntity) {
    if (this.attributeId != null) {
      const index = userMetadata.findIndex(data => data.attribute.id === this.attributeId);
      if (index !== -1) {
        if (userMetadata[index].attribute.name === 'CardNumber' && entity.name === 'cardholder') {
          return false;
        }
        return userMetadata[index].attribute.entity !== entity.name;
      }
      return false;
    }
    return false;
  }

  onSubmit() {
    if(this.conditionsForm.valid) {
      let retData: Condition = {
        id: this.conditionsData.id,
        systemLabelId: this.conditionsData.systemLabelId,
        attributeId: this.conditionsForm.value.attribute,
        prefix: this.conditionsForm.value.prefix,
        value: this.conditionsForm.value.value
      }

      this.dialogRef.close({ data: retData, action: this.action });
    }
  }
}
