import { Component, OnInit, ViewChild, Input, Inject  } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { LayoutService } from "../../../shared/services/layout.service";
import { ThemeService, ITheme } from "app/shared/services/theme.service";
import { TranslateService } from '@ngx-translate/core';
import { EnvSettingsService } from 'app/shared/services/env-settings.service';
import { environment } from '../../../../environments/environment';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { EnvSettings } from 'app/shared/models/env-settings.model';

import { StaticDataService, ITimezone, IDateTimeFormat, IIdleTimeoutOption } from 'app/shared/services/static-data.service';
import { ToastrService } from 'ngx-toastr';
import { DOCUMENT } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { PasswordChangeDialogComponent } from '../password-change-dialog/password-change-dialog.component';
import { UserService } from 'app/shared/services/users/user.service';
import { AuthenticationState } from 'app/auth/authentication-store/authentication.state';
import { Select } from '@ngxs/store';
import { IOmniCloudUserEntity } from 'app/interfaces/omni-cloud/state.interface';
import { Observable } from 'rxjs';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.scss']
})
export class AppSettingsComponent implements OnInit {
  @Select(AuthenticationState.omniclouduserdata) userData$: Observable<IOmniCloudUserEntity>;
  @Input() appSettingsPanel;

  layoutConf;

  settingsUserId: string = "";

  today: number;
  isLoading: boolean;
  matxThemes: ITheme[];  
  selectedTheme: string;
  selectedThemeColor: string;
  themeChanged: boolean;

  languages: string[];
  selectedLang: string;
  languageChanged: boolean;

  timezones: ITimezone[];
  isAutoTZ: boolean;
  selectedTZ: string;
  selectedTZOffset: string;
  timezoneChanged: boolean;

  dateFormats: IDateTimeFormat[];
  selectedDateFormat: string;
  dateFormatChanged: boolean;

  timeFormats: IDateTimeFormat[];
  selectedTimeFormat: string;
  timeFormatChanged: boolean;

  idleTimeoutOptions: IIdleTimeoutOption[];
  selectedIdleTimeoutOption: number;
  idleTimeoutOptionChanged: boolean;  

  isDarkTheme: boolean;
  darkThemeInitValue: boolean;
  userData: IOmniCloudUserEntity;

  currentUser = { name: "", email: "", live_enabled: ""};

  presetColors = ['#0e1445','#166ecd','#163e04','#2e7d32',
                '#e64a19','#ff963d','#1a1d24','#424242'];

  constructor(private router: Router, 
              public layout: LayoutService,
              private themeService: ThemeService,
              private translate: TranslateService,
              private settingsService: EnvSettingsService,
              private userService: UserService,
              private ls: LocalStoreService,
              private staticData: StaticDataService,
              private toastr: ToastrService,
              @Inject(DOCUMENT) private document: Document,
              public dialog: MatDialog,
              private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(): void {
    this.layoutConf = this.layout.layoutConf;
    this.matxThemes = this.themeService.matxThemes;
    this.languages = this.staticData.getLanguages();
    this.timezones = this.staticData.getTimezones();
    this.dateFormats = this.staticData.getDateFormats();
    this.timeFormats = this.staticData.getTimeFormats();
    this.idleTimeoutOptions = this.staticData.getIdleTimeoutOptions();
    this.loadCurrentSettings();
    
    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.appSettingsPanel.close();
        }
    });

    this.userData$.subscribe(data => {
      if (data) {
        this.userData = data;
      }
    });

    let user = this.ls.getItem(environment.config.currentUserStorageKey);
    if (user) {
      this.currentUser.name = user.name;
      this.currentUser.email = user.email;
      this.currentUser.live_enabled = user.live_enabled;
    }
  }
  ngOnChange(): void {
    this.loadCurrentSettings();
  }

  loadCurrentSettings() {

    this.isLoading = true;

    let settings = this.settingsService.getCurrentEnvSettings()
    this.settingsUserId = settings.id;
    this.setSelectedTheme(settings.theme);
    this.setSelectedLang(settings.language);
    this.setSelectedTZ(settings.autoTimezone, settings.timezone);
    this.setSelectedDateFormat(settings.dateFormat);
    this.setSelectedTimeFormat(settings.timeFormat);
    this.setSelectedIdleTimeoutOption(settings.idleTimeoutInSeconds);
    this.setDarkThemeOption(settings.isDarkTheme);
    this.today = Date.now(); 
    
    this.isLoading = false;
  }

  private setSelectedTheme(themeName: string) {
    let defaultTheme = environment.config.defaultEnvSettings.theme;
    let defaultThemeColor = environment.config.defaultEnvSettings.themeColor;
    this.selectedTheme = null;
    this.matxThemes.map((theme: ITheme) => {
      if (theme.isActive) {
        defaultTheme = theme.name;
        defaultThemeColor = theme.baseColor;
      }
      if (theme.name === themeName) {
        this.selectedTheme = theme.name;
        this.selectedThemeColor = theme.baseColor;
        
        this.themeChanged = false;
        return;
      }
    });
    if (this.selectedTheme === null) {
      this.selectedTheme = defaultTheme;
      this.selectedThemeColor = defaultThemeColor;
      this.themeChanged = true;
    }
  }

  private setSelectedLang(lang: string) {    
    this.selectedLang = null;
    this.languages.map((language: string) => {
      if (language === lang) {
        this.selectedLang = language;
        this.languageChanged = false;
        return;
      }
    });
    if (this.selectedLang === null) {
      this.selectedLang = environment.config.defaultEnvSettings.language;
      this.languageChanged = true;
    }
  }

  private setSelectedTZ(isAuto: boolean, tzCode: string) {

    this.isAutoTZ = isAuto;
    this.selectedTZ = null;
    this.timezones.map((tz: ITimezone) => {
      if (tz.value === tzCode) {
        this.selectedTZ = tz.value;
        this.selectedTZOffset = tz.offset;
        this.timezoneChanged = false;
        return;
      }
    });
    if (this.selectedTZ === null) {
      this.selectedTZ = environment.config.defaultEnvSettings.timezone;
      this.selectedTZOffset = environment.config.defaultEnvSettings.tzOffset;
      this.timezoneChanged = true;
    }
  }

  private setSelectedDateFormat(format: string) {
    this.selectedDateFormat = null;
    this.dateFormats.map((dtFormat: IDateTimeFormat) => {
      if (dtFormat.format === format) {
        this.selectedDateFormat = dtFormat.format;
        this.dateFormatChanged = false;
        return;
      }
    });
    if (this.selectedDateFormat === null) {
      this.selectedDateFormat = environment.config.defaultEnvSettings.dateFormat;
      this.dateFormatChanged = true;
    }
  }

  private setSelectedTimeFormat(format: string) {
    this.selectedTimeFormat = null;
    this.timeFormats.map((tmFormat: IDateTimeFormat) => {
      if (tmFormat.format === format) {
        this.selectedTimeFormat = tmFormat.format;
        this.timeFormatChanged = false;
        return;
      }
    });
    if (this.selectedTimeFormat === null) {
      this.selectedTimeFormat = environment.config.defaultEnvSettings.timeFormat;
      this.timeFormatChanged = true;
    }
  }

  private setSelectedIdleTimeoutOption(format: number) {
    this.selectedIdleTimeoutOption = null;

    this.idleTimeoutOptions.map((idleTimeout: IIdleTimeoutOption) => {
      if (idleTimeout.value === format) {
        this.selectedIdleTimeoutOption = idleTimeout.value;
        this.idleTimeoutOptionChanged = false;
        return;
      }
    });
    if (this.selectedIdleTimeoutOption === null) {
      this.selectedIdleTimeoutOption = environment.config.defaultEnvSettings.idleTimeoutInSeconds;
      this.idleTimeoutOptionChanged = true;
    }
  }

  setDarkThemeOption(darkTheme: boolean) {
    this.isDarkTheme = darkTheme;
    this.darkThemeInitValue = darkTheme;
  }

  changeTheme(themeName: string) {
    this.selectedTheme = themeName;
    this.themeChanged = true; 
  }

  changeLanguage(language: string) {
    this.selectedLang = language;
    this.languageChanged = true;
  }

  changeTimezone(tzName: string, tzOffset: string) {
    this.selectedTZ = tzName;
    this.selectedTZOffset = tzOffset;
    this.timezoneChanged = true;
  }

  changeDateFormat(format: string) {
    this.selectedDateFormat = format;
    this.dateFormatChanged = true;
  }

  changeTimeFormat(format: string) {
    this.selectedTimeFormat = format;
    this.timeFormatChanged = true;
  }

  changeIdleTimeoutOption(format: number) {
    this.selectedIdleTimeoutOption = format;
    this.idleTimeoutOptionChanged = true;
  }

  changeDefaultTheme(format: string, themeName: string) {
    this.selectedThemeColor = format;
    this.selectedTheme = themeName;
    this.themeChanged = true;
  }

  onChangePassword() {
    const dialogRef = this.dialog.open(PasswordChangeDialogComponent, {
      width: '350px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.isLoading = true;
        this.userService.changeUserPassword(result.oldPassword, result.newPassword).subscribe( res => {
          this.isLoading = false;
          this.toastr.success(this.translate.instant('SUCCESS.AC_SETTINGS_CHANGE_PWD_MESSAGE'), this.translate.instant('SUCCESS.AC_SETTINGS_CHANGE_PWD_TITLE'));
        },
        error => {
          this.isLoading = false;
          this.toastr.error(this.translate.instant('ERROR.AC_SETTINGS_CHANGE_PWD_MESSAGE'), this.translate.instant('ERROR.AC_SETTINGS_CHANGE_PWD_TITLE'));
          this.onChangePassword();
        });
      }
    });

  }

  applyChanges() {
    if (this.languageChanged && this.selectedLang.length) {
      this.translate.use(this.selectedLang);
      this.document.documentElement.lang = this.selectedLang;
    }
    if (this.themeChanged && this.selectedTheme) {
      this.layout.publishLayoutChange({matTheme: this.selectedTheme, themeColor: this.selectedThemeColor})
    }
    if (this.timezoneChanged || this.dateFormatChanged || this.timeFormatChanged) {
      // TODO
    // How to force refresh of dates (TZ, Date format, Time format)
      
    }
    if (this.isDarkTheme != this.darkThemeInitValue) {
      this.selectedTheme = (this.isDarkTheme) ? 'cardex-dark' : 'cardex-light';
      this.layout.publishLayoutChange({matTheme: this.selectedTheme});
      this.savePreference();
    }

    let settings:EnvSettings = {id: this.settingsUserId, 
                                language: this.selectedLang, 
                                theme: this.selectedTheme,
                                autoTimezone: this.isAutoTZ,
                                timezone: this.selectedTZ,
                                tzOffset: this.selectedTZOffset,
                                dateFormat: this.selectedDateFormat,
                                timeFormat: this.selectedTimeFormat,
                                idleTimeoutInSeconds: this.selectedIdleTimeoutOption,
                                themeColor: this.selectedThemeColor,
                                isDarkTheme: this.isDarkTheme
                              };
    this.settingsService.setEnvSettingsForUser(settings)
      .subscribe( res => 
        this.settingsService.loadEnvSettingsForLoggedUser().subscribe( res => {
            this.loadCurrentSettings();
            this.toastr.success(this.translate.instant('SUCCESS.AC_SETTINGS_SAVE_MESSAGE'), this.translate.instant('SUCCESS.AC_SETTINGS_SAVE_TITLE'));
          }
        ));  
    this.closePanel();
  }

  private formatTzOffset(tzOffset: number) : string {
    if (tzOffset <= 0) {
      return '' + tzOffset;
    }
    return '+' + tzOffset;
  }

  closePanel() {
    this.appSettingsPanel.close();
  }

  savePreference() {
    if(this.userData) {
      const theme = (this.isDarkTheme) ? 'dark' : 'light';
      const request = { ...this.userData };
      request.preferences = { ...request.preferences, 'theme': theme };
      this.userService.saveSitePreference(request).subscribe(data => { });
    }
  }

  onLogout() {
    this.oidcSecurityService.logoff();
  }
}
