
<div class="row">
  <div class="col-12">
    <!-- <div *ngIf="title" 
      [class]="options.titleClass" 
      [attr.aria-label]="title"
      class="custom-title">
      {{ title }}
    </div> -->
    <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
      class="custom-message"
      [class]="options.messageClass" [innerHTML]="message">
    </div>
    <div *ngIf="message && !options.enableHtml && title != '500'" role="alert" aria-live="polite"
      class="custom-message"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ message }}
    </div>
    <div *ngIf="message && !options.enableHtml && title == '500'" role="alert" aria-live="polite"
      class="custom-message"
      [class]="options.messageClass" [attr.aria-label]="message">
      {{ 'ERROR.UNEXPECTED' | translate }}
    </div>
    <span class="text-right">
      <!-- <a *ngIf="!options.closeButton" class="btn btn-pink btn-sm" (click)="action($event)">
        {{ undoString }}
      </a> -->
      <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-sm toast-close-button">
        &times;
      </a>
    </span>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div *ngIf="title == '500'" aria-live="polite" class="toastr-footer" [attr.aria-label]="details">
      <span class="error-details-btn" (click)="toggleDetails()">
        {{ 'ERROR.DETAILS' | translate }}
      </span>
    </div>
  </div>
</div>
<div *ngIf="showDetails && title == '500'" class="row">
  <div class="col-12 details">
    <span class="error-details">{{ message }}</span>
  </div>
</div>
<div *ngIf="options.progressBar">
  <div class="toast-progress" [style.width]="width + '%'"></div>
</div>