export class EmailPlaceholdersPersonDB {
    public static placeholders = [
        //Persons
        {
            "key": "{FirstName}",
            "required": false
        },
        {
            "key": "{MiddleName}",
            "required": false
        },
        {
            "key": "{LastName}",
            "required": false
        },
        {
            "key": "{PersonNumber}",
            "required": false
        },
        {
            "key": "{BirthDate}",
            "required": false
        },
        {
            "key": "{Prefix}",
            "required": false,
        },
        {
            "key": "{Suffix}",
            "required": false,
        },
        {
            "key": "{PhoneNumber}",
            "required": false,
        },
        {
            "key": "{MobileNumber}",
            "required": false,
        },
        {
            "key": "{FreeField1}",
            "required": false,
        },
        {
            "key": "{FreeField2}",
            "required": false,
        },
        {
            "key": "{FreeField3}",
            "required": false,
        },
        {
            "key": "{FreeField4}",
            "required": false,
        },
        {
            "key": "{FreeField5}",
            "required": false,
        },
        {
            "key": "{FreeField6}",
            "required": false,
        },
        {
            "key": "{FreeField7}",
            "required": false,
        },
        {
            "key": "{FreeField8}",
            "required": false,
        },
        {
            "key": "{FreeField9}",
            "required": false,
        },
        {
            "key": "{FreeField10}",
            "required": false,
        },
        {
            "key": "{FreeField11}",
            "required": false,
        },
        {
            "key": "{FreeField12}",
            "required": false,
        },
        {
            "key": "{FreeField13}",
            "required": false,
        },
        {
            "key": "{FreeField14}",
            "required": false,
        },
        {
            "key": "{FreeField15}",
            "required": false,
        },
        {
            "key": "{FreeField16}",
            "required": false,
        }
    ]
}