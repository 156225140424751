import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

import { EnvSettings, EnvSettingsPayload } from './../models/env-settings.model';
import { Observable, throwError, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { LocalStoreService } from './local-store.service';
import { BaseService } from './base.service';
import { DOCUMENT } from '@angular/common';
import { AppConfigService } from './app-config.service';
import { AppConfig } from '../models/app-config.model';

//const BASE_URL = 'applicationusersettings';
const BASE_URL = 'applicationusersettings';

@Injectable({
  providedIn: 'root'
})
export class EnvSettingsService extends BaseService {

  config: AppConfig;

  constructor(private http: HttpClient, private appConfigService: AppConfigService,
              private ls: LocalStoreService, 
              @Inject(DOCUMENT) private document: Document) {
    super(document);
    appConfigService.getConfig().subscribe((res: AppConfig) =>{
      this.config = res;
    });
  }
            
  private getDefaultEnvSettings() {
    return {
      id: '',
      language: environment.config.defaultEnvSettings.language,
      theme: environment.config.defaultEnvSettings.theme,
      autoTimezone: environment.config.defaultEnvSettings.autoTimezone,
      timezone: environment.config.defaultEnvSettings.timezone,
      tzOffset: environment.config.defaultEnvSettings.tzOffset,
      dateFormat: environment.config.defaultEnvSettings.dateFormat,
      timeFormat: environment.config.defaultEnvSettings.timeFormat,
      idleTimeoutInSeconds: environment.config.defaultEnvSettings.idleTimeoutInSeconds,
      isDarkTheme: environment.config.defaultEnvSettings.isDarkTheme
    } as EnvSettings;
  }

  loadEnvSettingsForLoggedUser(): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.getEnvSettingsForUser(true).subscribe((envSettings: EnvSettingsPayload) => {
        if (envSettings && envSettings.settings) {
          let envObj = JSON.parse(envSettings.settings);

          if ('idleTimeoutInSeconds' in envObj === false) {
            envObj.idleTimeoutInSeconds = environment.config.defaultEnvSettings.idleTimeoutInSeconds;
          }

          envObj.id = envSettings.id; 
          this.ls.setItem(environment.config.currentEnvSettingsStorageKey, envObj);
          observer.next(true);
        } else {
          this.ls.setItem(environment.config.currentEnvSettingsStorageKey, this.getDefaultEnvSettings());
          observer.next(false);
        }     
      },
      error => {
        this.ls.setItem(environment.config.currentEnvSettingsStorageKey, this.getDefaultEnvSettings());
        observer.next(false);
      });
    });    
  }

  getCurrentEnvSettings(): EnvSettings {
    return this.ls.getItem(environment.config.currentEnvSettingsStorageKey);
  }

  getCurrentUser(): EnvSettings {
    let userConfig = this.ls.getItem(environment.config.currentUserStorageKey) ?
        this.ls.getItem(environment.config.currentUserStorageKey) : sessionStorage.getItem(environment.config.sessionUserStorageKey);
    return userConfig;
  }

  getEnvSettingsForUser(refresh: boolean = false): Observable<EnvSettingsPayload>  {

    // TODO
    // Receive param for force-get
    // Store in Local Storage
    // Get from Local Storage if force-get is false and object in LS exists
    let apiBaseUrl = this.environmentForDomain.webApiBaseUrl;
    
    if(this.config?.useV2Endpoints?.includes('getApplicationUserSettings')){
      apiBaseUrl = this.environmentForDomain.webApi2BaseUrl
    }
    
    return this.http.get<EnvSettingsPayload>(apiBaseUrl + BASE_URL)
        .pipe(
          catchError((error) => {
              return throwError(error);
          })
        );
  }

  setEnvSettingsForUser(settings: EnvSettings) {

    let result: any;
    let currentUser: any = this.getCurrentUser();
    let isNew: boolean = true;

    if (settings.id) {
      isNew = false; 
    } else {
      settings.id = currentUser.sub;
    }

    let payload = {
      id: currentUser.sub,
      settings: JSON.stringify(settings)
    }

    let apiBaseUrl = this.environmentForDomain.webApiBaseUrl;
    
    if(this.config?.useV2Endpoints?.includes('updateApplicationUserSettings')){
      apiBaseUrl = this.environmentForDomain.webApi2BaseUrl
    }

    if (!isNew) {
      result = this.http.put(apiBaseUrl + BASE_URL, payload)
        .pipe(
            catchError((error) => {
                return throwError(error);
            })
        )
    } else {
      result = this.http.post(apiBaseUrl + BASE_URL, payload)
          .pipe(
              catchError((error) => {
                  return throwError(error);
              })
          )
    }
    return result;    
  }   
}
