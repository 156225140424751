import { Injectable,Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BaseService } from '../base.service';
import { Observable, throwError } from 'rxjs';
import { catchError,map } from 'rxjs/operators';
import {SystemLabel} from '../../../interfaces/omni-cloud/system-label.interface';
@Injectable({
  providedIn: 'root'
})
export class SystemLabelService extends BaseService {

constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {
  super(document);
 }

 getSystemLabel():Observable<any> {
   return this.http.get<any>(this.environmentForDomain.webApiBaseUrl + "system-labels",{observe:'response'}).pipe(
    map(httpResponse => httpResponse.body as Array<SystemLabel>),
    catchError((error) => {
      return throwError(error);
    })
   );

 }

}
