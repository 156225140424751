export class DefaultStandDetailsField
{
    public static includedFields = {
        "person" : {
            "hidden" : ["MiddleName","Gender","Prefix","Suffix",
                        "FreeField1","FreeField2","FreeField3","FreeField4","FreeField5","FreeField6","FreeField7",
                        "FreeField8","FreeField9","FreeField10","FreeField11","FreeField12","FreeField13","FreeField14",
                        "FreeField15","FreeField16"],
            "visible" : ["PersonNumber",],
            "editable" : ["FirstName","LastName","BirthDate","PhoneNumber","MobileNumber"]
        },
        "cardholder" : {
            "hidden" : ["BusinessUnit","ExternalCompany","Department","CostAccount",
                        "Location","JobTitle","NameOnCard","Note","FreeField1","FreeField2"],
            "visible" : ["Email"],
            "editable" : []
        }
    };
}