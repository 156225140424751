export class GroupFiltersFieldsDB {

    public static groupFilterFields = [
        {
            "entity": "Person",
            "fields": [
                "personNumber",
                "addressPreference",
                "identificationType",
                "identificationTypeValue",
                "firstName",
                "middleName",
                "lastName",
                "birthDate",
                "gender",
                "prefix",
                "suffix",
                "phoneNumber",
                "mobileNumber",
                "status",
                "profilePreference",
                "createdDateUtc",
                "origin",
                "freeField1",
                "freeField2",
                "freeField3",
                "freeField4",
                "freeField5",
                "freeField6",
                "freeField7",
                "freeField8",
                "freeField9",
                "freeField10",
                "freeField11",
                "freeField12",
                "freeField13",
                "freeField14",
                "freeField15",
                "freeField16"
            ]
        },
        {
            "entity": "Cardholder",
            "fields": [
                // "email",
                "businessUnit",
                "externalCompany",
                "department",
                "costAccount",
                "location",
                // "jobTitle",
                // "cardholderStartDateUtc",
                // "cardholderEndDateUtc",
                // "cardholderStatus",
                // "nameOnCard",
                // "note",
                // "profileNumber",
                // "cardholderFreeField1",
                // "cardholderFreeField2"
            ]
        }
    ]
}