import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStoreService {

  private subjects: Map<string, BehaviorSubject<any>>;
  private ls = window.localStorage;

  constructor() { 
    this.subjects = new Map<string, BehaviorSubject<any>>();
  }

  public setItem(key, value) {
    let stringValue = JSON.stringify(value)
    this.ls.setItem(key, stringValue)

    if (!this.subjects.has(key)) {
      this.subjects.set(key, new BehaviorSubject<any>(value))
    } else {
      this.subjects.get(key).next(value);
    }

    return true
  }

  public getItem(key) {
    let value = this.ls.getItem(key)
    try {
      return JSON.parse(value)
    } catch (e) {
      return null
    }
  }
  
  public clear() {
    this.ls.clear();
  }

  public watch(key: string) : Observable<any> {
    if (!this.subjects.has(key)) {
      this.subjects.set(key, new BehaviorSubject <any>(null));
    }

    var item = this.ls.getItem(key);
    if (item === "undefined") {
      item = undefined;
    } else {
      item = JSON.parse(item);
    }

    this.subjects.get(key).next(item);
    return this.subjects.get(key).asObservable();
  }
}