import { Injectable, Renderer2 } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { getQueryParam } from "../helpers/url.helper";
import { ThemeService } from "./theme.service";
import { EnvSettings } from "../models/env-settings.model";
import { LocalStoreService } from "./local-store.service";
import { EnvSettingsService } from "./env-settings.service";
import { environment } from '../../../environments/environment';

export interface ILayoutConf {
  navigationPos?: string; // side
  sidebarStyle?: string; // full, compact, closed
  sidebarCompactToggle?: boolean; // sidebar expandable on hover
  //sidebarColor?: string; // Sidebar background color 
  isMobile?: boolean; // updated automatically
  useBreadcrumb?: boolean; // Breadcrumb enabled/disabled
  breadcrumb?: string; // simple, title
  topbarFixed?: boolean; // Fixed header
  footerFixed?: boolean; // Fixed Footer
  //topbarColor?: string; // Header background color 
  //footerColor?: string; // Header background color 
  matTheme?: string; // theme. 
  perfectScrollbar?: boolean;
  themeColor?: string;
}
export interface ILayoutChangeOptions {
  duration?: number;
  transitionClass?: boolean;
}
interface IAdjustScreenOptions {
  browserEvent?: any;
  route?: string;
}

@Injectable({
  providedIn: "root"
})
export class LayoutService {
  public layoutConf: ILayoutConf;
  layoutConfSubject = new BehaviorSubject<ILayoutConf>(this.layoutConf);
  layoutConf$ = this.layoutConfSubject.asObservable();
  public isMobile: boolean;
  public currentRoute: string;
  public fullWidthRoutes = [];

  constructor(private themeService: ThemeService, private envSettings: EnvSettingsService, private ls: LocalStoreService) {
    this.initFromEnvSettings();
  }

  private initFromEnvSettings() {

    let baseTheme = {
          navigationPos: "side",
          sidebarStyle: "full", // full, compact, closed
          sidebarCompactToggle: false, // applied when "sidebarStyle" is "compact"
          useBreadcrumb: true,
          footerFixed: true,
          matTheme: this.themeService.matxThemes[0].name,
          breadcrumb: "simple", // simple, title
          perfectScrollbar: true
        }

    let settings = this.envSettings.getCurrentEnvSettings();
    if (settings && settings.theme) {
      let findTheme = this.themeService.matxThemes.filter(x => x.name == settings.theme);
      if (findTheme.length > 0) {
        baseTheme.matTheme = settings.theme;
      }
    }    
    this.setAppLayout(baseTheme);
  }

  setAppLayout(layoutConf: ILayoutConf) {
    this.layoutConf = { ...this.layoutConf, ...layoutConf };
    this.applyMatTheme(this.layoutConf.matTheme);
  }

  publishLayoutChange(lc: ILayoutConf, opt: ILayoutChangeOptions = {}) {
    if (lc.matTheme) {
      if (this.layoutConf.matTheme !== lc.matTheme) {
        this.themeService.changeTheme(this.layoutConf.matTheme, lc.matTheme);
      }
    }

    this.layoutConf = Object.assign(this.layoutConf, lc);
    this.layoutConfSubject.next(this.layoutConf);
  }

  applyMatTheme(theme) {
    this.themeService.applyMatTheme(this.layoutConf.matTheme);
  }

  setLayoutFromQuery() {
    let layoutConfString = getQueryParam("layout");
    let prevTheme = this.layoutConf.matTheme;
    try {
      this.layoutConf = JSON.parse(layoutConfString);
      this.themeService.changeTheme(prevTheme, this.layoutConf.matTheme);
    } catch (e) {}
  }

  adjustLayout(options: IAdjustScreenOptions = {}) {
    let sidebarStyle: string;
    this.isMobile = this.isSm();
    this.currentRoute = options.route || this.currentRoute;
    sidebarStyle = this.isMobile ? "closed" : "full";

    if (this.currentRoute) {
      this.fullWidthRoutes.forEach(route => {
        if (this.currentRoute.indexOf(route) !== -1) {
          sidebarStyle = "closed";
        }
      });
    }

    this.publishLayoutChange({
      isMobile: this.isMobile,
      sidebarStyle: sidebarStyle
    });
  }
  isSm() {
    return window.matchMedia(`(max-width: 959px)`).matches;
  }
}
