export const config = {
  currentUserStorageKey: "CARDEX_ADMIN_CURRENT_USER",
  currentUserRoleAuthStorageKey: "CARDEX_ADMIN_CURRENT_USER_ROLE_AUTH",
  currentEnvSettingsStorageKey: "CARDEX_ADMIN_ENV_SETTINGS",
  currentRoleFunctionsStorageKey: "CARDEX_ADMIN_ROLE_FUNCTIONS",
  currentSystemActionTypesStatus: "CARDEX_ADMIN_ACTION_TYPES_STATUS",
  sessionUserStorageKey: "userData_adminCenterSPA",
  defaultEnvSettings: {
    language: 'en',
    theme: 'cardex-light',
    themeColor: '#191F2B',
    autoTimezone: true,
    timezone: 'UTC',
    tzOffset: 'UTC',
    dateFormat: 'M/d/yy',
    timeFormat: 'h:mm a',
    idleTimeoutInSeconds: 3600,
    isDarkTheme: false
  },
  csvExportOptions: {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: true,
    title: '',
    useBom: true,
    noDownload: false,
    headers: []
  },
  twilioUrl: "https://www.twilio.com/"
}