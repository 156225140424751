import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedMaterialModule } from "../shared-material.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { SearchModule } from "../search/search.module";
import { SharedPipesModule } from "../pipes/shared-pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SharedDirectivesModule } from "../directives/shared-directives.module";

// ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT
import { HeaderSideComponent } from "./header-side/header-side.component";
import { SidebarSideComponent } from "./sidebar-side/sidebar-side.component";

// ONLY FOR DEMO
import { CustomizerComponent } from "./customizer/customizer.component";

// ALWAYS REQUIRED
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { AppSettingsComponent } from "./app-settings/app-settings.component";
import { SidenavComponent } from "./sidenav/sidenav.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { AppComfirmComponent } from "../services/app-confirm/app-confirm.component";
import { AppLoaderComponent } from "../services/app-loader/app-loader.component";

import { ButtonLoadingComponent } from "./button-loading/button-loading.component";

import {TranslateModule} from '@ngx-translate/core';
import { ErrorSnackbarComponent } from "./error-snackbar/error-snackbar.component";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { SimpleDialogComponent } from "./simple-dialog/simple-dialog.component";
import { SubscribedAppsComponent } from "./subscribed-apps/subscribed-apps.component";
import { PasswordChangeDialogComponent } from "./password-change-dialog/password-change-dialog.component";
import { ExtraPanelFormComponent } from "./extra-panel-form/extra-panel-form.component";
import { ConditionsDialogComponent } from "./extra-panel-form/conditions-dialog/conditions-dialog.component";
import { FilterPersonsCardComponent } from "./filter-persons-card/filter-persons-card.component";
import { ColorPickerModule } from 'ngx-color-picker';
import { StandTemplatesComponent } from "./stand-templates/stand-templates.component";


const components = [
  SidenavComponent,
  NotificationsComponent,
  AppSettingsComponent,
  SidebarSideComponent,
  HeaderSideComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  BreadcrumbComponent,
  AppComfirmComponent,
  AppLoaderComponent,
  ButtonLoadingComponent,
  CustomizerComponent,
  FooterComponent,
  ErrorSnackbarComponent,
  ConfirmationDialogComponent,
  SimpleDialogComponent,
  SubscribedAppsComponent,
  PasswordChangeDialogComponent,
  ExtraPanelFormComponent,
  ConditionsDialogComponent,
  FilterPersonsCardComponent,
  StandTemplatesComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    SearchModule,
    SharedPipesModule,
    SharedDirectivesModule,
    SharedMaterialModule,
    TranslateModule,
    ColorPickerModule
  ],
  declarations: components,
  entryComponents: [
    AppComfirmComponent,
    AppLoaderComponent
  ],
  exports: components
})
export class SharedComponentsModule {}
