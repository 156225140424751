import { Component, OnInit, Inject } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { environment } from 'environments/environment';
import jwt_decode from 'jwt-decode';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-subscribed-apps',
  templateUrl: './subscribed-apps.component.html',
  styleUrls: ['./subscribed-apps.component.scss']
})
// OBSOLETE 
export class SubscribedAppsComponent implements OnInit {

  subscriptions: any[] = [];
  customer: any = {};

  extraProductInfo: any[] = [
    {pId: "ec7ef266-c0f4-4f50-998c-590698613f44", logo: "ce_logo_controller_210.png", url: "", show: true}, // ControllerCMS
    {pId: "55924b78-651b-4d1b-b2bb-a0560cde3e57", logo: "ce_logo_stand_210.png", url: "", show: true}, // Stand
    {pId: "8f2809a5-07c8-4bba-9047-ae1f4868a123", logo: "ce_logo_press_210.png", url: "", show: true}, // Press
    {pId: "f5b6dfc5-92a9-4e7a-80fe-ad08fffa2997", logo: "ce_logo_bridge_210.png", url: "", show: true} // API
  ];

  constructor(
              public dialogRef: MatDialogRef<SubscribedAppsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private router: Router,
              private translate: TranslateService,
              private ls: LocalStoreService,
              private oidcSecurityService: OidcSecurityService) {}

  ngOnInit() {

    
    //subscriptions
    let user = this.ls.getItem(environment.config.currentUserStorageKey);
    let token = this.oidcSecurityService.getToken();
    if (user && token) {
      let tokenData: any = jwt_decode(token);
      // Add Controller Subscription data
      if(tokenData && tokenData.controller_maximumactiveids){
        this.subscriptions.push({
          productName: this.extraProductInfo[0].productName,
          logo: this.extraProductInfo[0].logo,
          url : this.extraProductInfo[0].url,
          show : this.extraProductInfo[0].show,
          maximumActiveIds: tokenData.controller_maximumactiveids
        });
        //Get Licensed cloud subscriptions
        Object.entries(tokenData).forEach(([key, value]) => {
          if (value === 'licensed') {
            const productInfo = this.extraProductInfo.find(info => info.name === key);
            if (productInfo) {
              this.subscriptions.push({
                productName: productInfo.productName,
                logo: productInfo.logo,
                url : productInfo.url,
                show : productInfo.show
              });
            }
          }
        });
      }
      this.customer = {
        customerd: user.customer_id, 
        customerName: user.customer_name
      };
    }

    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.dialogRef.close(false);
        }
    });
  }

  closePanel() {    
    this.dialogRef.close();
  }

}
