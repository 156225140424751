<div id="passwordChangeDialog">
  <mat-card class="p-0">
      <mat-card-title class="">
          <div class="card-title-text"> {{ 'APP_SETTINGS.CHANGE_PASSWORD' | translate }} </div>
          <mat-divider></mat-divider>
      </mat-card-title>
      <mat-card-content>
        <form role="form" [formGroup]="passwordForm">
          <div fxLayout="row wrap">
              <div fxFlex="100" fxFlex.gt-xs="100" class="pr-1">

                  <div class="pb-1">
                      <mat-form-field class="full-width">
                          <input
                          matInput
                          type="password"
                          name="oldPassword"
                          formControlName="oldPassword"
                          placeholder="{{ 'APP_SETTINGS.OLD_PASSWORD' | translate }}">
                      </mat-form-field>
                      <small 
                      *ngIf="passwordForm.controls['oldPassword'].hasError('required')" 
                      class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.OLD_PASSWORD_REQUIRED' | translate }} </small>
                  </div>

                  <div class="pb-1">
                    <mat-form-field class="full-width">
                        <input
                        matInput
                        type="password"
                        name="newPassword"
                        formControlName="newPassword"
                        placeholder="{{ 'APP_SETTINGS.NEW_PASSWORD' | translate }}">
                    </mat-form-field>
                    <!--<small 
                    *ngIf="passwordForm.controls['newPassword'].hasError('required') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.NEW_PASSWORD_REQUIRED' | translate }} </small>-->
                    <small 
                    *ngIf="passwordForm.controls['newPassword'].hasError('hasNumber') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.NEW_PASSWORD_HAS_NUMBER' | translate }} </small>
                    <small 
                    *ngIf="passwordForm.controls['newPassword'].hasError('minlength') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.NEW_PASSWORD_MIN_SIZE' | translate }} </small>
                    <small 
                    *ngIf="passwordForm.controls['newPassword'].hasError('hasUpcaseLetter') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.NEW_PASSWORD_HAS_UPCASE' | translate }} </small>
                    <small 
                    *ngIf="passwordForm.controls['newPassword'].hasError('hasLowcaseLetter') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.NEW_PASSWORD_HAS_LOWCASE' | translate }} </small>
                    <small 
                    *ngIf="passwordForm.controls['newPassword'].hasError('hasSpecialCharacters') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.NEW_PASSWORD_HAS_SPECIAL_CHARS' | translate }} </small>
                  </div>

                  <div class="pb-1">
                    <mat-form-field class="full-width">
                        <input
                        matInput
                        type="password"
                        name="confirmNewPassword"
                        formControlName="confirmNewPassword"
                        placeholder="{{ 'APP_SETTINGS.CONFIRM_NEW_PASSWORD' | translate }}">
                    </mat-form-field>
                    <small 
                    *ngIf="passwordForm.hasError('mismatch') && passwordForm.controls['newPassword'].touched" 
                    class="form-error-msg"> {{ 'APP_SETTINGS.VALIDATIONS.PASSWORD_CONFIRMATION_MISMATCH' | translate }} </small>
                  </div>                  
              </div>
          </div>
        </form> 
      </mat-card-content>
      <mat-card-footer>
        <mat-card-actions class="yes-no-wrapper">
          <button 
            mat-raised-button
            [disabled]="passwordForm.invalid" 
            (click)="onSaveClick()"> {{ 'FORM_CTRL.SAVE' | translate }} </button>
          <button mat-raised-button (click)="onCancelClick()" cdkFocusInitial>  {{ 'FORM_CTRL.CANCEL' | translate }} </button>
        </mat-card-actions>
      </mat-card-footer>
  </mat-card>
</div>