export class StandBarcodeSourceFieldsDB {

    public static standBarcodeSourceFields = [
        {
            "entity": "Persons",
            "fields": [
                "PersonNumber",
                "AddressPreference",
                "IdentificationType",
                "IdentificationTypeValue",
                "FirstName",
                "MiddleName",
                "LastName",
                "BirthDate",
                "Gender",
                "Prefix",
                "Suffix",
                "PhoneNumber",
                "MobileNumber",
                "Status",
                "ProfilePreference",
                "CreatedDateUtc",
                "Origin"
            ]
        },
        {
            "entity": "Cardholders",
            "fields": [
                "Email",
                "BusinessUnit",
                "ExternalCompany",
                "Department",
                "CostAccount",
                "Location",
                "JobTitle",
                "StartDate",
                "EndDate",
                "Status",
                "NameOnCard",
                "Note",
                "Created",
                "ProfileNumber",
                "FreeField1",
                "FreeField2"
            ]
        },
        {
            "entity": "Cards",
            "fields": [
                "CreatedDateUtc",
                "ModifiedDateUtc",
                "CardNumber",
                "ChipserialNumber",
                "Status",
                "StartDateUtc",
                "EndDateUtc",
                "ActivatedOnUtc"
            ]
        }
    ]
}