export class CredentialExpiryDateSourcesDB {
    public static credentialExpiryDateSources = [
        {
            "langKey": "Fixed_Date",
            "code": "fixed_date"
        },
        {
            "langKey": "Credential_Valid_Through",
            "code": "card_validation_days"
        },
        {
            "langKey": "Follow_Profile_End_Date",
            "code": "profile_end_date"
        }
    ]
}