export interface SearchParam {
    'field': string;
    'value': any;
}

export interface FilterParam {
    'field': string;
    'value': any;
    'operator': string;
}

export class RequestParamNamesHelper {    

    public getPageParamName(newapi: boolean = true) {
        return newapi ? 'page' : '_page';
        //return 'page';
    }
    
    getPerPageParamName(newapi: boolean = true) {
        return newapi ? 'per_page' : '_limit';
        //return 'per_page';
    }
    
    getSortParamName(newapi: boolean = true) {
        return newapi ? 'orderBy' : '_sort';
    }
    
    getSortOrderParamName(newapi: boolean = true) {
        return newapi ? 'ascending' : '_order';
    }
    
    getSortOrderParamValue(order) {
        return order == "asc" ? "true" : "false";
    }
    
    getSearchFieldsParamName() {
        return "search_columns";
    }

    getFilterConditionParamName() {
        return "filter_conditions";
    }

    getSearchQueryParamName() {
        return "q";
    }

    getIncludePhotosParamName() {
        return "include_photos";
    }
    getHideFromListParamName() {
        return "hide_from_list";
    }
}