export class CustomFieldsApplicationsDB {

    public static applications = [
      {
        "label": "STAND",
        "code": "standMobApp",
        "enabled": true
      },
      {
        "label": "HFC",
        "code": "hfc",
        "enabled": true
      },
      {
        "label": "CONTROLLERCMS",
        "code": "spa",
        "enabled": true
      }
    ]      
}