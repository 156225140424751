import { Component, OnInit, OnDestroy, AfterViewInit, Inject } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";

import { DOCUMENT } from "@angular/common";

import { EnvSettingsService } from "app/shared/services/env-settings.service";
import { environment } from "environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    private envSettings: EnvSettingsService,
    @Inject(DOCUMENT) private document: Document,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;
    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      if (this.document.location.hostname != "localhost" && this.document.location.hostname != "cardexchangecloudadmindev.azurewebsites.net") {
        menuItem.forEach(item => {
          if (item.name == "SETTINGS" || item.name == "SETUP") {
            for (let index = 0; index < item.sub.length; index++) {
              if (item.sub[index].name == "TRANSLATIONS" || item.sub[index].name == "CUSTOM_FIELDS") {
                item.sub.splice(index, 1);
                index--;
              } else if (item.sub[index].name == "STAND") {
                for (let index2 = 0; index2 < item.sub[index].sub.length; index2++) {
                  if (item.sub[index].sub[index2].name == "PAYMENT_ARTICLES") {
                    item.sub[index].sub.splice(index2, 1);
                    index2--;
                  }
                }
              }
            }
          }
        });
      }
      this.menuItems = menuItem;
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;
  }
  ngAfterViewInit() {
    this.initFromEnvSettings();
  }

  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {

    if (this.layoutConf.sidebarCompactToggle) {
      this.layout.publishLayoutChange({
        sidebarStyle: 'full',
        sidebarCompactToggle: false
      }, { transitionClass: true })
    } else {
      // * --> compact
      this.layout.publishLayoutChange({
        sidebarStyle: 'compact',
        sidebarCompactToggle: true
      }, { transitionClass: true })
    }

  }

  private initFromEnvSettings() {

    this.envSettings.loadEnvSettingsForLoggedUser().subscribe((res: boolean) => {
      let settings = this.envSettings.getCurrentEnvSettings();
      if (settings && settings.language) {
        this.translate.use(settings.language);
        this.document.documentElement.lang = settings.language;
      }
    },
      error => {
      });
  }
}
