import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Toast,ToastPackage,ToastrService } from 'ngx-toastr';

@Component({
  selector: 'omni-cloud-toastr',
  templateUrl: './omni-cloud-toastr.component.html',
  styleUrls: ['./omni-cloud-toastr.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          opacity: 0,
        }),
        style({
          opacity: 1,
        })
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(10%, 0, 0) skewX(10deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
})

export class OmniCloudToastrComponent extends Toast{

  toastRef;
  showDetails: boolean = false;
  
  constructor(
    protected toastrService: ToastrService, 
    public toastPackage: ToastPackage, 
    private translate: TranslateService) {
    super(toastrService,toastPackage);
  }

  toggleDetails() {
    this.showDetails = (this.showDetails) ? false : true;
  }

}

