export class PersonStatusDb {
    public static status = [
        {
            "code": "None",
            "langKey": "None"
        },
        {
            "code": "Blocked",
            "langKey": "Blocked"
        },
        {
            "code": "Activated",
            "langKey": "Activated"
        }
    ]
}