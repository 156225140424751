export class SystemActionReceiverTypesDB {

    public static actionReceiverTypes = [
      {
        "label": "PERSON",
        "name": "person",
        "requires_detail": ""
      },
      {
        "label": "PROFILE",
        "name": "profile",
        "requires_detail": ""
      },
      {
        "label": "USER",
        "name": "user",
        "requires_detail": "user"
      }
    ]      
}