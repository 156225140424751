import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Inject } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { GroupService } from 'app/shared/services/groups/group.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StaticDataService } from 'app/shared/services/static-data.service';
import { PersonFilterCondition } from 'app/shared/models/storage.management.model';
import { IMetadata } from 'app/interfaces/omni-cloud/user-metadata.interface';
import { Select } from '@ngxs/store';
import { AuthenticationState } from 'app/auth/authentication-store/authentication.state';
import { Observable } from 'rxjs';
import { SystemLabelService } from 'app/shared/services/system-labels/system-label.service';
import { map, switchMap } from 'rxjs/operators';
import { SystemLabel } from 'app/interfaces/omni-cloud/system-label.interface';

@Component({
    selector: 'app-filter-persons-card',
    templateUrl: './filter-persons-card.component.html',
    styleUrls: ['./filter-persons-card.component.scss']
})
export class FilterPersonsCardComponent implements OnInit {
    @Select(AuthenticationState.omnicloudmetadata) usermetadata$: Observable<Array<IMetadata>>;

    systemLabelList: Array<SystemLabel> = null;
    attributesList: Array<{entity: string, attributes: IMetadata[]}> = null;

    entityUIPhrase = {
        'person': 'Person',
        'cardholder': 'Profile',
        'card': 'Card'
    };
    
    @Output() closed = new EventEmitter();
    @ViewChild(MatSort, { static: false }) sort: MatSort;

    @ViewChild('inputValue') inputValue: ElementRef;

    operators = null;

    displayedFilterColumns: string[] = [];
    loading: boolean;

    filterForm: FormGroup;
    filterConditionList = new MatTableDataSource<PersonFilterCondition>();

    constructor(
        public dialog: MatDialog,
        public dialogRef: MatDialogRef<FilterPersonsCardComponent>,
        private translate: TranslateService,
        private groupService: GroupService,
        private staticData: StaticDataService,
        private toastr: ToastrService,
        private systemLabelService: SystemLabelService,
        @Inject(MAT_DIALOG_DATA) public data: PersonFilterCondition[]) {
        this.operators = this.staticData.getArchivePersonsFilterOperators();
        
        if (data.length > 0) {
            this.filterConditionList = new MatTableDataSource<PersonFilterCondition>(JSON.parse(JSON.stringify(data)));
        }
    }

    ngOnInit(): void {
        this.displayedFilterColumns = this.groupService.getGroupFiltersTableDataConf().map((c) => c.prop);

        this.usermetadata$.pipe(
            switchMap(userMetaData => {
                //because we are retrieving from the source, the logic below will also change the values 
                //from userMetaData because of javascripts object reference.
                userMetaData = JSON.parse(JSON.stringify(userMetaData));
                let attributesList: Array<{entity: string, attributes: IMetadata[]}> = [];

                const entityList = ["person", "cardholder", "card"];
                entityList.forEach(entity => {
                    let attributes = userMetaData.filter(m => entity === m.attribute.entity && m.attribute.searchable &&
                    (m.permission === "read" || m.permission === "write"));

                    //these conditions are needed to differentiate Card.Status with Cardholder.Status
                    //vwPersons (FullPersonDetails) in db has its names this way (Nov022021)
                    if (entity == 'cardholder') {
                        const cardHolderStatusIndex = attributes.findIndex(a => a.attribute.name === 'Status');
                        attributes[cardHolderStatusIndex].attribute.name = 'CardholderStatus';

                        const cardHolderFreeField1Index = attributes.findIndex(a => a.attribute.name === 'FreeField1');
                        attributes[cardHolderFreeField1Index].attribute.name = 'CardholderFreeField1';

                        const cardHolderFreeField2Index = attributes.findIndex(a => a.attribute.name === 'FreeField2');
                        attributes[cardHolderFreeField2Index].attribute.name = 'CardholderFreeField2';
                    } else if (entity == 'card') {
                        const cardStatusIndex = attributes.findIndex(a => a.attribute.name === 'Status');
                        attributes[cardStatusIndex].attribute.name = 'CardStatus';
                    }

                    attributesList.push({
                        entity: entity,
                        attributes: attributes
                    });
                });

                return this.systemLabelService.getSystemLabel()
                    .pipe(map((systemLabelList: SystemLabel[]) => ({ attributesList, systemLabelList})));
            })
        )
        .subscribe(({ attributesList, systemLabelList}) => {
            this.attributesList = JSON.parse(JSON.stringify(attributesList));
            this.systemLabelList = JSON.parse(JSON.stringify(systemLabelList));
        });

        this.setupForm();
    }

    getLabel(attribute: IMetadata) {
        const systemLabel = this.systemLabelList.find(s => s.attributeId === attribute.attribute.id);
    
        return (systemLabel) ? systemLabel.label : attribute.attribute.translation;
    }

    setupForm() {
        if (this.filterForm == undefined) {
            this.filterForm = new FormGroup({
                filterField: new FormControl("", [Validators.required]),
                filterOperator: new FormControl("", [Validators.required]),
                filterValue: new FormControl("", [Validators.required])
            });
        } else {
            this.filterForm.get('filterField').reset("", [Validators.required]);
            this.filterForm.get('filterOperator').reset("", [Validators.required]);
            this.filterForm.get('filterValue').reset("", [Validators.required]);
        }
    }

    clearNewFilterInputs() {
        this.setupForm();
    }

    onAddNewFilterCondition(metaDataList: IMetadata[]) {

        let filterInfo = {
            field: this.filterForm.value.filterField,
            operator: this.filterForm.value.filterOperator,
            values: this.filterForm.value.filterValue
        } as PersonFilterCondition;
        let exists = false;
        
        this.filterConditionList.data.map(filter => {
            if (filter.field == filterInfo.field && filter.operator == filterInfo.operator && filter.values == filterInfo.values) {
                exists = true;
            }
        });
        if (!exists) {
            var filterList = this.filterConditionList.data;
            filterList.push(filterInfo);
            this.filterConditionList.data = filterList;

            this.clearNewFilterInputs();
        } else {
            this.toastr.error(this.translate.instant('ERROR.FILTER_PERSONS_EXISTS_MESSAGE'), this.translate.instant('ERROR.FILTER_PERSONS_EXISTS_TITLE'));
        }
    }

    removeFilter(filter: PersonFilterCondition) {
        var filterList = this.filterConditionList.data;
        let index = filterList.findIndex(f => f.field == filter.field && f.values == filter.values && f.operator == filter.operator);
        filterList.splice(index, 1);

        this.filterConditionList.data = filterList;
    }

    cancel() {
        this.dialogRef.close();
    }

    save() {
        if (this.filterConditionList.data.length > 0) {
            this.dialogRef.close(JSON.parse(JSON.stringify(this.filterConditionList.data)));
        } else {
            this.toastr.error(this.translate.instant('ERROR.FILTER_PERSONS_LIST_EMPTY_MESSAGE'), this.translate.instant('ERROR.FILTER_PERSONS_LIST_EMPTY_TITLE'));
        }
    }
}
