export class EmailPlaceholdersPhotoDB {

    public static placeholders = [
        {
            "key": "{RejectReason}",
            "required": false
        },
        {
            "key": "{PhotoStatus}",
            "required": false
        }
    ];
}