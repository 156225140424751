export class CompareAndApproveDB{
    public static compareAndApproveList = [
        {
            "label":"SETUP.CONTROLLER_CMS.PHOTOS.COMPARE_AGAINST_HISTORIC",
            "value": "compareAgainstHistoric"
        },
        {
            "label":"SETUP.CONTROLLER_CMS.PHOTOS.COMPARE_AGAINST_ID",
            "value":"compareAgainstId"
        }
    ];
}