import { Injectable } from '@angular/core';
import { TimezoneDB } from '../data/timezones';
import { DateFormatsDB } from '../data/date-formats';
import { TimeFormatsDB } from '../data/time-formats';
import { EmailAuthenticationModesDB } from '../data/email-authentication-modes';
import { CredentialEncryptionModesDB } from '../data/credential-encryption-modes';
import { AddressTypesDB } from '../data/address-types';
import { CropRatiosDB } from '../data/crop-ratios';
import { LanguagesDB } from '../data/languages';
import { MessagePlaceholdersDB } from '../data/message-placeholders';
import { FargoToPrintOriginAttributesDB } from '../data/fargo-toprint-origin-attributes';
import { FargoFromPrintOriginAttributesDB } from '../data/fargo-fromprint-origin-attributes';
import { FargoFromPrintEntityAttributesDB } from '../data/fargo-fromprint-entity-attributes';
import { SystemActionTypesDB } from '../data/system-action-types';
import { SystemActionReceiverTypesDB } from '../data/system-action-receiver-types';
import { GroupFiltersFieldsDB } from '../data/group-filters-fields';
import { GroupFiltersOperatorsDB } from '../data/group-filters-operators';
import { StandColorThemesDB } from '../data/stand-color-themes';
import { CeCloudNotificationsTargetApplicationsDB } from '../data/ce-cloud-notifications-target-applications';
import { AnalyticsBaseDB } from '../data/analytic-base';
import { CurrenciesDB } from '../data/currencies';
import { CustomFieldsApplicationsDB } from '../data/custom-fields-applications';
import { CustomFieldsDependendOnFieldsDB } from '../data/custom-fields-depend-on-fields';
import { CustomFieldsTypesDB } from '../data/custom-fields-types';
import { CustomFieldsTypeValidtionsDB } from '../data/custom-fields-type-validations';
import { BusinessRulesConditionOperatorsDB } from '../data/business-rules-condition-operators';
import { BusinessRulesFieldModifiersDB } from '../data/business-rules-field-modifiers';
import { BusinessRulesConditionFieldsDB } from '../data/business-rules-condition-fields';
import { GendersDB } from '../data/genders';
import { WorkflowEventsDB } from '../data/workflow-events';
import { TranslationsApplicationsDB } from '../data/translations-applications';
import { ArchivePersonsFilterFieldsDB } from '../data/archive-persons-filter-fields';
import { ArchivePersonsFilterOperatorsDB } from '../data/archive-persons-filter-operators';
import { StandBarcodeSourceFieldsDB } from '../data/stand-barcode-source-fields';
import { CompareAndApproveDB } from '../data/compare-and-approve';
import { IdleTimeoutOptionsDB } from '../data/idle-timeout-options';
import { CredentialStatusesDB } from '../data/credential-statuses';
import { PhotoStatusDb } from '../data/photo-status';
import { PhotoRejectReasonDb } from '../data/photo-reject-reason';
import { StandBarcodeTypesDB } from '../data/stand-barcode-types';
import { CredentialExpiryDateSourcesDB } from '../data/credential-expiry-date-sources';
import { CredentialStartDateSourcesDB } from '../data/credential-start-date-sources';
import { DigitalCardSystemsDB } from '../data/digital-card-systems';
import { ProviderKeyFieldDB } from "../data/provider-key-field";
import { ProviderKeyHandling } from '../data/provider-key-handling';
import { PhotoApprovalPlaceholdersDB } from '../data/photo-apporval-placeholders';
import { CredentialColumnsDB } from '../data/credential-columns';
import { DefaultPhotoRejectReasonsDB } from '../data/default-photo-reject-reasons';
import { DefaultCardBlockReason } from '../data/default-card-block-reasons';
import { CardStatusDb } from '../data/card-status';
import { PersonStatusDb } from '../data/person-status';
import { PersonBlockReasonDb } from '../data/person-block-reason';
import { CustomCredentialOptionDB } from '../data/custom-credential-options';
import { PersonArchiveStatus } from '../data/person-archive-status';
import { DefaultStandDetailsField } from '../data/default-stand-details-field';
import { EmailPlaceholdersPersonDB } from '../data/email-placeholder-persons';
import { EmailPlaceholdersProfileDB } from '../data/email-placeholder-profiles';
import { EmailPlaceholdersCredentialDB } from '../data/email-placeholder-credentials';
import { EmailPlaceholdersPhotoDB } from '../data/email-placeholder-photo';

export interface ITimezone {
  value: string;
  abbr: string;
  offset: string;
  isdst: boolean;
  text: string;
}

export interface IDateTimeFormat {
  text: string;
  format: string;
}

export interface IFargoDateFormat {
  text: string;
  format: string;
  value: string;
}

export interface ICodeLangObject {
  code: string;
  langKey: string;
  valueToShow?: string;
}

export interface ISystemCodeLangObject {
  code: string;
  langKey: string;
  valueToShow?: string;
  id: string;
}

export interface ICropRatio {
  value: number;
  ratio: string;
}

export interface ICredentialStatus {
  label: string;
  value: string;
}

export interface IPlaceholder {
  key: string;
  required: boolean;
}

export interface ISystemActionType {
  label: string;
  name: string;
  enabled: boolean;
  disabled_check: string;
  has_receiver: boolean;
  receiver_types: string[];
  fields: string[];
}

export interface ISystemActionReceiverType {
  label: string;
  name: string;
  requires_detail: string;
}

export interface IEntityFields {
  entity: string;
  fields: string[];
}

export interface IIdleTimeoutOption {
  name: string,
  value: number
}

export interface IProviderKeyField {
  display: string;
  value: string;
  tooltip: string;
}

export interface IEntityField extends ICredentialStatus {};
export interface ICompareAndApprove extends ICredentialStatus {};
export interface ICustomCredentialOption extends ICredentialStatus {};

@Injectable({
  providedIn: 'root'
})
export class StaticDataService {

  constructor() { }

  getTimezones(): ITimezone[] {
    return TimezoneDB.timezones;
  }

  getDateFormats(): IDateTimeFormat[] {
    return DateFormatsDB.dateFormats;
  }
  getFargoDateFormats(): IFargoDateFormat[] {
    return DateFormatsDB.fargoDateFormats;
  }

  getTimeFormats(): IDateTimeFormat[] {
    return TimeFormatsDB.timeFormats;
  }

  getEmailAuthenticationModes(): ICodeLangObject[] {
    return EmailAuthenticationModesDB.emailAuthenticationModes;
  }

  getCredentialEncryptionModes(): ICodeLangObject[] {
    return CredentialEncryptionModesDB.credentialEncryptionModes;
  }

  getCredentialExpiryDateSources(): ICodeLangObject[] {
    return CredentialExpiryDateSourcesDB.credentialExpiryDateSources;
  }

  getCredentialStartDateSources(): ICodeLangObject[] {
    return CredentialStartDateSourcesDB.credentialStartDateSources;
  }

  getCredentialStatuses(): ICredentialStatus[] {
    return CredentialStatusesDB.credentialStatuses;
  }

  getAddressTypes(): ICodeLangObject[] {
    return AddressTypesDB.addressTypes;
  }

  getGenders(): ICodeLangObject[] {
    return GendersDB.genders;
  }

  getCropRatios(): ICropRatio[] {
    return CropRatiosDB.cropRatios;
  }

  getLanguages(): string[] {
    return LanguagesDB.languages;
  }

  getMessagePlaceholders(): IPlaceholder[] {
    return MessagePlaceholdersDB.placeholders;
  }

  getFargoToPrintOriginAttributes(): IEntityFields[] {
    return FargoToPrintOriginAttributesDB.originAttributes;
  }

  getFargoFromPrintOriginAttributes(): string[] {
    return FargoFromPrintOriginAttributesDB.originAttributes;
  }

  getFargoFromPrintEntityAttributes(): IEntityFields[] {
    return FargoFromPrintEntityAttributesDB.entityAttributes;
  }

  getSystemActionTypes(): ISystemActionType[] {
    return SystemActionTypesDB.actionTypes;
  }

  getSystemActionReceiverTypes(): ISystemActionReceiverType[] {
    return SystemActionReceiverTypesDB.actionReceiverTypes;
  }

  getArchivePersonsFilterFields(): IEntityFields[] {
    return ArchivePersonsFilterFieldsDB.archivePersonsFilterFields;
  }

  getArchivePersonsFilterOperators(): ICodeLangObject[] {
    return ArchivePersonsFilterOperatorsDB.archivePersonsFilterOperators;
  }

  getGroupFiltersFields(): IEntityFields[] {
    return GroupFiltersFieldsDB.groupFilterFields;
  }

  getGroupFiltersOperators(): ICodeLangObject[] {
    return GroupFiltersOperatorsDB.groupFilterOperators;
  }

  getStandColorThemes(): {} {
    return StandColorThemesDB.colorThemes;
  }

  getCeCloudNotificationsTargetAplications(): {} {
    return CeCloudNotificationsTargetApplicationsDB.targetApplications;
  }

  getUserAnalyticsBase(): {} {
    return AnalyticsBaseDB.userStats;
  }

  getPersonAnalyticsBase(): {} {
    return AnalyticsBaseDB.personStats;
  }

  getEnrollmentAnalyticsBase(): {} {
    return AnalyticsBaseDB.enrollmentStats;
  }

  getProductListBase(): {} {
    return AnalyticsBaseDB.productList;
  }

  getCurrencies(): {} {
    return CurrenciesDB.currencies;
  }

  getCustomFieldsApplications(): {} {
    return CustomFieldsApplicationsDB.applications;
  }

  getCustomFieldsDependOnFields(): {} {
    return CustomFieldsDependendOnFieldsDB.fields;
  }

  getPersonFields(): IEntityFields[] {
    return CustomFieldsDependendOnFieldsDB.personFields;
  }

  getCustomFieldsTypes(): {} {
    return CustomFieldsTypesDB.customFieldsTypes;
  }

  getCustomFieldsTypeValidations(): {} {
    return CustomFieldsTypeValidtionsDB.customFieldsTypeValidations;
  }

  getTranslationsApplications(): {} {
    return TranslationsApplicationsDB.applications;
  }

  getBusinessRulesConditionOperators(): ICodeLangObject[] {
    return BusinessRulesConditionOperatorsDB.conditionOperators;
  }

  getBusinessRulesFieldModifiers(): ICodeLangObject[] {
    return BusinessRulesFieldModifiersDB.fieldModifiers;
  }

  getBusinessRulesConditionFields(): {} {
    return BusinessRulesConditionFieldsDB.conditionFields;
  }

  getStandBarcodeSourceFields(): {} {
    return StandBarcodeSourceFieldsDB.standBarcodeSourceFields;
  }

  getStandBarcodeTypes(): ICodeLangObject[] {
    return StandBarcodeTypesDB.barcodeTypes;
  }

  getWorkflowEvents(): {} {
    return WorkflowEventsDB.events;
  }

  getCompareAndApprove(): ICompareAndApprove[] {
    return CompareAndApproveDB.compareAndApproveList;
  }

  getIdleTimeoutOptions(): IIdleTimeoutOption[] {
    return IdleTimeoutOptionsDB.timeoutOptions;
  }

  getPhotoStatus(): ICodeLangObject[] {
    return PhotoStatusDb.status;
  }

  getPhotoRejectReason(): ICodeLangObject[] {
    return PhotoRejectReasonDb.reason;
  }

  getDigitalCardSystems(): ISystemCodeLangObject[] {
    return DigitalCardSystemsDB.system;
  }

  getProviderKeyField(): IEntityFields[] {
    return ProviderKeyFieldDB.providerKeyFields;
  }

  getProviderKeyHandling(): IProviderKeyField[] {
    return ProviderKeyHandling.providerKeyHandling;
  }

  getPhotoApprovalPlaceholders(): IPlaceholder[] {
    return PhotoApprovalPlaceholdersDB.placeholders;
  }
  getEmailPersonsEntityPlaceholders(): IPlaceholder[] {
    return EmailPlaceholdersPersonDB.placeholders;
  }
  getEmailProfilesEntityPlaceholders(): IPlaceholder[] {
    return EmailPlaceholdersProfileDB.placeholders;
  }
  getEmailCredentialsEntityPlaceholders(): IPlaceholder[] {
    return EmailPlaceholdersCredentialDB.placeholders;
  }
  getEmailPhotoPlaceholders(): IPlaceholder[] {
    return EmailPlaceholdersPhotoDB.placeholders;
  }

  getCredentialColumns(): ICodeLangObject[] {
    return CredentialColumnsDB.columns;
  }
  
  getDefaultPhotoRejectReasons(): string[] {
    return DefaultPhotoRejectReasonsDB.reasons;
  }

  getDefaultCardBlockReason(): string[] {
    return DefaultCardBlockReason.reasons;
  }

  getCardStatus(): ICodeLangObject[] {
    return CardStatusDb.status;
  }

  getPersonStatus(): ICodeLangObject[] {
    return PersonStatusDb.status;
  }

  getPersonBlockReason(): ICodeLangObject[] {
    return PersonBlockReasonDb.reason;
  }

  getCredentialNumberOptions(): ICustomCredentialOption[] {
    return CustomCredentialOptionDB.options;
  }
  
  getPersonArchiveStatus(): ICodeLangObject[] {
    return PersonArchiveStatus.status;
  }

  getStandDetailsField(): {} {
    return DefaultStandDetailsField.includedFields;
  }
}
