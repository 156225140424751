export class TranslationsApplicationsDB {

    public static applications = [
      {
        "label": "ADMINCENTER",
        "code": "adminCenterSPA",
        "enabled": true
      },
      {
        "label": "CONTROLLERCMS",
        "code": "spa",
        "enabled": true
      },
      {
        "label": "STAND",
        "code": "standMobApp",
        "enabled": true
      }
    ]      
}