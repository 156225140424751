import { Injectable,Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import {environment} from 'environments/environment';
import { DOCUMENT } from '@angular/common';
import { catchError } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import {IMetadata, IOmniCloudMetadata} from '../../../interfaces/omni-cloud/user-metadata.interface';
import { IOmniCloudUserEntity } from '../../../interfaces/omni-cloud/state.interface';
import {SystemLabel} from '../../../interfaces/omni-cloud/system-label.interface';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { JwtHelperService } from '@auth0/angular-jwt';
import {LocalStoreService } from '../local-store.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  apiUrl = '';
  constructor(private http: HttpClient,  @Inject(DOCUMENT) private document: Document, private oidcSecurityService: OidcSecurityService,
  private ls: LocalStoreService) { 
    let envForSubdomain = environment.subdomains.find(elem => elem.subdomain == this.document.location.hostname);
    if(envForSubdomain != undefined) {
      this.apiUrl = envForSubdomain.webApiBaseUrl;
    }
    
  }
  

  omniCloudMetadata(): Observable<IOmniCloudMetadata> {
    let token = this.oidcSecurityService.getToken(); //add here the code to check the api url in token this function will be called first
    let jwtService = new JwtHelperService();
    let decodeToken = jwtService.decodeToken(token);
    if(decodeToken && decodeToken.api_url != undefined) {
      let baseApiUrl = decodeToken.api_url + '/';
      this.apiUrl = baseApiUrl + 'api/v1/';
      this.ls.setItem("api_url",baseApiUrl); //set global api_url to local storage that will be used in the app globally
    }
    return this.http.get<IOmniCloudMetadata>(this.apiUrl + 'users/current/metadata', { observe: 'response' }).pipe(
      map(val => (<IOmniCloudMetadata>val.body)),
      catchError((error) => {
        console.log(error);
        return throwError(error);
      })
    );
  }

  getSystemLabels() {
    return this.http.get(this.apiUrl + 'system-labels', { observe: 'response' }).pipe(
      map(httpResponse => httpResponse.body as Array<SystemLabel>)
    );
  }

  omniCloudUser() {
    
    let token = this.oidcSecurityService.getToken(); //add here the code to check the api url in token this function will be called first
    let jwtService = new JwtHelperService();
    let decodeToken = jwtService.decodeToken(token);
    if(decodeToken && decodeToken.api_url != undefined) {
      this.ls.setItem("api_url", decodeToken.api_url);
      this.apiUrl = decodeToken.api_url + '/api/v1/';
    }
    return this.http.get(this.apiUrl + 'users/current/loggedinuser?validateControllerPermission=true', { observe: 'response' }).pipe(
      map(val => (<IOmniCloudUserEntity>val.body))
    );
  }
}
