export class DateFormatsDB {

    public static dateFormats = [
        {
            "text": "M/d/yy",
            "format": "M/d/yy"
        },
        {
            "text": "d/M/yy",
            "format": "d/M/yy"
        },
        {
            "text": "MM/dd/y",
            "format": "MM/dd/y"
        },
        {
            "text": "dd/MM/y",
            "format": "dd/MM/y"
        },
        {
            "text": "MMM d, y",
            "format": "MMM d, y"
        },
        {
            "text": "EEEE, MMMM d, y",
            "format": "EEEE, MMMM d, y"
        }
    ]


    public static fargoDateFormats = [
        {
            "text": "MM/y",
            "format": "M/yy",
            "value": "MM/y"
        },
        {
            "text":"MM/yyyy",
            "format":"MM/y",
            "value":"MM/yyyy"
        },
        {
            "text": "MM/dd/y",
            "format": "M/d/yy",
            "value": "MM/dd/y"
        },
        {
            "text": "d/MM/y",
            "format": "d/M/yy",
            "value": "d/MM/y"
        },
        {
            "text": "MM/dd/yyyy",
            "format": "MM/dd/y",
            "value": "MM/dd/yyyy"
        },
        {
            "text": "dd/MM/yyyy",
            "format": "dd/MM/y",
            "value": "dd/MM/yyyy"
        },
        {
            "text": "MMM d, yyyy",
            "format": "MMM d, y",
            "value": "MMM d, yyyy"
        },
        {
            "text": "MMMM d, yyyy",
            "format": "MMMM d, y",
            "value": "MMMM d, yyyy"
        },
        {
            "text": "dddd, MMMM d, yyyy",
            "format": "EEEE, MMMM d, y",
            "value": "dddd, MMMM d, yyyy"
        },
        {
            "text": "MMMM yyyy",
            "format": "MMMM y",
            "value": "MMMMM yyyy"
        },
        {
            "text": "Custom Date Format",
            "format":"custom_format",
            "value":"custom_format"
        }
    ]
}