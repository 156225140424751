import { Inject, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DOCUMENT } from '@angular/common';
import { AuthModule, AuthWellKnownEndpoints, OidcConfigService, OidcSecurityService, OpenIDImplicitFlowConfiguration } from 'angular-auth-oidc-client';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { AUTHENTICATION_ROUTES } from './authentication.routing';
import { AuthenticationService } from './authentication.service';
// import { HttpAuthenticationInterception } from 'app/shared/interceptors/http-authentication-interceptor.service';
import { AuthGuard } from 'app/shared/guards/auth.guard';
import { OmniCloudAuthComponent } from './omni-cloud-auth/omni-cloud-auth.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from 'app/shared/components/error-snackbar/error-snackbar.component';
import { AuthenticationState } from './authentication-store/authentication.state';
import { NgxsModule, Store } from '@ngxs/store';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';


@NgModule({
  declarations: [OmniCloudAuthComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(AUTHENTICATION_ROUTES),
    AuthModule.forRoot(),
    HttpClientModule,
    NgxsModule.forFeature([AuthenticationState]),
    NgxsRouterPluginModule
  ],
  providers: [AuthenticationService, AuthGuard,
    // { provide: HTTP_INTERCEPTORS, useClass: HttpAuthenticationInterception, multi: true },
    OidcConfigService, OidcSecurityService],
})
export class AuthenticationModule { 

  constructor(public oidcSecurityService: OidcSecurityService,
    @Inject(DOCUMENT) private document: Document, private snackBar: MatSnackBar) {

    let envForSubdomain = environment.subdomains.find(elem => elem.subdomain == this.document.location.hostname);
    if (envForSubdomain == undefined) {
      this.snackBar.openFromComponent(ErrorSnackbarComponent,
      {
        data: 'No Auth address found.'
      });
      return;
    }

    // For usage with version 9.0.3 of the angular-auth-oidc-client package
    const openIDImplicitFlowConfiguration = new OpenIDImplicitFlowConfiguration();
    openIDImplicitFlowConfiguration.stsServer = envForSubdomain.authenticationUrl;
    openIDImplicitFlowConfiguration.redirect_url = envForSubdomain.adminCenterUrl + '/auth/login';
    openIDImplicitFlowConfiguration.client_id = 'adminCenterSPA';
    openIDImplicitFlowConfiguration.response_type = 'id_token token';
    openIDImplicitFlowConfiguration.scope = 'openid profile email roles customer subscription api1 IdentityServerApi';
    openIDImplicitFlowConfiguration.post_logout_redirect_uri = envForSubdomain.adminCenterUrl + '/auth/login';
    openIDImplicitFlowConfiguration.start_checksession = true;
    openIDImplicitFlowConfiguration.silent_renew = true;
    openIDImplicitFlowConfiguration.silent_renew_url = envForSubdomain.adminCenterUrl + '/silent-renew.html';
    openIDImplicitFlowConfiguration.post_login_route = envForSubdomain.adminCenterUrl;
    openIDImplicitFlowConfiguration.trigger_authorization_result_event = true;
    openIDImplicitFlowConfiguration.forbidden_route = '/Forbidden';
    openIDImplicitFlowConfiguration.unauthorized_route = '/Unauthorized';
    openIDImplicitFlowConfiguration.log_console_warning_active = false;
    openIDImplicitFlowConfiguration.log_console_debug_active = false;
    openIDImplicitFlowConfiguration.max_id_token_iat_offset_allowed_in_seconds = 1000;

    const authWellKnownEndpoints = new AuthWellKnownEndpoints();
    authWellKnownEndpoints.issuer = envForSubdomain.authenticationUrl;
    authWellKnownEndpoints.jwks_uri =
      envForSubdomain.authenticationUrl + '/.well-known/openid-configuration/jwks';
    authWellKnownEndpoints.authorization_endpoint = envForSubdomain.authenticationUrl + '/connect/authorize';
    authWellKnownEndpoints.token_endpoint = envForSubdomain.authenticationUrl + '/connect/token';
    authWellKnownEndpoints.userinfo_endpoint = envForSubdomain.authenticationUrl + '/connect/userinfo';
    authWellKnownEndpoints.end_session_endpoint = envForSubdomain.authenticationUrl + '/connect/endsession';
    authWellKnownEndpoints.check_session_iframe =
      envForSubdomain.authenticationUrl + '/connect/checksession';
    authWellKnownEndpoints.revocation_endpoint = envForSubdomain.authenticationUrl + '/connect/revocation';
    authWellKnownEndpoints.introspection_endpoint =
      envForSubdomain.authenticationUrl + '/connect/introspect';

    this.oidcSecurityService.setupModule(
      openIDImplicitFlowConfiguration,
      authWellKnownEndpoints
    );

    // For usage with version ^10.0.15 of the angular-auth-oidc-client package
    /*const openIDImplicitFlowConfig = {
      stsServer: envForSubdomain.authenticationUrl,
      redirect_url: envForSubdomain.adminCenterUrl + '/auth/login',
      client_id: 'adminCenterSPA',
      response_type: 'id_token token',
      scope: 'openid profile email customer api1 roles IdentityServerApi',
      post_logout_redirect_uri: envForSubdomain.adminCenterUrl + '/auth/login',
      start_checksession: true,
      silent_renew: true,
      silent_renew_url: envForSubdomain.adminCenterUrl + '/silent-renew.html',
      post_login_route: envForSubdomain.adminCenterUrl,
      trigger_authorization_result_event: true,
      forbidden_route: '/Forbidden',
      unauthorized_route: '/Unauthorized',
      log_console_warning_active: true,
      log_console_debug_active: true,
      max_id_token_iat_offset_allowed_in_seconds: 1000
    }

    const authWellKnownEndpoints = {
      issuer: envForSubdomain.authenticationUrl,
      jwks_uri: envForSubdomain.authenticationUrl + '/.well-known/openid-configuration/jwks',
      authorization_endpoint: envForSubdomain.authenticationUrl + '/connect/authorize',
      token_endpoint: envForSubdomain.authenticationUrl + '/connect/token',
      userinfo_endpoint: envForSubdomain.authenticationUrl + '/connect/userinfo',
      end_session_endpoint: envForSubdomain.authenticationUrl + '/connect/endsession',
      check_session_iframe: envForSubdomain.authenticationUrl + '/connect/checksession',
      revocation_endpoint: envForSubdomain.authenticationUrl + '/connect/revocation',
      introspection_endpoint: envForSubdomain.authenticationUrl + '/connect/introspect'
    }

    this.oidcSecurityService.setupModule(
      openIDImplicitFlowConfig,
      authWellKnownEndpoints
    );*/

  }
}
