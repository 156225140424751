
export class ReLoginRedirect {
    static readonly type = '[OmniCloudAuth] reloginRedirect';
    constructor() { }
}

export class SetRequiredData {
    static readonly type = '[SessionAPI] setRequireData';
    constructor() { }
}

export class SetRequiredDataPermissions {
    static readonly type = '[SessionAPI] setRequireDataPermission';
    constructor() { }
}

export class FetchUserRoles {
    static readonly type = '[RoleAPI] fetchUserRoles';
    constructor(public userId: string) { }
}