import { Inject, Injectable } from '@angular/core';
import { RequestParamNamesHelper, SearchParam } from 'app/shared/helpers/api-requests.helper';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { catchError } from 'rxjs/operators';
import { Group, GroupFilterCondition, GroupMember, PrinterLocationIds } from 'app/shared/models/group.model';
import { DOCUMENT } from '@angular/common';
import { BaseService } from '../base.service';

const BASE_URL = 'groups';
const BASE_URL_MEMBERS = 'groups/groupmembers';
const BASE_URL_FILTERS = 'groups/groupfilters';
const BASE_URL_PRINTERFILTERS = 'groups/groupPrinterLocationFilters';

@Injectable({
  providedIn: 'root'
})
export class GroupService extends BaseService {

  paramHelper = new RequestParamNamesHelper();

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) {
    super(document);
  }

  getGroupTableDataConf() {
    return [
      {prop: 'name', name: 'NAME'},
      {prop: 'description', name: 'DESCRIPTION'},
      {prop: 'actions', name: 'ACTIONS'}
    ];
  }

  getGroupMembersTableDataConf() {
    return [
      /*{prop: 'firstName', name: 'FIRST_NAME'},
      {prop: 'lastName', name: 'LAST_NAME'},*/
      {prop: 'email', name: 'EMAIL'},
      {prop: 'actions', name: 'ACTIONS'}
    ];
  }

  getGroupFiltersTableDataConf() {
    return [
      {prop: 'field', name: 'FIELD'},
      {prop: 'operator', name: 'OPERATOR'},
      {prop: 'value', name: 'VALUE'},
      {prop: 'actions', name: 'ACTIONS'}
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Role>
  */
  getGroups(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g,'') : search.value));
    params = params
        .set(this.paramHelper.getSortParamName(), sort)
        .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
        .set(this.paramHelper.getPageParamName(), "" + page)
        .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /* It is a copy of GetGroups because groups does not have IsActive.
    However, for consistency and scalability, we proceed with getActiveGroups
  */ 
  getActiveGroups(): Observable<Group[]> {

    let params = new HttpParams();
    params = params
        .set(this.paramHelper.getSortParamName(), "Name")
        .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
        .set(this.paramHelper.getPageParamName(), "1")
        .set(this.paramHelper.getPerPageParamName(), "100");

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getGroupById(id: string): Observable<Group> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveGroup(groupInfo: Group) {
    
    let result: any;

    if (groupInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/' + groupInfo.id, groupInfo)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, groupInfo)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    }
    return result;   
  }

  deleteGroup(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* 
    This method will be consumed directly by MatTableDataSource
    So, all searches, sort, pagination are done in the client
  */
  getGroupMembers(groupId: string): Observable<GroupMember[]> {

    return this.http.get<GroupMember[]>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MEMBERS + '/groupid/' + groupId ).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  addUserToGroupMembers(groupId: string, userId: string) {
    let memberInfo = {
      groupId: groupId,
      userId: userId
    }
    
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MEMBERS , memberInfo)
              .pipe(
                catchError((error) => {
                    return throwError(error);
                })
              );
  }

  removeGroupMember(groupId: string, userId: string, recordId: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MEMBERS + '/' + recordId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* 
    This method will be consumed directly by MatTableDataSource
    So, all searches, sort, pagination are done in the client
  */
  getGroupFilters(groupId: string): Observable<GroupFilterCondition[]> {

    return this.http.get<GroupFilterCondition[]>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_FILTERS + '/groupid/' + groupId).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  saveFilterCondition(filterConditionInfo: GroupFilterCondition) {
    
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_FILTERS, filterConditionInfo)
              .pipe(
                catchError((error) => {
                    return throwError(error);
                })
              ) 
  }

  removeFilterCondition(filterId: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_FILTERS + '/' + filterId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  savePrinterLocationFilterCondition(param: PrinterLocationIds) {
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PRINTERFILTERS, param)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }

  removePrinterLocationFilterCondition(filterId: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PRINTERFILTERS + '/' + filterId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getPrinterLocationFilter(groupId: string) {
    return this.http.get<PrinterLocationIds>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PRINTERFILTERS + '/' + groupId)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }
}
