export class MessagePlaceholdersDB {

    public static placeholders = [
        {
            "key": "{CallbackUrl}",
            "required": false
        },
        {
            "key": "{LoginUrl}",
            "required": false
        }
    ]
}