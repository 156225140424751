export class CustomFieldsDependendOnFieldsDB {

    public static fields = [
        {
            "entity": "Person",
            "fields": [
                "personNumber",
                "addressPreference",
                "identificationType",
                "identificationTypeValue",
                "firstName",
                "middleName",
                "lastName",
                "birthDate",
                "gender",
                "prefix",
                "suffix",
                "phoneNumber",
                "mobileNumber",
                "status",
                "profilePreference",
                "createdDateUtc",
                "origin"
            ]
        },
        {
            "entity": "Address",
            "fields": [
                "type",
                "streetAddress",
                "locality",
                "region",
                "postalCode",
                "country"
            ]
        },
        {
            "entity": "Cardholder",
            "fields": [
                "email",
                "businessUnit",
                "externalCompany",
                "department",
                "costAccount",
                "location",
                "jobTitle",
                "startDate",
                "endDate",
                "status",
                "nameOnCard",
                "note",
                "created",
                "profileNumber"
            ]
        },
        {
            "entity": "Custom",
            "fields": [
            ]
        }
    ]

    public static personFields = [
        {
            "entity": "Person",
            "fields": [
                "PersonNumber",
                "FirstName",
                "MiddleName",
                "LastName",
                "BirthDate",
                "Gender",
                "Prefix",
                "Suffix",
                "PhoneNumber",
                "MobileNumber",
                "ProfilePreference",
                "FreeField1",
                "FreeField2",
                "FreeField3",
                "FreeField4",
                "FreeField5",
                "FreeField6",
                "FreeField7",
                "FreeField8",
                "FreeField9",
                "FreeField10",
                "FreeField11",
                "FreeField12",
                "FreeField13",
                "FreeField14",
                "FreeField15",
                "FreeField16"
            ]
        },
        {
            "entity": "Cardholder",
            "fields": [
                "Email",
                "ExternalCompany",
                "Department",
                "CostAccount",
                "Location",
                "JobTitle",
                "StartDate",
                "EndDate",
                "Status",
                "NameOnCard",
                "Note",
                "Created",
                "ProfileNumber",
                "ProfileName",
                "FreeField1",
                "FreeField2"
            ]
        }
    ]
    
}