import {Injectable} from '@angular/core';
import { environment } from 'environments/environment';
import {LocalStoreService } from './local-store.service';

@Injectable()
export class BaseService {

    public environmentForDomain;

    constructor(document: Document) {
        let ls = new LocalStoreService();
        let apiUrl = ls.getItem("api_url");
        if(apiUrl != null )
            this.environmentForDomain = this.subDomains(apiUrl)[0];
        else
            this.environmentForDomain = environment.subdomains.find(elem => elem.subdomain == document.location.hostname);
    }

    subDomains(apiUrl){
        return [
            {
                webApiBaseUrl: apiUrl + 'api/v1/',
                webApi2BaseUrl: apiUrl + 'api/v2/',
                webApi2BaseAdmincenterUrl: apiUrl + 'api/v2/admincenter/',
                mockWebApiBaseUrl: 'https://cardexchangecloudmockapi.azurewebsites.net/',
                adminCenterUrl: 'https://cardexchangecloudadmindev.azurewebsites.net'

            }
        ]
    }
}