export class CredentialColumnsDB {
    public static columns = [
        {
            "code": "PacsFacilityCode",
            "langKey": "PACS_FACILITY_CODE"
        },
        {
            "code": "PacsCardNumber",
            "langKey": "PACS_CARD_NUMBER"
        }
    ];
}