export class BusinessRulesFieldModifiersDB {
    public static fieldModifiers = [
        {
            "code": "upc",
            "langKey": "UPCASE"
        },
        {
            "code": "lwc",
            "langKey": "LOWCASE"
        },
        {
            "code": "ltr",
            "langKey": "LEFT_TRIM"
        },
        {
            "code": "rtr",
            "langKey": "RIGHT_TRIM"
        },
        {
            "code": "tr",
            "langKey": "TRIM"
        },
        {
            "code": "len",
            "langKey": "LENGTH"
        },
        {
            "code": "hex",
            "langKey": "HEX"
        },
        {
            "code": "dat",
            "langKey": "DAYSAFTERTODAY"
        },
        {
            "code": "dbt",
            "langKey": "DAYSBEFORETODAY"
        }

    ]
}