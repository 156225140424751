import { Component, Input } from '@angular/core';
import { StandTemplate } from 'app/shared/models/setup.stand.model';
import { SettingsService } from 'app/shared/services/settings/settings.service';

@Component({
  selector: 'app-stand-templates',
  templateUrl: './stand-templates.component.html',
  styleUrls: ['./stand-templates.component.scss']
})
export class StandTemplatesComponent {
  customerLogo = "";
  date: string;
  @Input() standTemplate: StandTemplate;

  constructor(private settingsService: SettingsService) {
  }

  ngOnInit(): void {
    this.loadCustomerLogo();
    const currentDate = new Date();
    this.date = currentDate.toLocaleTimeString();
  }

  loadCustomerLogo() {
    this.settingsService.getCustomerLogo()
      .subscribe(resp => {
        if (resp.logoUrl) {
          this.customerLogo = resp.logoUrl;
        } else {
          this.customerLogo = "assets/images/logos/ce_logo.png";
        }
      },
      error => {
        this.customerLogo = "assets/images/logos/ce_logo.png";
    });
  }

  getPropertyName(name: string) {
    return name.substring(name.indexOf(".") + 1);
  }
}
