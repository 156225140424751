export class CredentialStatusesDB {
    public static credentialStatuses = [
        {
            "label": "Initialized",
            "value": "initialized"
        },
        {
            "label": "In Production",
            "value": "in_production"
        },
        {
            "label": "Printed",
            "value": "printed"
        },
        {
            "label": "Approved",
            "value": "approved"
        },
        {
            "label": "Withdrawn",
            "value": "withdrawn"
        },
        {
            "label": "Activated",
            "value": "activated"
        },
        {
            "label": "Blocked",
            "value": "blocked"
        },
        {
            "label": "Expired",
            "value": "expired"
        }
    ]
}