import { Component, Input, OnInit, ChangeDetectorRef, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Select } from '@ngxs/store';
import { AuthenticationState } from 'app/auth/authentication-store/authentication.state';
import { IMetadata } from 'app/interfaces/omni-cloud/user-metadata.interface';
import { Observable } from 'rxjs';
import { Form } from 'app/shared/models/setup.controllercms.model';
import { map, tap } from 'rxjs/operators';
import { ConditionsDialogComponent } from './conditions-dialog/conditions-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomFieldLabels } from 'app/shared/models/setup.controllercms.model';
import { Condition } from 'app/interfaces/omni-cloud/system-label.interface';
import { ConfirmationDialogComponent, IConfirmDialogContent } from '../confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'extra-panel-form',
  templateUrl: './extra-panel-form.component.html',
  styleUrls: ['./extra-panel-form.component.scss']
})
export class ExtraPanelFormComponent implements OnInit {
  @Select(AuthenticationState.omnicloudmetadata) userMetadata$: Observable<Array<IMetadata>>;
  
  @Input() title: string = 'Extra Panel Form';
  @Input() showConditions = false;
  @Input() extraPanelFormInstance: FormGroup;
  @Input() formProperty: string = '';
  @Input() isExtraPanel: boolean = false;
  @Input() formKeys: Array<Form> = [];
  @Input() metadataColumns: Array<string> = [];
  @Input() entity: string = '';
  @Input() extraPanelProperty = '';
  @Input() formData: CustomFieldLabels;
  @Input() dataFormat: string = '';

  attributeHelper: Array<IMetadata> = [];
  displayKeys$: Observable<Array<Form>>;
  extraPanelForm: FormGroup;
  displayedColumns = ['AttributeId', 'Value', 'Prefix', 'Actions'];
  conditionsTableTitle = "Conditions";
  newConditions: Array<Condition>;
  keys: any;
  retData: any;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private changeDetectorRefs: ChangeDetectorRef,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.newConditions = this.formData.conditions;
    this.displayKeys$ = this.userMetadata$.pipe(
      map(this.getDisplayKeysObs),
      tap(keys => {
        this.keys = keys;
        this.extraPanelForm = this.setupForm(keys);
      })
    )
  }

  setupForm(formKeys: Array<Form>): FormGroup {
    const formGroup: FormGroup = this.formBuilder.group({});
    const id = this.formBuilder.control('');
    formGroup.addControl('id', id);
    formKeys
      .forEach(key => {
        const control = this.formBuilder.control('');
        if (!key.allowNulls) {
          control.setValidators(Validators.required);
        }
        formGroup.addControl(key.controlName, control);
        formGroup.get(key.controlName).setValue(this.formData[this.uncapitalize(key.controlName)]);
      });
    return formGroup;
  }

  getFormKeysForColumns(keys: Array<Form>, colNumber: number): Array<Form> {
    const halfCount = Math.round(keys.length / 2);
    return colNumber === 1 ?
      keys.slice(0, halfCount) : keys.slice(halfCount);
  }

  getFromControl(controlName: string): AbstractControl {
    return this.extraPanelForm.get(controlName);
  }

  getDisplayKeysObs = (userMetadata: Array<IMetadata>): Array<Form> => {
    if (this.formKeys.length) {
      return this.formKeys;
    }

    let metadata = userMetadata;
    this.attributeHelper = userMetadata;
    if (this.metadataColumns.length > 0) {
      metadata = userMetadata.filter(meta => this.metadataColumns.findIndex(column => column === meta.attribute.name) > -1);
    }
    if (this.isExtraPanel) {
      return metadata.filter(meta =>
        meta.attribute.entity === this.entity && meta.attribute.extraPanel &&
        meta.attribute.extraPanelProperty === this.extraPanelProperty).map(key => {
          const reqObj: Form = {
            controlName: key.attribute.name,
            label: key.attribute.translation,
            type: key.attribute.dataFormat,
            allowNulls: key.attribute.allowNulls,
            linkedKey: key.attribute.linkedKey,
            attributeID: key.attribute.id,
            linkedEntity: key.attribute.linkedEntity
          };
          return reqObj;
        })
        .sort(x => x.linkedKey ? 0 : -1)
        .sort(x => x.type === 'date' ? 0 : -1)
        .sort(x => x.type === 'boolean' ? 0 : -1);
    } else {
      return metadata.filter(meta =>
        meta.attribute.entity === this.entity && meta.attribute.listable).map(key => {
          const reqObj: Form = {
            controlName: key.attribute.name,
            label: key.attribute.translation,
            type: key.attribute.dataFormat,
            allowNulls: key.attribute.allowNulls,
            linkedKey: key.attribute.linkedKey,
            attributeID: key.attribute.id,
            linkedEntity: key.attribute.linkedEntity
          };
          return reqObj;
        })
        .sort(x => x.linkedKey ? 0 : -1)
        .sort(x => x.type === 'date' ? 0 : -1).
        sort(x => x.type === 'boolean' ? 0 : -1);
    }
  }

  setupAddForm() {
    let conditionsData: Condition = {
      systemLabelId: this.formData.id,
      prefix: null,
      value: null,
      attributeId: null,
      id: null
    }

    this.openConditionsForm(conditionsData, 0);
  }

  setupEditForm(index) {
    let condition = this.newConditions[index];

    let conditionsData: Condition = {
      systemLabelId: condition.systemLabelId,
      prefix: condition.prefix,
      value: condition.value,
      attributeId: condition.attributeId,
      id: condition.id
    }

    this.openConditionsForm(conditionsData, index);
  }

  openConditionsForm(conditionsData: Condition, index: number): void {
    const dialogRef = this.dialog.open(ConditionsDialogComponent, {
      data: conditionsData,
      minWidth: '40vw'
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        let condition: Condition = res.data;

        if (res.action == "Add") {
          let newData = [ ...this.newConditions ];
          newData.push(condition);
          this.newConditions = newData;
        } else if (res.action == "Edit") {
          this.newConditions[index] = condition;
          let newData = [ ...this.newConditions ];
          this.newConditions = newData;
        }

        this.changeDetectorRefs.detectChanges();
      }
    });
  }

  deleteCondition(condition: Condition, index: number) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: {
          title: this.translate.instant("SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.CONFIRM_DELETE_TITLE"),
          message: this.translate.instant("SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.CONFIRM_DELETE_MESSAGE", {value: condition.value})
      } as IConfirmDialogContent
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.newConditions.splice(index, 1);
        let newData = [ ...this.newConditions ];
        this.newConditions = newData;
      }
    });
  }

  uncapitalize(str: string): string {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  hiddenForm(control: string): boolean {
    let hide = false;
    
    if (this.dataFormat == "random") {
      hide = (control.toLowerCase() == "incrementalvalue" || control.toLowerCase() == "startvalue") ? true : false;
    }

    return hide;
  }
}
