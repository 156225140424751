export class FargoFromPrintEntityAttributesDB {

    public static entityAttributes = [
        {
            "entity": "Generic",
            "fields": [
                "cardSerialNumber"
            ]
        },
        {
            "entity": "Person",
            "fields": [
                "personNumber",
                "addressPreference",
                "identificationType",
                "identificationTypeValue",
                "firstName",
                "middleName",
                "lastName",
                "birthDate",
                "gender",
                "prefix",
                "suffix",
                "phoneNumber",
                "mobileNumber",
                "status",
                "profilePreference",
                "createdDateUtc",
                "origin"
            ]
        },
        {
            "entity": "Address",
            "fields": [
                "type",
                "streetAddress",
                "locality",
                "region",
                "postalCode",
                "country"
            ]
        },
        {
            "entity": "Cardholder",
            "fields": [
                "email",
                "businessUnit",
                "externalCompany",
                "department",
                "costAccount",
                "location",
                "jobTitle",
                "startDate",
                "endDate",
                "status",
                "nameOnCard",
                "note",
                "created",
                "profileNumber"
            ]
        }
    ]
}