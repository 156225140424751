import { Routes } from "@angular/router";
import { AdminLayoutComponent } from "./shared/components/layouts/admin-layout/admin-layout.component";
//import { AuthLayoutComponent } from "./shared/components/layouts/auth-layout/auth-layout.component";
import { AuthGuard } from "./shared/guards/auth.guard";

export const rootRouterConfig: Routes = [
  {
    path: "",
    redirectTo: "/dashboard/analytics",
    pathMatch: "full",
  },
  {
      path: 'auth',
      loadChildren: () =>
        import("./auth/authentication.module").then(
          (m) => m.AuthenticationModule
        ),
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "dashboard",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then(
            (m) => m.DashboardModule
          )
      },
      /*{
        path: "search",
        canActivate: [UserRoleGuard],
        loadChildren: () =>
          import("./views/search-view/search-view.module").then(
            (m) => m.SearchViewModule
          )
      },*/      
      {
        path: "users",
        loadChildren: () =>
          import("./pages/users/user.module").then((m) => m.UserModule),
        data: { title: "USERS", breadcrumb: "USERS" }

      },
      {
        path: "groups",
        loadChildren: () =>
          import("./pages/groups/group.module").then((m) => m.GroupModule),
        data: { title: "GROUPS", breadcrumb: "GROUPS" }
      },
      {
        path: "roles",
        loadChildren: () =>
          import("./pages/roles/role.module").then((m) => m.RoleModule),
        data: { title: "ROLES", breadcrumb: "ROLES" }
      },
      {
        path: "resources",
        loadChildren: () =>
          import("./pages/resources/resources.module").then((m) => m.ResourcesModule),
        data: { title: "RESOURCES", breadcrumb: "RESOURCES" }
      },
      {
        path: "settings",
        loadChildren: () =>
          import("./pages/settings/settings.module").then((m) => m.SettingsModule),
        data: { title: "SETTINGS_MAIN", breadcrumb: "SETTINGS_MAIN" }
      },
      {
        path: "setup",
        loadChildren: () =>
          import("./pages/setup/setup.module").then((m) => m.SetupModule),
        data: { title: "SETUP_MAIN", breadcrumb: "SETUP_MAIN" }
      },
      {
        path: "processes",
        loadChildren: () =>
          import("./pages/processes/processes.module").then((m) => m.ProcessesModule),
        data: { title: "PROCESSES_MAIN", breadcrumb: "PROCESSES_MAIN" }
      },
      {
        path: "addins",
        loadChildren: () =>
          import("./pages/add-ins/add-ins.module").then((m) => m.AddInsModule),
        data: { title: "ADD_INS_MAIN", breadcrumb: "ADD_INS_MAIN" }
      },
      /*{
        path: "forms",
        loadChildren: () =>
          import("./views/forms/forms.module").then((m) => m.AppFormsModule),
        data: { title: "Forms", breadcrumb: "Forms" },      
      },*/
      {
        path: "archive",
        loadChildren: () =>
          import("./pages/storagemgmt/storagemgmt.module").then((m) => m.StoragemgmtModule),
        data: { title: "DATA_ARCHIVE", breadcrumb: "DATA_ARCHIVE" }
      },
      {
        path: "ce_cloud_notifications",
        loadChildren: () =>
          import("./pages/notifications/ce-cloud-notifications.module").then((m) => m.CeCloudNotificationsModule),
        data: { title: "CE_CLOUD_NOTIFICATIONS_MAIN", breadcrumb: "CE_CLOUD_NOTIFICATIONS_MAIN" }
      }
    ],
  },
  {
    path: "**",
    redirectTo: "sessions/404",
  },
];
