<div id="sideBarForm" class="min-width-800">
    <mat-card class="p-0">

        <div class="spinner-container" *ngIf="!attributesList || !systemLabelList">
            <mat-spinner></mat-spinner>
        </div>
        <ng-container *ngIf="attributesList && systemLabelList">
            <mat-card-title class="">
                <div class="card-title-text"> {{ "STORAGE_MGMT.FILTER_PERSONS.TITLE" | translate }}</div>
                <mat-divider></mat-divider>
            </mat-card-title>
            <mat-card-content>
                <form role="form" [formGroup]="filterForm" (ngSubmit)="onAddNewFilterCondition(metaDataList)">
                    <div class="add-filter-wrapper">
                        <h4 class="text-left">{{ "STORAGE_MGMT.FILTER_PERSONS.ADD_FILTER" | translate }}</h4>
                        <mat-form-field class="mr-1">
                            <mat-label>{{ "STORAGE_MGMT.FILTER_PERSONS.FIELD" | translate }}</mat-label>
                            <mat-select attr.aria-label="{{ 'STORAGE_MGMT.FILTER_PERSONS.FIELD' | translate }}"
                                formControlName="filterField">
                                <mat-option [value]="''">{{ 'STORAGE_MGMT.FILTER_PERSONS.NO_FIELD' | translate }}</mat-option>
                                <mat-optgroup *ngFor="let entity of attributesList" [label]="entityUIPhrase[entity.entity]">
                                    <mat-option *ngFor="let attr of entity.attributes"
                                        [value]="attr.attribute.name">
                                        {{getLabel(attr)}}
                                    </mat-option>
                                </mat-optgroup>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mr-1">
                            <mat-label>{{ "STORAGE_MGMT.FILTER_PERSONS.OPERATOR" | translate }}</mat-label>
                            <mat-select attr.aria-label="{{ 'STORAGE_MGMT.FILTER_PERSONS.OPERATOR' | translate }}"
                                formControlName="filterOperator">
                                <mat-option [value]="''">{{ 'STORAGE_MGMT.FILTER_PERSONS.NO_OPERATOR' | translate }}</mat-option>
                                <mat-option [value]="operator.code" *ngFor="let operator of operators"
                                    attr.aria-label="{{ 'STORAGE_MGMT.FILTER_PERSONS.OPERATORS.' + operator.langKey | translate }}">
                                    {{ 'STORAGE_MGMT.FILTER_PERSONS.OPERATORS.' + operator.langKey | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mr-1">
                            <input matInput formControlName="filterValue" name="filterValue"
                                placeholder="{{ 'STORAGE_MGMT.FILTER_PERSONS.VALUE' | translate }}">
                        </mat-form-field>
                        <button mat-icon-button [disabled]="filterForm.invalid || loading"
                            matTooltip="{{ 'STORAGE_MGMT.FILTER_PERSONS.ADD' | translate }}"
                            attr.aria-label="{{ 'STORAGE_MGMT.FILTER_PERSONS.ADD' | translate }}" tabindex="0">
                            <mat-icon>add</mat-icon>
                        </button>
                        <button mat-icon-button [disabled]="!filterForm.touched"
                            (click)="clearNewFilterInputs(); $event.preventDefault()"
                            matTooltip="{{ 'STORAGE_MGMT.FILTER_PERSONS.CLEAR' | translate }}"
                            attr.aria-label="{{ 'STORAGE_MGMT.FILTER_PERSONS.CLEAR' | translate }}" tabindex="0">
                            <mat-icon>clear</mat-icon>
                        </button>
                    </div>
                </form>

                <div class="mb-5 fixed-height-560">
                    <mat-table [dataSource]="filterConditionList" matSort matSortActive="id" matSortDirection="desc"
                        matSortDisableClear>

                    
                        <ng-container matColumnDef="field">
                            <mat-header-cell *matHeaderCellDef mat-sort-header tabindex="-1"> {{ "STORAGE_MGMT.FILTER_PERSONS.FIELD" |
                                translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let row" data-label="field"> {{row.field}} </mat-cell>
                        </ng-container>
                        

                        <ng-container matColumnDef="operator">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="th-center" tabindex="-1"> {{
                                "STORAGE_MGMT.FILTER_PERSONS.OPERATOR" | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let row" class="th-center" data-label="operator"> {{row.operator}}
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="value">
                            <mat-header-cell *matHeaderCellDef mat-sort-header class="th-center" tabindex="-1"> {{
                                "STORAGE_MGMT.FILTER_PERSONS.VALUE" | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let row" class="th-center" data-label="value"> {{row.values}} </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <mat-header-cell *matHeaderCellDef class="th-center" tabindex="-1"> {{ "STORAGE_MGMT.FILTER_PERSONS.ACTIONS"
                                | translate }} </mat-header-cell>
                            <mat-cell *matCellDef="let row" class="th-center" data-label="actions">
                                <button mat-raised-button color="warn" [disabled]="loading" (click)="removeFilter(row)"
                                    matTooltip="{{ 'STORAGE_MGMT.FILTER_PERSONS.REMOVE' | translate:{'value':row.field} }}"
                                    attr.aria-label="{{ 'STORAGE_MGMT.FILTER_PERSONS.REMOVE' | translate:{'value':row.field} }}"
                                    tabindex="0">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedFilterColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedFilterColumns;">
                        </mat-row>
                    </mat-table>
                </div>
            </mat-card-content>
            <mat-card-footer>
                <mat-card-actions class="form-ctrl-wrapper">
                    <button mat-raised-button (click)="save(); $event.preventDefault()"> {{ 'FORM_CTRL.SAVE' |
                        translate }} </button>
                    <button mat-raised-button (click)="cancel(); $event.preventDefault()"> {{ 'FORM_CTRL.CANCEL' |
                        translate }} </button>
                </mat-card-actions>
            </mat-card-footer>
        </ng-container>
    </mat-card>
</div>