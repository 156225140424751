export class BusinessRulesConditionFieldsDB {

    public static conditionFields = [
        {
            "entity": "Person",
            "fields": [
                {
                    "field": "PersonNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "PhoneNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "MobileNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Gender",
                    "type": "select",
                    "target": "GENDER"
                },
                {
                    "field": "PhotoVersion",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Status",
                    "type": "select",
                    "target": "PERSON_STATUS"
                },
                {
                    "field": "Archive",
                    "type": "select",
                    "target": "PERSON_ARCHIVE"
                },
                {
                    "field": "BlockReason",
                    "type": "select",
                    "target": "PERSON_BLOCK_REASON"
                }
            ]
        },
        {
            "entity": "Photo",
            "fields": [
                {
                    "field": "Status",
                    "type": "select",
                    "target": "PHOTO_STATUS"
                },
                {
                    "field": "CompareHistoricMatch",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "CompareIdMatch",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "RejectReason",
                    "type": "select",
                    "target": "PHOTO_REJECT_REASON"
                }
            ]
        },
        {
            "entity": "Address",
            "fields": [
                {
                    "field": "Type",
                    "type": "select",
                    "target": "ADDRESS_TYPE"
                },
                {
                    "field": "StreetAddress",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Locality",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Region",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "PostalCode",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Country",
                    "type": "text",
                    "target": null
                },

            ]
        },
        {
            "entity": "Credential",
            "fields": [
                {
                    "field": "CardNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "ChipSerialNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "PacsCardNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "PacsFacilityCode",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Status",
                    "type": "select",
                    "target": "CARD_STATUS"
                },
                {
                    "field": "BlockReason",
                    "type": "select",
                    "target": "CARD_BLOCK_REASON"
                },
                {
                    "field": "EndDateUtc",
                    "type": "number",
                    "target": "END_DATE"
                },
                {
                    "field": "Type",
                    "type": "select",
                    "target": "CARD_TYPE"
                }
            ]
        },
        {
            "entity": "Profile",
            "fields": [
                {
                    "field": "Email",
                    "type": "email",
                    "target": null
                },
                {
                    "field": "ProfileNumber",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Note",
                    "type": "text",
                    "target": null
                },
                {
                    "field": "Location",
                    "type": "select",
                    "target": "PROFILE_LOCATION"
                },
                {
                    "field": "ExternalCompany",
                    "type": "select",
                    "target": "PROFILE_EXTERNAL_COMPANY"
                },
                {
                    "field": "Department",
                    "type": "select",
                    "target": "PROFILE_DEPARTMENT"
                },
                {
                    "field": "Name",
                    "type": "select",
                    "target": "PROFILE_NAME"
                },
                {
                    "field": "EndDateUtc",
                    "type": "number",
                    "target": "PROFILE_ENDDATE"
                }
            ]
        }
    ]
}