export class ArchivePersonsFilterFieldsDB {

    public static archivePersonsFilterFields = [
        {
            "entity": "Persons",
            "fields": [
                "Persons.PersonNumber",
                "Persons.AddressPreference",
                "Persons.IdentificationType",
                "Persons.IdentificationTypeValue",
                "Persons.FirstName",
                "Persons.MiddleName",
                "Persons.LastName",
                "Persons.BirthDate",
                "Persons.Gender",
                "Persons.Prefix",
                "Persons.Suffix",
                "Persons.PhoneNumber",
                "Persons.MobileNumber",
                "Persons.Status",
                "Persons.ProfilePreference",
                "Persons.CreatedDateUtc",
                "Persons.Origin",
                "Persons.FreeField1",
                "Persons.FreeField2",
                "Persons.FreeField3",
                "Persons.FreeField4",
                "Persons.FreeField5",
                "Persons.FreeField6",
                "Persons.FreeField7",
                "Persons.FreeField8",
                "Persons.FreeField9",
                "Persons.FreeField10",
                "Persons.FreeField11",
                "Persons.FreeField12",
                "Persons.FreeField13",
                "Persons.FreeField14",
                "Persons.FreeField15",
                "Persons.FreeField16" 
            ]
        },
        {
            "entity": "Cardholders",
            "fields": [
                "Cardholders.Profile",
                "Cardholders.Email",
                "Cardholders.Location",
                "Cardholders.JobTitle",
                "Cardholders.StartDateUtc",
                "Cardholders.EndDateUtc",
                "Cardholders.Status",
                "Cardholders.NameOnCard",
                "Cardholders.Note",
                "Cardholders.CreatedDateUtc",
                "Cardholders.ProfileNumber",
                "Cardholders.FreeField1",
                "Cardholders.FreeField2"
            ]
        },
        {
            "entity": "Cards",
            "fields": [
                "Cards.CreatedDateUtc",
                "Cards.ModifiedDateUtc",
                "Cards.CardNumber",
                "Cards.ChipserialNumber",
                "Cards.Status",
                "Cards.StartDateUtc",
                "Cards.EndDateUtc",
                "Cards.ActivatedOnUtc"
            ]
        }
    ]
}