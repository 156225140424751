<div class="sidebar-panel">
  <div
    id="scroll-area"
    [perfectScrollbar]
    class="navigation-hold"
    fxLayout="column"
  >
    <div class="sidebar-hold">

      <div class="sidebar-collapsed"
        *ngIf="layoutConf.sidebarStyle === 'compact'">
        <button
          mat-icon-button
          id="sidenavToggle"
          fxHide.lt-md
          (click)="toggleCollapse()"
          matTooltip="Expand/Collapse Menu"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </div>

      <div class="sidebar-expanded"
        *ngIf="layoutConf.sidebarStyle !== 'compact'">
        <button
          mat-icon-button
          id="sidenavToggle"
          fxHide.lt-md
          (click)="toggleCollapse()"
          matTooltip="Expand/Collapse Menu"
        >
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      
      <!-- Navigation -->
      <app-sidenav
        role="navigation"
        aria-label="Main Navigation"
        [items]="menuItems"
        [hasIconMenu]="hasIconTypeMenuItem"
        [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
    </div>
  </div>
</div>
