export class ArchivePersonsFilterOperatorsDB {

    public static archivePersonsFilterOperators = [
        {
            "code": "Equal",
            "langKey": "EQUALS"
        },
        {
            "code": "NotEqual",
            "langKey": "NOT_EQ"
        },
        {
            "code": "LessThan",
            "langKey": "LESS_THAN"
        },
        {
            "code": "GreaterThan",
            "langKey": "GREATER_THAN"
        },
        {
            "code": "LessThanOrEqual",
            "langKey": "LESS_OR_EQ"
        },
        {
            "code": "GreaterThanOrEqual",
            "langKey": "GREATER_OR_EQ"
        },
        {
            "code": "Contains",
            "langKey": "CONTAINS"
        }
        // {
        //     "code": "ncontains",
        //     "langKey": "NOT_CONTAIN"
        // },
        // {
        //     "code": "startsw",
        //     "langKey": "STARTS_WITH"
        // }
    ]
}