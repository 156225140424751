export class PersonArchiveStatus{
    public static status = [
        {
            "code": "True",
            "langKey": "Archive"
        },
        {
            "code": "False",
            "langKey": "Restored"
        }
    ]
}