export class GendersDB {

    public static genders = [
        {
            "code": "m",
            "langKey": "M"
        },
        {
            "code": "f",
            "langKey": "F"
        }
    ]
}