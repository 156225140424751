export class PhotoStatusDb {
    public static status = [
        {
            "code": "Pending",
            "langKey": "Pending"
        },
        {
            "code": "Approved",
            "langKey": "Approved"
        },
        {
            "code": "Rejected",
            "langKey": "Rejected"
        },
        {
            "code": "UnderReview",
            "langKey": "Under Review"
        },
        {
            "code": "Release",
            "langKey": "Release"
        },
        {
            "code": "",
            "langKey": "None"
        }
    ]
}