export class PersonBlockReasonDb {
    public static reason = [
        {
            "code": "PersonBlocked",
            "langKey": "Person Blocked"
        },
        {
            "code": "Departed",
            "langKey": "Departed"
        },
        {
            "code": "Terminated",
            "langKey": "Terminated"
        },
        {
            "code": "Archived",
            "langKey": "Archived"
        }
    ]
}