import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

interface IStatusCode {
  status: number;
}

@Component({
  selector: 'app-error-snackbar',
  templateUrl: './error-snackbar.component.html',
  styleUrls: ['./error-snackbar.component.scss']
})
export class ErrorSnackbarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: IStatusCode,
    public snackBar: MatSnackBarRef<ErrorSnackbarComponent>,
    private translate: TranslateService) { }

  onClose() {
    this.snackBar.dismiss();
  }

}
