export class AnalyticsBaseDB {

    public static userStats = [
        {
        icon: "people",
        code: "total",
        title: "TOTAL",
        amount: ""
        },
        {
        icon: "check_circle",
        code: "confirmed",
        title: "CONFIRMED",
        amount: ""
        },
        {
        icon: "person",
        code: "active",
        title: "ACTIVE",
        amount: ""
        }
    ];
    
    public static enrollmentStats = [
        {
        value: 0,
        key: "readyToCreate",
        name: "Ready To Create"
        },
        {
        value: 0,
        key: "approvedForProduction",
        name: "Approved For Production"
        },
        { 
        value: 0, 
        key: "toProduce",
        name: "To Produce"
        },
        { 
        value: 0, 
        key: "approveIssuance",
        name: "Approve Issuance"
        },
        { 
        value: 0, 
        key: "toBeActivated",
        name: "To Be Activated"
        }
    ];
    
    public static personStats = [
        {
        value: 0,
        key: "active",
        name: "Active"
        },
        {
        value: 0,
        key: "blocked",
        name: "Blocked"
        },
        { 
        value: 0, 
        key: "readyToArchive",
        name: "Ready To Archive"
        },
        { 
        value: 0, 
        key: "none",
        name: "None"
        },
        { 
        value: 0, 
        key: "expired",
        name: "Expired"
        },
        { 
        value: 0, 
        key: "blackListed",
        name: "Blacklisted"
        },
        { 
        value: 0, 
        key: "linked",
        name: "Linked"
        },
        { 
        value: 0, 
        key: "unlinked",
        name: "Unlinked" 
        }
    ];

    public static productList = [
        {
            key: "Controller CMS",
            name: "Controller"
        },
        {
            key: "The Stand",
            name: "Stand"
        },
        {
            key: "The Press",
            name: "Press"
        },
        {
            key: "The API",
            name: "Bridge"
        }
    ];
}