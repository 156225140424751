export class BusinessRulesConditionOperatorsDB {

    public static conditionOperators = [
        {
            "code": "eq",
            "langKey": "EQUALS"
        },
        {
            "code": "like",
            "langKey": "LIKE"
        },
        {
            "code": "nlike",
            "langKey": "NOT_LIKE"
        },
        {
            "code": "gt",
            "langKey": "GREATER_THAN"
        },
        {
            "code": "geq",
            "langKey": "GREATER_OR_EQ"
        },
        {
            "code": "leq",
            "langKey": "LESS_OR_EQ"
        },
        {
            "code": "lt",
            "langKey": "LESS_THAN"
        },
        {
            "code": "nlt",
            "langKey": "NOT_LESS_THAN"
        },
        {
            "code": "neq",
            "langKey": "NOT_EQ"
        },
        {
            "code": "ngt",
            "langKey": "NOT_GREATER_THAN"
        }
    ]
}