<div id="refusalDialog">
    <mat-card class="p-0">
        <mat-card-title class="">
            <div class="card-title-text"> {{data.title}} </div>
            <mat-divider></mat-divider>
        </mat-card-title>
        <mat-card-content>
          {{data.message}}
        </mat-card-content>
        <mat-card-footer>
          <mat-card-actions class="yes-no-wrapper">
            <button mat-raised-button (click)="onOkClick()" cdkFocusInitial>  {{ 'FORM_CTRL.CLOSE' | translate }} </button>
          </mat-card-actions>
        </mat-card-footer>
    </mat-card>
  </div>