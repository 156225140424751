export class CustomCredentialOptionDB {
    public static options = [
        {
            label: 'PERSON_NUMBER',
            value: 'PersonNumber'
        },
        {
            label: 'PERSON_ISSUENUMBER',
            value: 'CurrentCredentialIssueNumber'
        },
        {
            label: 'CARD_NUMBER',
            value: 'CardNumber'
        },
        {
            label: 'FREEFIELD1',
            value: 'FreeField1'
        },
        {
            label: 'FREEFIELD2',
            value: 'FreeField2'
        },
        {
            label: 'FREEFIELD3',
            value: 'FreeField3'
        },
        {
            label: 'FREEFIELD4',
            value: 'FreeField4'
        },
        {
            label: 'FREEFIELD5',
            value: 'FreeField5'
        },
        {
            label: 'FREEFIELD6',
            value: 'FreeField6'
        },
        {
            label: 'FREEFIELD7',
            value: 'FreeField7'
        },
        {
            label: 'FREEFIELD8',
            value: 'FreeField8'
        },
        {
            label: 'FREEFIELD9',
            value: 'FreeField9'
        },
        {
            label: 'FREEFIELD10',
            value: 'FreeField10'
        },
        {
            label: 'FREEFIELD11',
            value: 'FreeField11'
        },
        {
            label: 'FREEFIELD12',
            value: 'FreeField12'
        },
        {
            label: 'FREEFIELD13',
            value: 'FreeField13'
        },
        {
            label: 'FREEFIELD14',
            value: 'FreeField14'
        },
        {
            label: 'FREEFIELD15',
            value: 'FreeField15'
        }
    ]
}