export class StandBarcodeTypesDB {

    public static barcodeTypes = [
        {
            "code": "qrcode",
            "langKey": "QRCODE"
        },
        {
            "code": "code39",
            "langKey": "CODE39"
        },
        {
            "code": "code128",
            "langKey": "CODE128"
        },
        {
            "code": "codabar",
            "langKey": "CODABAR"
        }
    ]
}