import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IPasswordChangeDialogReturnInfo {
  oldPassword: string;
  newPassword: string;
}

@Component({
  selector: 'app-password-change-dialog',
  templateUrl: './password-change-dialog.component.html',
  styleUrls: ['./password-change-dialog.component.scss']
})
export class PasswordChangeDialogComponent {

  passwordForm: FormGroup;
  returnInfo: IPasswordChangeDialogReturnInfo = {oldPassword: '', newPassword: ''};
  constructor(
    public dialogRef: MatDialogRef<PasswordChangeDialogComponent>) { 
      this.setupForm();
  }

  setupForm(): void {
    this.passwordForm = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [
        this.patternValidator(/\d/, {
          hasNumber: true
        }),
        this.patternValidator(/[A-Z]/, {
          hasUpcaseLetter: true
        }),
        this.patternValidator(/[a-z]/, {
          hasLowcaseLetter: true
        }),
        this.patternValidator(/[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
        {
          hasSpecialCharacters: true
        }),
        Validators.minLength(8),
        Validators.required
      ]),
      confirmNewPassword: new FormControl('')
    }, this.passwordMatchValidator);
  }

  passwordMatchValidator(g: FormGroup) {
    return g.get('newPassword').value === g.get('confirmNewPassword').value
       ? null : {'mismatch': true};
  }

  patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  onSaveClick(): void {
    this.returnInfo.oldPassword = this.passwordForm.value.oldPassword;
    this.returnInfo.newPassword = this.passwordForm.value.newPassword;
    this.dialogRef.close(this.returnInfo);
  }  
    
  onCancelClick(): void {
    this.dialogRef.close(null);
  }

}
