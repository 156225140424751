import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSortHeaderIntl } from '@angular/material/sort';

@Injectable()
export class MatSortHeaderIntlCustom extends MatSortHeaderIntl {
    constructor(private readonly translate: TranslateService) {
        super();
    }

    sortButtonLabel = (id: string) => {
        return this.translate.instant('TABLE_CTRL.SORT.BUTTON_LABEL', {'value': id});
    }
}