export class EmailAuthenticationModesDB {

    public static emailAuthenticationModes = [
        {
            "code": "tls",
            "langKey": "TLS"
        },
        {
            "code": "ssl",
            "langKey": "SSL"
        }
    ]
}