export class ProviderKeyHandling {
    public static providerKeyHandling = [
        {
            "display": "Fail",
            "value": "fail",
            "tooltip": "SETTINGS.GENERAL.PROVIDER_KEY_HANDLING_TOOLTIP.FAIL"
        },
        {
            "display": "Steal",
            "value": "steal",
            "tooltip": "SETTINGS.GENERAL.PROVIDER_KEY_HANDLING_TOOLTIP.STEAL"
        },
        {
            "display": "Connect",
            "value": "connect",
            "tooltip": "SETTINGS.GENERAL.PROVIDER_KEY_HANDLING_TOOLTIP.CONNECT"
        }
    ]
}