import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'app/shared/services/settings/settings.service';
import { environment } from '../../../../environments/environment';
import { ControllerCmsGeneralSettings } from 'app/shared/models/setup.controllercms.model';
import { ControllercmsService } from 'app/shared/services/setup/controllercms/controllercms.service';
import { ThemeService } from 'app/shared/services/theme.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  appVersion = environment.appVersion;

  companyNm = "CardExchange Solutions";
  productNm = "CardExchange® Admin Center";
  copyright = "© 2021 CardExchange, Inc.";
  accessibilityURL: string = "";

  constructor(private settingsService: SettingsService,  private controllerCmsService: ControllercmsService, private themeService: ThemeService) { }

  ngOnInit() {
    this.settingsService.getProductInfo().subscribe((data: any) => {
      if (data) {
        this.companyNm = (data.companyName) ? data.companyName : this.companyNm;
        this.productNm = (data.productName) ? data.productName : this.productNm;
        this.copyright = (data.copyright) ? data.copyright : this.copyright;
      }
    })
    this.controllerCmsService.getSettingsGeneral().subscribe((data: ControllerCmsGeneralSettings) => {
      this.accessibilityURL = data.adminCenterAccessibilityStatement;
    })
  }

}
