export class GroupFiltersOperatorsDB {

    public static groupFilterOperators = [
        {
            "code": "eq",
            "langKey": "EQUALS"
        },
        // {
        //     "code": "neq",
        //     "langKey": "NOT_EQ"
        // },
        // {
        //     "code": "lt",
        //     "langKey": "LESS_THAN"
        // },
        // {
        //     "code": "gt",
        //     "langKey": "GREATER_THAN"
        // },
        // {
        //     "code": "leq",
        //     "langKey": "LESS_OR_EQ"
        // },
        // {
        //     "code": "geq",
        //     "langKey": "GREATER_OR_EQ"
        // },
        {
            "code": "contains",
            "langKey": "CONTAINS"
        }
        // {
        //     "code": "ncontains",
        //     "langKey": "NOT_CONTAIN"
        // },
        // {
        //     "code": "startsw",
        //     "langKey": "STARTS_WITH"
        // }
    ]
}