<div id="snackBarThingy">
  <ng-container [ngSwitch]="data.status">
    <div *ngSwitchCase="403">
      {{ 'ERROR.NO_PERMISSION' | translate }}
    </div>
    <div *ngSwitchCase="404">
      {{ 'ERROR.NOT_FOUND' | translate }}
    </div>
    <div *ngSwitchDefault>
      {{ 'ERROR.UNEXPECTED' | translate }}
    </div>
  </ng-container>
  <div>
    <button mat-icon-button (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>