import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RelativeTimePipe } from './relative-time.pipe';
import { ExcerptPipe } from "./excerpt.pipe";
import { GetValueByKeyPipe } from './get-value-by-key.pipe';
import { DatedateFormatFromSettingsPipe } from './date-format-from-settings.pipe';
import { DisplayLabelPipe } from './display-label.pipe';
import { DisplayAttributePipe } from './display-attribute.pipe';

const pipes = [
  RelativeTimePipe,
  ExcerptPipe,
  GetValueByKeyPipe,
  DatedateFormatFromSettingsPipe,
  DisplayLabelPipe,
  DisplayAttributePipe
]

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: pipes,
  exports: pipes,
  providers: [	DatedateFormatFromSettingsPipe
   ]
})
export class SharedPipesModule {}