export class DefaultCardBlockReason {
    public static reasons = [
        "Lost",
        "Stolen",
        "Damaged",
        "Defective",
        "Returned",
        "Departed",
        "Terminated",
        "Archived"
    ]
}