<section id="extraPanel">
    <ng-container *ngIf="(displayKeys$ | async) as keys">
        <ng-container *ngIf="userMetadata$ | async as usermetadata">
            <mat-expansion-panel [expanded]="false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{ title }}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <section id="extraPanelForm">
                    <form role="form" [formGroup]="extraPanelForm">
                        <div fxLayout="row wrap">
                            <div fxFlex="100" fxFlex.gt-xs="100" class="pr-1">
                                <div class="pb-1">
                                    <div *ngFor="let control of keys">
                                        <ng-container [ngSwitch]="control.type">

                                            <mat-form-field [class.hidden]="hiddenForm(control.controlName)" *ngSwitchCase="'integer'" class="full-width">
                                                <input matInput type="number"
                                                [placeholder]="control.label"
                                                [formControlName]="control.controlName">
                                            </mat-form-field>
                                            <mat-form-field [class.hidden]="hiddenForm(control.controlName)" *ngSwitchCase="'long'" class="full-width">
                                                <input matInput type="number"
                                                [placeholder]="control.label"
                                                [formControlName]="control.controlName">
                                            </mat-form-field>

                                        </ng-container>
                                    </div>
                                </div>
                                <!-- Conditions Table -->
                                <div class="pb-1" *ngIf="showConditions">
                                    <span class="whiteText table-title">{{ conditionsTableTitle }}</span>
                                    <mat-table [dataSource]="newConditions" matSort matSortDisableClear>
                                        <ng-container matColumnDef="AttributeId">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header tabindex="-1"> {{ "SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.ATTRIBUTE" | translate }} </mat-header-cell>
                                        <mat-cell *matCellDef="let row" data-label="AttributeId"> {{ "SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.ATTRIBUTEID" | translate | displayAttribute: row : usermetadata }} </mat-cell>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="Value">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header tabindex="-1"> {{ "SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.VALUE" | translate }} </mat-header-cell>
                                        <mat-cell *matCellDef="let row" data-label="Value"> {{row.value}} </mat-cell>
                                        </ng-container>
                                    
                                        <ng-container matColumnDef="Prefix">
                                        <mat-header-cell *matHeaderCellDef tabindex="-1"> {{ "SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.PREFIX" | translate }} </mat-header-cell>
                                        <mat-cell *matCellDef="let row" data-label="Prefix"> {{row.prefix}} </mat-cell>
                                        </ng-container>

                                        <ng-container matColumnDef="Actions">
                                            <mat-header-cell *matHeaderCellDef class="mat-column-right" tabindex="-1">
                                                <button 
                                                mat-button
                                                (click)="setupAddForm()"
                                                matTooltip="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.OPEN_NEW_CUSTOM_FIELD_LABELS' | translate }}"
                                                attr.aria-label="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.OPEN_NEW_CUSTOM_FIELD_LABELS' | translate }}"
                                                tabindex="0">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                            </mat-header-cell>
                                            <mat-cell *matCellDef="let row; let i = index" class="w-150 th-center" data-label="actions"> 
                                              <button 
                                                  mat-raised-button
                                                  (click)="setupEditForm(i)"
                                                  matTooltip="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.OPEN_EDIT_CUSTOM_FIELD_LABEL' | translate:{'value':row.label} }}"
                                                  attr.aria-label="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.OPEN_EDIT_CUSTOM_FIELD_LABEL' | translate:{'value':row.label} }}"
                                                  tabindex="0">
                                                  <mat-icon>create</mat-icon>
                                              </button>
                                              <button 
                                                  mat-raised-button 
                                                  (click)="deleteCondition(row,i)"
                                                  color="warn"
                                                  matTooltip="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.DELETE_CUSTOM_FIELD_LABEL' | translate:{'value':row.label} }}"
                                                  attr.aria-label="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.DELETE_CUSTOM_FIELD_LABEL' | translate:{'value':row.label} }}"
                                                  tabindex="0">
                                                  <mat-icon>delete_forever</mat-icon>
                                              </button> 
                                            </mat-cell>
                                        </ng-container>

                                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                                        </mat-row>
                                    </mat-table>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </mat-expansion-panel>
        </ng-container>
    </ng-container>
</section>