export class PhotoRejectReasonDb{
    public static reason = [
        {
            "code": "NoMatch",
            "langKey": "No Match"
        },
        {
            "code": "NoFaceDetected",
            "langKey": "No face detected"
        },
        {
            "code":"Cropped",
            "langKey": "Cropped"
        },
        {
            "code":"Blurred",
            "langKey": "Blurred"
        }
    ]
}