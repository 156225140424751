<div class="app-admin-wrap" dir='ltr'>

  <!-- Main Container -->
  <mat-sidenav-container 
    [dir]='layoutConf.dir'
    class="app-admin-container app-side-nav-container mat-drawer-transition"
    [ngClass]="adminContainerClasses">

    <mat-sidenav-content>

      <!-- Header for side navigation layout -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-header-side 
        *ngIf="layoutConf.navigationPos === 'side'"
        [sidebarPanel]="rightsidePanel"
        (notificationsClicked) ="showNotificationsOnSidebar($event)"
        (settingsClicked) ="showSettingsOnSidebar($event)">
      </app-header-side>

      <!-- SIDEBAR -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-sidebar-side 
      *ngIf="layoutConf.navigationPos === 'side'" 
      (mouseenter)="sidebarMouseenter($event)"
      (mouseleave)="sidebarMouseleave($event)"
      ></app-sidebar-side>
    
      <!-- App content -->
      <div role="main" class="main-content-wrap" id="main-content-wrap" [perfectScrollbar]="" [disabled]="layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
        <div class="rightside-content-hold" id="rightside-content-hold" [perfectScrollbar]="scrollConfig" [disabled]="!layoutConf.topbarFixed || !layoutConf.perfectScrollbar">
          <div *ngIf="live_enabled == 'false'" class="testEnvironmentBanner">
            <div class="testEnvironmentInfo">
              You are currently running the sandbox environment
            </div>
          </div>
          <!-- View Loader -->
          <div class="view-loader" *ngIf="isModuleLoading" style="position:fixed;"
          fxLayout="column" fxLayoutAlign="center center">
            <div class="spinner">
              <div class="double-bounce1 mat-bg-accent"></div>
              <div class="double-bounce2 mat-bg-primary"></div>
            </div>
          </div>
          <!-- Breadcrumb -->
          <app-breadcrumb></app-breadcrumb>
          <!-- View outlet -->
          <router-outlet></router-outlet>
          <span class="m-auto" *ngIf="!layoutConf.footerFixed"></span>
          <app-footer *ngIf="!layoutConf.footerFixed" style="display: block; margin: 0 -.333rem -.33rem"></app-footer>
        </div>
        <span class="m-auto" *ngIf="layoutConf.footerFixed"></span>
        <app-footer *ngIf="layoutConf.footerFixed"></app-footer>
      </div>
      <!-- View overlay for mobile navigation -->
      <div class="sidebar-backdrop"
      [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
      (click)="closeSidebar()"></div>
    </mat-sidenav-content>

    <mat-sidenav #rightsidePanel mode="over" class="" position="end">
      <div class="" fxLayout="column">
        <app-notifications *ngIf="notificationsOnSidebar" [notificPanel]="rightsidePanel"></app-notifications>
        <app-app-settings *ngIf="settingsOnSidebar" [appSettingsPanel]="rightsidePanel"></app-app-settings>
      </div>
    </mat-sidenav>
  </mat-sidenav-container>

</div>