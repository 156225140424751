export class EmailPlaceholdersCredentialDB {

    public static placeholders = [
        {
            "key": "{CardNumber}",
            "required": false
        },
        {
            "key": "{ChipSerialNumber}",
            "required": false
        },
        {
            "key": "{PACSCardNumber}",
            "required": false
        },
        {
            "key": "{PACSFacilityCode}",
            "required": false
        },
        {
            "key": "{PrintDate}",
            "required": false
        },
        {
            "key": "{BlockReason}",
            "required": false
        },
        {
            "key": "{Status}",
            "required": false
        }
    ];
}