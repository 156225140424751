import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { User, UserExportEntry } from './../../models/user.model';
import { RequestParamNamesHelper, SearchParam } from "./../../helpers/api-requests.helper";
import { Observable, throwError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseService } from '../base.service';
import { CurrencyPipe, DOCUMENT } from '@angular/common';
import { IOmniCloudUserEntity } from 'app/interfaces/omni-cloud/state.interface';

const BASE_URL = 'users';
const CHANGE_PWD_URL = 'users/current/password';
const USER_LOGIN_PROVIDER = 'users/externalLoginProvider';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService {

  paramHelper = new RequestParamNamesHelper();

  constructor(private http: HttpClient, @Inject(DOCUMENT) private document: Document) { 
    super(document);
  }

  getTableDataConf() {
    return [
      /*{prop: 'firstName', name: 'FIRST_NAME'},
      {prop: 'lastName', name: 'LAST_NAME'},*/
      {prop: 'email', name: 'EMAIL'},
      {prop: 'mobileNumber', name: 'MOBILE_NUMBER'},
      {prop: 'emailConfirmed', name: 'ISCONFIRMED'},
      {prop: 'emailSent', name: 'ISSENT'},
      {prop: 'isActive', name: 'ISACTIVE'},
      //{prop: 'role', name: 'ROLE'},
      {prop: 'startDate', name: 'START_DATE'},
      {prop: 'endDate', name: 'END_DATE'},
      {prop: 'createdDate', name: 'CREATED_DATE'},
      {prop: 'actions', name: 'ACTIONS'}
    ];
  }

  getExportDataConf() {
    return [
      'id',
      /*'firstName',
      'lastName',*/
      'email',
      'emailConfirmed',
      'emailSent',
      'mobileNumber',
      'isActive',
      //'role',
      'startDate',
      'endDate',
      'created',
      /*'roleId',
      'roleName'*/
    ];
  }

  getUsers(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g,'') : search.value));
    params = params
        .set(this.paramHelper.getSortParamName(), sort)
        .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
        .set(this.paramHelper.getPageParamName(), "" + page)
        .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Email");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getUsersToExport(query: SearchParam[] = [], sort = "", order = "asc"): Observable<UserExportEntry[]> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g,'') : search.value));
    params = params
              .set(this.paramHelper.getSortParamName(), sort)
              .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
              .set(this.paramHelper.getPageParamName(), "1")
              .set(this.paramHelper.getPerPageParamName(), "100");

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Email");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, {params: params}).pipe(
      map((rows) => rows.map( row => {
            return {
              id: row.id,
              email: row.email,
              /*firstName: row.firstName,
              lastName: row.lastName,*/
              emailConfirmed: row.emailConfirmed ? 1 : 0,
              emailSent: row.emailSent ? 1 : 0,
              mobileNumber: row.mobileNumber,
              isActive: row.isActive ? 1: 0,
              startDate: row.startDate,
              endDate: row.endDate,
              created: row.created/*,
              roleId: row.role != undefined && row.role.id != undefined ? row.role.id : '',
              roleName: row.role != undefined && row.role.name != undefined ? row.role.name : ''*/
            } as UserExportEntry})),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  searchUsers(query: SearchParam[] = []): Observable<User[]> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g,'') : search.value));
    params = params
              .set(this.paramHelper.getSortParamName(), 'Email')
              .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
              .set(this.paramHelper.getPageParamName(), "1")
              .set(this.paramHelper.getPerPageParamName(), "100");

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Email");
    }

    return this.http.get<User[]>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, {
      params: params}).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getUserById(id: string): Observable<User> {

    return this.http.get<User>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveUser(userInfo: User) {
    
    let result: any;

    if (userInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/' + userInfo.id, userInfo)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL, userInfo)
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    }
    return result;   
  }

  resendEmail(userid: string) {
    
    let result: any;

    if (userid) {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/emailresend/' + userid, {})
                  .pipe(
                    catchError((error) => {
                        return throwError(error);
                    })
                  )
    } 
    return result;   
  }

  deleteUser(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  changeUserPassword(oldPassword: string, newPassword: string) {
    let passwordInfo = {currentPassword: oldPassword, newPassword: newPassword};
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + CHANGE_PWD_URL, passwordInfo)
      .pipe(
        catchError((error) => {
            return throwError(error);
        })
      );
  }

  getUserRoles(id: string): Promise<any> {
    return this.http.get<User>(this.environmentForDomain.webApiBaseUrl + BASE_URL + '/' + id + '/roles').toPromise(); 
  }

  saveSitePreference(savePreferenceReq: IOmniCloudUserEntity) {
    return this.http.post(this.environmentForDomain.webApiBaseUrl + BASE_URL + '/' + savePreferenceReq.id + '/preferences', savePreferenceReq)
      .pipe(
        catchError((error) => {
          return throwError(error);
        }) 
      )
  }
  getUsersLoginProvider(): Observable<string[]> {
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + USER_LOGIN_PROVIDER)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }
}
