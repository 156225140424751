import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppConfig } from "../models/app-config.model";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: 'root'
  })
export class AppConfigService {
    private config: BehaviorSubject<AppConfig> = new BehaviorSubject<AppConfig>(null);

    constructor(private http: HttpClient) {}
    loadConfig(): Promise<void> {
        return this.http
            .get<AppConfig>('/assets/app.config.json')
            .toPromise()
            .then(data => {
                this.config.next(data);
            }).catch((error)=>{
                console.log(error);
                this.config.next({});
            });
    }
    
    getConfig(): BehaviorSubject<AppConfig> {
        return this.config;
    }
}