import { Component, OnInit, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

import { RoutePartsService } from "./shared/services/route-parts.service";
// import { ThemeService } from './shared/services/theme.service';

import { filter } from 'rxjs/operators';
import { LayoutService } from './shared/services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { EnvSettingsService } from './shared/services/env-settings.service';
import { LocalStoreService } from './shared/services/local-store.service';
import { EnvSettings } from './shared/models/env-settings.model';
import { environment } from '../environments/environment';
import { DOCUMENT } from '@angular/common';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { OidcSecurityService } from 'angular-auth-oidc-client'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  appTitle = 'CardExchange® Cloud | Admin Center';
  pageTitle = 'Dashboard';
  
  idleState = 'Not started.'
  timedOut = false;
  authHasBeenChecked = false;
  idleTimeoutInSeconds = environment.config.defaultEnvSettings.idleTimeoutInSeconds;

  constructor(
    public title: Title, 
    private router: Router, 
    private activeRoute: ActivatedRoute,
    private routePartsService: RoutePartsService,
    private layout: LayoutService,
    private translate: TranslateService,
    private envSettings: EnvSettingsService,
    private ls: LocalStoreService,
    @Inject(DOCUMENT) private document: Document,
    private idle: Idle,
    public oidcSecurityService: OidcSecurityService
  ) {
    //for dynamic timeout set up, if user puts 30 mins, then do 15 mins for idle, and 15 mins for timeout
    //set default time for timeout here and store in localstore. 
    //
    idle.setIdle(this.idleTimeoutInSeconds / 2);
    idle.setTimeout(this.idleTimeoutInSeconds / 2);
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    
    this.ls.watch(environment.config.currentEnvSettingsStorageKey)
    .subscribe(settings => {
      if (settings != null && 'idleTimeoutInSeconds' in settings === true && this.idleTimeoutInSeconds !== settings.idleTimeoutInSeconds) {
        this.idleTimeoutInSeconds = settings.idleTimeoutInSeconds;
        idle.setIdle(this.idleTimeoutInSeconds / 2);
        idle.setTimeout(this.idleTimeoutInSeconds / 2);
        this.resetIdle();
      }
    });

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.resetIdle();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      
      oidcSecurityService.logoff();
    });

    //This is to check if the user is logged in or not
    oidcSecurityService.getIsAuthorized().subscribe(async isAuthorized => {
      //if user is logged in and we have not passed this check yet.
      //then reset the idle timer and set authHasBeenChecked to true so it does not
      //go through this again.
      if (isAuthorized === true) {
        if (this.authHasBeenChecked === false) {
          this.resetIdle();
          this.authHasBeenChecked = true;
        }
      } else {
        //If user is not logged in then we can stop the timer 
        idle.stop();
        this.idleState = 'Stopped.'
        this.authHasBeenChecked = false;
      }
    });
  }

  ngOnInit() {

    this.translate.setDefaultLang(environment.config.defaultEnvSettings.language);
    let defaultLanguage = environment.config.defaultEnvSettings.language;
    this.translate.use(defaultLanguage);
    this.document.documentElement.lang = defaultLanguage;

    this.changePageTitle();
  }
  ngAfterViewInit() {
  }
  changePageTitle() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((routeChange) => {
      var routeParts = this.routePartsService.generateRouteParts(this.activeRoute.snapshot);
      if (!routeParts.length)
        return this.title.setTitle(this.appTitle);
      this.pageTitle = routeParts
                      .reverse()
                      .map((part) => this.translate.instant('PAGE_TITLE.' + part.title) )
                      .reduce((partA, partI) => {return `${partA} > ${partI}`});
      
      this.pageTitle = this.pageTitle + ` | ${this.appTitle}`;
      this.title.setTitle(this.pageTitle);
    });
  }

  resetIdle() {
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }
}
