<footer class="main-footer">
    <div class="footer-content">
        <section name="product-name">
            <span>{{productNm}} v{{appVersion}}</span>
        </section>
        <section class="ml-5" name="copyright-text">
            <span>{{copyright}}</span>
        </section>
        <section class="ml-auto " name="accessibility-statement" *ngIf="accessibilityURL" style="color: #31aaeb;">
            <a [style.color]="this.themeService.activatedTheme.name == 'cardex-light' ? '#394bc2' : '#31aaeb'" style="text-decoration: underline;" href="{{accessibilityURL}}" target="_blank">{{ 'APP_SETTINGS.ACCESSIBILITY_STATEMENT' | translate }}</a> <!--Translation-->
        </section>
        <section class="ml-auto" name="company-name">
            <span>{{companyNm}}</span>
        </section>
    </div>
</footer>