export class FargoToPrintOriginAttributesDB {

    public static originAttributes = [
        {
            "entity": "Person",
            "fields": [
                "personNumber",
                "addressPreference",
                "identificationType",
                "identificationTypeValue",
                "firstName",
                "middleName",
                "lastName",
                "birthDate",
                "gender",
                "prefix",
                "suffix",
                "phoneNumber",
                "mobileNumber",
                "status",
                "profilePreference",
                "Person.createdDateUtc",
                "origin",
                "Photo",
                "FreeField1",
                "FreeField2",
                "FreeField3",
                "FreeField4",
                "FreeField5",
                "FreeField6",
                "FreeField7",
                "FreeField8",
                "FreeField9",
                "FreeField10",
                "FreeField11",
                "FreeField12",
                "FreeField13",
                "FreeField14",
                "FreeField15",
                "FreeField16"
            ]
        },
        {
            "entity": "Address",
            "fields": [
                "type",
                "streetAddress",
                "locality",
                "region",
                "postalCode",
                "country"
            ]
        },
        {
            "entity": "Cardholder",
            "fields": [
                "email",
                "businessUnit",
                "externalCompany",
                "department",
                "costAccount",
                "location",
                "jobTitle",
                "Cardholder.startDateUtc",
                "Cardholder.endDateUtc",
                "status",
                "nameOnCard",
                "note",
                "Cardholder.createdDateUtc",
                "profileNumber",
                "Cardholder.freeField1",
                "Cardholder.freeField2"
            ]
        },
        {
            "entity": "Card",
            "fields": [
                "cardNumber",
                "chipSerialNumber",
                "status",
                "type",
                "Card.startDateUtc",
                "Card.endDateUtc",
                "Card.activatedOnUtc",
                "Card.createdDateUtc",
                "photoVersion",
                "nameOnCard",
                "pacsCardNumber",
                "pacsFacilityCode"
            ]
        }
    ]
}