import { Directive, HostListener, Inject } from '@angular/core';
import { DropdownLinkDirective } from './dropdown-link.directive';

@Directive({
  selector: '[appDropdownToggle]'
})
export class DropdownAnchorDirective {

  protected navlink: DropdownLinkDirective;
  private openByFocus: boolean;
  
    constructor( @Inject(DropdownLinkDirective) navlink: DropdownLinkDirective) {
      this.navlink = navlink;
      this.openByFocus = false;
    }
  
    @HostListener('click', ['$event'])
    onClick(e: any) {
      if (!this.openByFocus)
        this.navlink.toggle();
      this.openByFocus = false;
    }

    @HostListener('keypress', ['$event'])
    onKeyPress(e: any) {
      if (e.charCode === 13 && !this.openByFocus) {
        this.navlink.toggle();
      }
      this.openByFocus = false;
    }

    @HostListener('focus', ['$event'])
    onFocus(e: any) {
      this.openByFocus = true;
      this.navlink.doOpen();
    }
}
