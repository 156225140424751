import { Component, Inject, OnInit } from '@angular/core';
import { OidcSecurityService, AuthorizationResult, AuthorizationState } from 'angular-auth-oidc-client';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { DOCUMENT } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from 'app/shared/components/error-snackbar/error-snackbar.component';

@Component({
  selector: 'omni-cloud-auth',
  templateUrl: './omni-cloud-auth.component.html',
  styleUrls: ['./omni-cloud-auth.component.scss']
})
export class OmniCloudAuthComponent implements OnInit {

  adminCenterUrl : string = '';

  constructor(
            public oidcSecurityService: OidcSecurityService,
            private ls: LocalStoreService,
            @Inject(DOCUMENT) private document: Document, 
            private snackBar: MatSnackBar) {

    let envForSubdomain = environment.subdomains.find(elem => elem.subdomain == this.document.location.hostname);
    if (envForSubdomain == undefined) {
      this.snackBar.openFromComponent(ErrorSnackbarComponent,
        {
          data: 'No Auth address found.'
        });
    } else {
      this.adminCenterUrl = envForSubdomain.adminCenterUrl;
    }

    this.oidcSecurityService.getIsModuleSetup()
      .pipe(
        filter((isModuleSetup: boolean) => isModuleSetup),
        take(1)
      )
      .subscribe(() => {
        this.doCallbackLogicIfRequired();
      });
    this.oidcSecurityService.onModuleSetup.subscribe(() => {
      this.oidcSecurityService.authorize();
    },
    error => {
      console.log('Error on module setup, ', error);
    });
    this.oidcSecurityService.onAuthorizationResult.subscribe(
      (authorizationResult: AuthorizationResult) => {
        this.onAuthorizationResultComplete(authorizationResult);
      },
      error => {
        console.log('Error on auth, ', error);
      });
  }

  ngOnInit() { }

  onLogin() {
    console.log('Authorizing');
    this.oidcSecurityService.authorize();
    console.log('Authorized');
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

  private doCallbackLogicIfRequired() {
    if (window.location.hash) {
      this.oidcSecurityService.authorizedImplicitFlowCallback();
    } else {
      this.oidcSecurityService.authorize();
    }
  }

  private onAuthorizationResultComplete(authorizationResult: AuthorizationResult) {
    if (authorizationResult.authorizationState === AuthorizationState.authorized) {      
      this.oidcSecurityService.getUserData().subscribe((userData: any) => {
        this.ls.setItem(environment.config.currentUserStorageKey, userData);
        this.ls.setItem(environment.config.currentUserRoleAuthStorageKey, null);
      })      
      window.location.href = this.adminCenterUrl;
    }
    window.location.href = this.adminCenterUrl;
  }
}
