export class AddressTypesDB {

    public static addressTypes = [
        {
            "code": "none",
            "langKey": "NONE"
        },
        {
            "code": "home",
            "langKey": "HOME"
        },
        {
            "code": "business",
            "langKey": "BUSINESS"
        },
        {
            "code": "po_box",
            "langKey": "PO_BOX"
        }
    ]
}