export class CustomFieldsTypesDB {

    public static customFieldsTypes = [
      {
        "label": "HEADING",
        "code": "heading"
      },
      {
        "label": "EMAIL",
        "code": "email"
      },
      {
        "label": "RADIO",
        "code": "radio"
      },
      {
        "label": "DATETIME",
        "code": "datetime"
      },
      {
        "label": "TEXT_AREA",
        "code": "text"
      },
      {
        "label": "URL",
        "code": "url"
      },
      {
        "label": "NUMBER",
        "code": "number"
      },
      {
        "label": "PASSWORD",
        "code": "password"
      },
      {
        "label": "CHECKBOX",
        "code": "checkbox"
      }
    ]      
}