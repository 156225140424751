<div class="sidenav-hold">
  <ul appDropdown class="sidenav">
    <li *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open">
      <div class="nav-item-sep" *ngIf="item.type === 'separator'">
        <span class="text-muted">{{"SIDE_NAV." + item.name | uppercase | translate }}</span>
      </div>
      <div
        *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'"
        class="lvl1"
      >
        <a
          routerLink="/{{item.state}}"
          appDropdownToggle
          matRipple
          *ngIf="item.type === 'link'"
          routerLinkActive="route-selected"
        >
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{"SIDE_NAV." + item.name | uppercase | translate }}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
        </a>
        <a
          [href]="item.state"
          appDropdownToggle
          matRipple
          *ngIf="item.type === 'extLink'"
          target="_blank"
          routerLinkActive="route-selected"
        >
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{"SIDE_NAV." + item.name | uppercase | translate }}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            [ngStyle]="{background: badge.color}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
        </a>

        <!-- DropDown -->
        <a
          *ngIf="item.type === 'dropDown'"
          appDropdownToggle
          matRipple
          routerLinkActive="route-selected"
          tabindex="0"
        >
          <mat-icon>{{item.icon}}</mat-icon>
          <span class="item-name lvl1">{{"SIDE_NAV." + item.name | uppercase | translate }}</span>
          <span fxFlex></span>
          <span
            class="menuitem-badge mat-bg-{{ badge.color }}"
            *ngFor="let badge of item.badges"
            >{{ badge.value }}</span
          >
          <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
        </a>
        <!-- LEVEL 2 -->
        <ul class="submenu lvl2" appDropdown *ngIf="item.type === 'dropDown'">
          <li
            *ngFor="let itemLvL2 of item.sub"
            appDropdownLink
            routerLinkActive="route-selected"
            
          >
            <a
              routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}"
              appDropdownToggle
              *ngIf="itemLvL2.type !== 'dropDown' && itemLvL2.type !== 'extLink' "
              matRipple
              routerLinkActive="route-selected"
              [ngClass]="{'disabledLink': !hasHidMobileSubsciption && itemLvL2.state == 'hid_mobile'}"
            >
              <span class="item-name lvl2">{{"SIDE_NAV." + itemLvL2.name | uppercase | translate}}</span>
              <span fxFlex></span>
            </a>

            <a
              [href]="itemLvL2.state"
              appDropdownToggle
              matRipple
              *ngIf="itemLvL2.type === 'extLink'"
              target="_blank"
              routerLinkActive="route-selected"
            >
              <span class="item-name lvl2">{{"SIDE_NAV." + itemLvL2.name | uppercase | translate}}</span>
            </a>

            <a
              *ngIf="itemLvL2.type === 'dropDown'"
              appDropdownToggle
              matRipple
              routerLinkActive="route-selected"
              tabindex="0"
              [ngClass]="{'disabledLink': !hasSaltoSubscription && itemLvL2.state == 'salto_space'}"
            >
              <span class="item-name lvl2">{{"SIDE_NAV." + itemLvL2.name | uppercase | translate}}</span>
              <span fxFlex></span>
              <mat-icon [ngClass]="{'hide-icon': !hasSaltoSubscription && itemLvL2.state == 'salto_space'}" class="menu-caret">keyboard_arrow_right</mat-icon>
            </a>

            <!-- LEVEL 3 -->
            <ul
              class="submenu lvl3"
              appDropdown
              *ngIf="itemLvL2.type === 'dropDown'"
            >
              <li
                *ngFor="let itemLvL3 of itemLvL2.sub"
                appDropdownLink
                routerLinkActive="route-selected"
              >
                <a
                  *ngIf="itemLvL3.type !== 'dropDown' && itemLvL3.type !== 'extLink'"
                  routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}"
                  appDropdownToggle
                  matRipple
                  routerLinkActive="border-radius-4 route-selected"
                >
                  <span 
                    class="item-name lvl3"
                  >
                    {{"SIDE_NAV." + itemLvL3.name | uppercase | translate}}
                  </span>
                </a>

                <a
                  *ngIf="itemLvL3.type === 'dropDown'"
                  appDropdownToggle
                  matRipple
                  routerLinkActive="route-selected"
                  tabindex="0"
                >
                  <span class="item-name lvl2">{{"SIDE_NAV." + itemLvL3.name | uppercase | translate}}</span>
                  <span fxFlex></span>
                  <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
                </a>
                
                <!-- LEVEL 4 -->
                <ul 
                  class="submenu lvl4"
                  appDropdown
                  *ngIf="itemLvL3.type === 'dropDown'"
                >
                  <li 
                    *ngFor="let itemLvL4 of itemLvL3.sub"
                    appDropdownLink
                    routerLinkActive="route-selected"
                  >
                    <a
                    *ngIf="itemLvL4.type !== 'dropDown' && itemLvL4.type !== 'extLink'"
                    routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{ itemLvL3.state ? '/'+itemLvL3.state : ''}}/{{itemLvL4.state}}"
                    appDropdownToggle
                    matRipple
                    routerLinkActive="border-radius-4 route-selected"
                    >
                      <span 
                        class="item-name lvl4"
                      >
                        {{"SIDE_NAV." + itemLvL4.name | uppercase | translate}}
                      </span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </li>
  </ul>
</div>
