import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  Route
} from "@angular/router";
import { Observable } from 'rxjs';
import { map,tap } from 'rxjs/operators';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { Store } from '@ngxs/store';
import { ReLoginRedirect, SetRequiredData } from '../../auth/authentication-store/authentication.action';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private oidcSecurityService: OidcSecurityService, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.checkUser();
  }

  canLoad(state: Route): Observable<boolean> {
      return this.checkUser();
  }

  private checkUser(): Observable<boolean> {
    
      return this.oidcSecurityService.getIsAuthorized().pipe(
          tap((isAuthorized: boolean) => {
            console.log('Checking auth', isAuthorized);
              if(isAuthorized) {
                return this.store.dispatch([new SetRequiredData(), new ReLoginRedirect()]).pipe(
                  map(_ => true)
                );
              } else {
                console.log('not authed');
                this.router.navigate(['/auth/login']);
                return false;
              }
              
          })
      );
  }
}