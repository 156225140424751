import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlCustom extends MatPaginatorIntl {
    constructor(private readonly translate: TranslateService) {
        super();
    
        this.translate.onLangChange.subscribe(() => {
            this.translateLabels();
          });
      
        this.translateLabels();
    }

    getRangeLabel = function (page: number, pageSize: number, length: number) {
        if (length === 0 || pageSize === 0) {
            return '0' + this.translate.instant('TABLE_CTRL.PAGINATOR.OF') + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + this.translate.instant('TABLE_CTRL.PAGINATOR.OF') + length;
    };

    translateLabels() {
        this.itemsPerPageLabel = this.translate.instant('TABLE_CTRL.PAGINATOR.ITEMS_PER_PAGE');
        this.nextPageLabel = this.translate.instant('TABLE_CTRL.PAGINATOR.NEXT_PAGE');
        this.previousPageLabel = this.translate.instant('TABLE_CTRL.PAGINATOR.PREVIOUS_PAGE');
        this.firstPageLabel = this.translate.instant('TABLE_CTRL.PAGINATOR.FIRST_PAGE');
        this.lastPageLabel = this.translate.instant('TABLE_CTRL.PAGINATOR.LAST_PAGE');

        this.changes.next();
    }
}