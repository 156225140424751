export class IdleTimeoutOptionsDB {
    public static timeoutOptions = [
        {
            name: '24 Hours',
            value: 86400
        },
        {
            name: '60 minutes',
            value: 3600
        },
        {
            name: '30 minutes',
            value: 1800
        },
        {
            name: '15 minutes',
            value: 900
        }
    ]
}