import { Inject, Injectable } from '@angular/core';
import { RequestParamNamesHelper, SearchParam } from 'app/shared/helpers/api-requests.helper';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CredentialCategory, CredentialType, Profile, ControllerCmsGeneralSettings, ControllerCmsPhotoSettings, MessageTemplate, Message, Application, ApplicationProfile, Department, CostAccount, ExternalCompany, RequiredPlaceholder, PaymentArticle, CustomFieldLabels, ControllerCmsNotification, PartNumberDetails, UserApproverDetails, CredentialColumnDetail, CredentialBlockReasonDetail } from 'app/shared/models/setup.controllercms.model';
import { DOCUMENT } from '@angular/common';
import { BaseService } from '../../base.service';
import { AppConfigService } from '../../app-config.service';
import { AppConfig } from 'app/shared/models/app-config.model';

const BASE_URL_GENERAL_V1 = 'generalsettings';
const BASE_URL_GENERAL = 'setup/controllercms/controllergeneralsettings';
const BASE_URL_PHOTOS = 'setup/controllercms/controllerphotosettings';
const BASE_URL_PHOTOS_V1 = 'photosettings'
const BASE_URL_PROFILES = 'setup/controllercms/profiles';
const BASE_URL_MESSAGES = 'messageTemplates';
const BASE_URL_MESSAGE_TEMPLATES = 'messageTemplates/templates';
const BASE_URL_CRED_CATS = 'setup/controllercms/cardcategories';
const BASE_URL_CRED_TYPES = 'setup/controllercms/cardtypes';
const BASE_URL_CUSTOM_FIELD_LABELS = 'setup/controllercms/systemlabels'
const BASE_URL_APPLICATIONS = 'setup/controllercms/applications';
const BASE_URL_APPLICATION_PROFILES = 'setup/controllercms/applicationprofiles';
const BASE_URL_DEPARTMENTS = 'setup/controllercms/departments';
const BASE_URL_COST_ACCOUNTS = 'setup/controllercms/costaccounts';
const BASE_URL_EXT_COMPANIES = 'setup/controllercms/externalcompanies';
const BASE_URL_PAYMENT_ARTICLES = 'payments';
const BASE_URL_SYSTEM_LABELS = 'setup/controllercms/systemlabels';
const BASE_URL_SYSTEM_COMMUNICATIONS = 'setup/controllercms/systemcommunications';
const BASE_URL_BULK_CREATE_STAND_USER = 'bulk/cardholders/create-stand-user';
const BASE_URL_GET_HID_MOBILE_PART_NUMBERS = 'hidmobile/partNumber';
const BASE_URL_APPROVERDETAILS = 'setup/controllercms/userphotoapprovers';
const BASE_URL_CREDENTIAL_COLUMNS = 'setup/controllercms/credentialcolumns';
const BASE_URL_CREDENTIAL_BLOCK_REASON = 'setup/controllercms/credential-block-reason';

@Injectable({
  providedIn: 'root'
})
export class ControllercmsService extends BaseService {

  paramHelper = new RequestParamNamesHelper();
  config: AppConfig;

  constructor(private http: HttpClient, private appConfigService: AppConfigService, @Inject(DOCUMENT) private document: Document) {
    super(document);
    appConfigService.getConfig().subscribe((res: AppConfig) => {
      this.config = res;
    })
  }

  /* General */

  getSettingsGeneral(): Observable<ControllerCmsGeneralSettings> {
    let apiBaseUrl = this.environmentForDomain.webApiBaseUrl + BASE_URL_GENERAL_V1;
    if(this.config?.useV2Endpoints?.includes('getGeneralSettings')){
      apiBaseUrl = this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_GENERAL;
    }
    return this.http.get<any>(apiBaseUrl)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveSettingsGeneral(info: ControllerCmsGeneralSettings) {
    let apiBaseUrl = this.environmentForDomain.webApiBaseUrl + BASE_URL_GENERAL_V1;
    if(this.config?.useV2Endpoints?.includes('updateGeneralSettings')){
      apiBaseUrl = this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_GENERAL;
    }
    return this.http.put(apiBaseUrl, info)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* Photos */

  getSettingsPhoto(): Observable<ControllerCmsPhotoSettings> {
    let apiBaseUrl = this.environmentForDomain.webApiBaseUrl + BASE_URL_PHOTOS_V1;
    if(this.config?.useV2Endpoints?.includes('getPhotoSettings')){
      apiBaseUrl = this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PHOTOS;
    }
    return this.http.get<any>(apiBaseUrl)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveSettingsPhoto(info: ControllerCmsPhotoSettings) {
    let apiBaseUrl = this.environmentForDomain.webApiBaseUrl + BASE_URL_PHOTOS_V1;
    if(this.config?.useV2Endpoints?.includes('updatePhotoSettings')){
      apiBaseUrl = this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PHOTOS;
    }
    return this.http.put(apiBaseUrl, info)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* Profiles */

  getProfilesTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      //{ prop: 'nameOnCard', name: 'NAME_ON_CARD'},
      { prop: 'photoRequired', name: 'PHOTO_REQUIRED_CARD' },
      { prop: 'photoRequiredProduction', name: 'PHOTO_REQUIRED_PRODUCTION' },
      { prop: 'signatureRequired', name: 'SIGNATURE_REQUIRED' },
      { prop: 'proofIdentityRequired', name: 'PROOF_IDENTITY_REQUIRED' },
      { prop: 'selfServiceAccess', name: 'SELF_SERVICE_REQUIRED' },
      { prop: 'anonymous', name: 'ANONYMOUS' },
      { prop: 'useCustomProviderKey', name: "USE_CUSTOM_PROVIDER_KEY" },
      //{ prop: 'template', name: 'TEMPLATE'},
      //{ prop: 'payment', name: 'PAYMENT'},
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  getAllProfiles(): Observable<Profile[]> {
    return this.http.get<Profile[]>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getProfiles(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  /* It is a copy of GetProfiles because it does not have IsActive.
    However, for consistency and scalability, we proceed with getActiveProfiles
  */
  getActiveProfiles(): Observable<Profile[]> {

    let params = new HttpParams();
    params = params
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100");

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getProfileById(id: string): Observable<Profile> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveProfile(profileInfo: Profile) {

    let result: any;

    if (profileInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES + '/' + profileInfo.id, profileInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES, profileInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteProfile(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  bulkCreateStandUser(profileName: string) {
    return this.http.post(this.environmentForDomain.webApiBaseUrl + BASE_URL_BULK_CREATE_STAND_USER, { profileName: profileName })
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }

  profileNameIsDuplicate(profileName: string, profileId: string = null) {
    let requestUrl = this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_PROFILES + '/isDuplicate/' + profileName;

    if (profileId !== null) {
      requestUrl = requestUrl + '/' + profileId;
    }

    return this.http.get(requestUrl)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* Credential Categories */

  getCredentialCategoriesTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'description', name: 'DESCRIPTION' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<CredentialCategory>
  */
  getCredentialCategories(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_CATS, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActiveCredentialCategories(): Observable<CredentialCategory[]> {

    let params = new HttpParams();
    params = params
      .set("q", "true")
      .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100")

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_CATS, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCredentialCategoryById(id: string): Observable<CredentialCategory> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_CATS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveCredentialCategory(categoryInfo: CredentialCategory) {

    let result: any;
    if (categoryInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_CATS + '/' + categoryInfo.id, categoryInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_CATS, categoryInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteCredentialCategory(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_CATS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* Credential Types */

  getCredentialTypesTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'type', name: 'TYPE' },
      { prop: 'category', name: 'CATEGORY' },
      { prop: 'capacity', name: 'CAPACITY' },
      { prop: 'isDefault', name: 'IS_DEFAULT' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<CredentialType>
  */
  getCredentialTypes(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_TYPES, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActiveCredentialTypes(): Observable<CredentialType[]> {

    let params = new HttpParams();
    params = params
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100");

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_TYPES, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCredentialTypeById(id: string): Observable<CredentialType> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_TYPES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveCredentialType(typeInfo: CredentialType) {

    let result: any;

    if (typeInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_TYPES + '/' + typeInfo.id, typeInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_TYPES, typeInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteCredentialType(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CRED_TYPES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* Custom Field Labels */

  getCustomFieldLabelsTableDataConf() {
    return [
      { prop: 'label', name: 'LABEL' },
      { prop: 'attribute', name: 'ATTRIBUTE' },
      { prop: 'dataFormat', name: 'DATA_FORMAT' },
      { prop: 'isRequired', name: 'IS_REQUIRED' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<CredentialType>
  */
  getCustomFieldLabels(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Label");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SYSTEM_LABELS, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCustomFieldLabelById(id: string): Observable<CustomFieldLabels> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SYSTEM_LABELS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveCustomFieldLabel(customFieldLabelInfo: CustomFieldLabels) {

    let result: any;

    if (customFieldLabelInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CUSTOM_FIELD_LABELS + '/' + customFieldLabelInfo.id, customFieldLabelInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CUSTOM_FIELD_LABELS, customFieldLabelInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteCustomFieldLabel(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CUSTOM_FIELD_LABELS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* Messages */

  escapeMessageTemplatePlaceholders(placeholders: RequiredPlaceholder[]): RequiredPlaceholder[] {

    let escapedPlaceholders: RequiredPlaceholder[] = [];
    if (placeholders) {
      placeholders.forEach((element, index) => {
        escapedPlaceholders.push({ value: '{' + element.value + '}' } as RequiredPlaceholder);
      });
    }

    return escapedPlaceholders;
  }

  getMessageTemplates(): Observable<MessageTemplate[]> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MESSAGE_TEMPLATES)
      .pipe(
        map(result => {
          const templates: MessageTemplate[] = [];
          result.forEach(template => {
            if (template.application == 'spa') {
              templates.push(template);
            }
          });

          templates.forEach((obj, index) => {
            templates[index].requiredPlaceholdersArray = this.escapeMessageTemplatePlaceholders(obj.requiredPlaceholdersArray);
          });

          return templates;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  getMessage(idTemplate: string, language: string): Observable<Message> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MESSAGES + '/' + idTemplate + '/' + language)
      .pipe(
        map(messages => messages.length ? messages[0] : null),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveMessage(messageInfo: Message) {

    let result: any;

    messageInfo.application = 'spa';

    if (messageInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MESSAGES + '/' + messageInfo.id, messageInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_MESSAGES, messageInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }


  /* Applications */

  getApplicationsTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'description', name: 'DESCRIPTION' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getApplications(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATIONS, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActiveApplications(): Observable<Application[]> {

    let params = new HttpParams();
    params = params
      .set("q", "true")
      .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100");

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATIONS, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getApplicationById(id: string): Observable<Application> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATIONS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveApplication(applicationInfo: Application) {

    let result: any;

    if (applicationInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATIONS + '/' + applicationInfo.id, applicationInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATIONS, applicationInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteApplication(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATIONS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* Application Profiles */

  getApplicationProfilesTableDataConf() {
    return [
      { prop: 'application', name: 'APPLICATION' },
      { prop: 'profile', name: 'PROFILE' },
      { prop: 'code', name: 'CODE' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getApplicationProfiles(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Code");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATION_PROFILES, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getApplicationProfileById(id: string): Observable<ApplicationProfile> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATION_PROFILES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveApplicationProfile(appProfileInfo: ApplicationProfile) {

    let result: any;

    if (appProfileInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATION_PROFILES + '/' + appProfileInfo.id, appProfileInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATION_PROFILES, appProfileInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteApplicationProfile(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPLICATION_PROFILES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* Departments */

  getDepartmentsTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'costAccount', name: 'COST_ACCOUNT' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getDepartments(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_DEPARTMENTS, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActiveDepartments(): Observable<Department[]> {

    let params = new HttpParams();
    params = params
      .set("q", "true")
      .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100")

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_DEPARTMENTS, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getDepartmentById(id: string): Observable<Department> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_DEPARTMENTS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveDepartment(departmentInfo: Department) {

    let result: any;
    let payload = JSON.parse(JSON.stringify(departmentInfo))

    if (payload.costAccount.id == "") {
      payload.costAccount = null;
    }

    if (departmentInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_DEPARTMENTS + '/' + departmentInfo.id, payload)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_DEPARTMENTS, payload)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteDepartment(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_DEPARTMENTS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* Cost Accounts */

  getCostAccountsTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'profile', name: 'PROFILE' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getCostAccounts(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_COST_ACCOUNTS, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActiveCostAccounts(): Observable<CostAccount[]> {

    let params = new HttpParams();
    params = params
      .set("q", "true")
      .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100");

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_COST_ACCOUNTS, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCostAccountById(id: string): Observable<CostAccount> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_COST_ACCOUNTS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveCostAccount(accountInfo: CostAccount) {

    let result: any;

    if (accountInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_COST_ACCOUNTS + '/' + accountInfo.id, accountInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_COST_ACCOUNTS, accountInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteCostAccount(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_COST_ACCOUNTS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }


  /* External Companies */

  getExternalCompaniesTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getExternalCompanies(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page);

    if (query != []) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EXT_COMPANIES, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActiveExternalCompanies(): Observable<ExternalCompany[]> {

    let params = new HttpParams();
    params = params
      .set("q", "true")
      .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100")

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EXT_COMPANIES, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }


  getExternalCompanyById(id: string): Observable<ExternalCompany> {

    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EXT_COMPANIES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveExternalCompany(accountInfo: ExternalCompany) {

    let result: any;

    if (accountInfo.id) {
      result = this.http.put(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EXT_COMPANIES + '/' + accountInfo.id, accountInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EXT_COMPANIES, accountInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deleteExternalCompany(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_EXT_COMPANIES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /* Payment Articles */

  getPaymentArticlesTableDataConf() {
    return [
      { prop: 'name', name: 'NAME' },
      { prop: 'amount', name: 'AMOUNT' },
      { prop: 'currency', name: 'CURRENCY' },
      { prop: 'isActive', name: 'IS_ACTIVE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  /* 
    This method will be consumed by a Datasource that needs to inspect the Headers to get the total count
    Also, all searches, sort, pagination is requested to the backend
    This is why it returns Observable<any> instead of Observable<Profile>
  */
  getPaymentArticles(query: SearchParam[] = [], sort = "", order = "asc", page: number = 1, per_page: number = 10): Observable<any> {

    let params = new HttpParams();
    query.map((search: SearchParam) => params = params.set(search.field, typeof search.value == 'string' ? search.value.replace(/\s/g, '') : search.value));
    params = params
      .set(this.paramHelper.getSortParamName(), sort)
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue(order))
      .set(this.paramHelper.getPageParamName(), "" + page)
      .set(this.paramHelper.getPerPageParamName(), "" + per_page)

    if (query && query.length > 0) {
      params = params.set(this.paramHelper.getSearchFieldsParamName(), "Name");
    }

    return this.http.get<any>(this.environmentForDomain.webApiBaseUrl + BASE_URL_PAYMENT_ARTICLES, {
      params: params,
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getActivePaymentArticles(): Observable<PaymentArticle[]> {

    let params = new HttpParams();
    params = params
      .set("q", "true")
      .set(this.paramHelper.getSearchFieldsParamName(), "IsActive")
      .set(this.paramHelper.getSortParamName(), "Name")
      .set(this.paramHelper.getSortOrderParamName(), this.paramHelper.getSortOrderParamValue("asc"))
      .set(this.paramHelper.getPageParamName(), "1")
      .set(this.paramHelper.getPerPageParamName(), "100")

    return this.http.get<any>(this.environmentForDomain.webApiBaseUrl + BASE_URL_PAYMENT_ARTICLES, {
      params: params
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getPaymentArticleById(id: string): Observable<PaymentArticle> {

    return this.http.get<any>(this.environmentForDomain.webApiBaseUrl + BASE_URL_PAYMENT_ARTICLES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  savePaymentArticle(paymentArticleInfo: PaymentArticle) {

    let result: any;

    if (paymentArticleInfo.id) {
      result = this.http.post(this.environmentForDomain.webApiBaseUrl + BASE_URL_PAYMENT_ARTICLES + '/' + paymentArticleInfo.id, paymentArticleInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApiBaseUrl + BASE_URL_PAYMENT_ARTICLES, paymentArticleInfo)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }
    return result;
  }

  deletePaymentArticle(id: string) {
    return this.http.delete(this.environmentForDomain.webApiBaseUrl + BASE_URL_PAYMENT_ARTICLES + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  //Notifications or System Communications

  getNotificationsTableDataConf() {
    return [
      { prop: 'communicationType', name: 'COMMUNICATION_TYPE' },
      { prop: 'cardStatus', name: 'CARD_STATUS' },
      { prop: 'receiverType', name: 'RECEIVER_TYPE' },
      { prop: 'actions', name: 'ACTIONS' }
    ];
  }

  getSystemCommunications(): Observable<any> {
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SYSTEM_COMMUNICATIONS, {
      observe: 'response'
    }).pipe(
      catchError((error) => {
        return throwError(error);
      })
    )
  }

  saveSystemCommunication(systemCommunication: ControllerCmsNotification) {
    let result: any;
    if (systemCommunication.id) {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SYSTEM_COMMUNICATIONS + '/' + systemCommunication.id, systemCommunication)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    } else {
      result = this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SYSTEM_COMMUNICATIONS, systemCommunication)
        .pipe(
          catchError((error) => {
            return throwError(error);
          })
        )
    }

    return result;
  }

  deleteSystemCommunication(id: string) {
    return this.http.delete(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_SYSTEM_COMMUNICATIONS + '/' + id)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }

  //get all HID Mobile Part Number
  getHidMobilePartNumber(): Observable<PartNumberDetails[]> {
    return this.http.get<any>(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_GET_HID_MOBILE_PART_NUMBERS)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  saveUserApproverDetails(approverDetails: UserApproverDetails) {
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPROVERDETAILS, approverDetails)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }

  getUserApproverDetails() {
    return this.http.get(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_APPROVERDETAILS)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }

  saveCredentialColumns(columns: CredentialColumnDetail) {
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CREDENTIAL_COLUMNS, columns)
      .pipe(
        catchError((error) => {
          return throwError(error)
        })
      )
  }

  getCredentialColumns() {
    return this.http.get(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CREDENTIAL_COLUMNS)
      .pipe(
        catchError((error) => {
          return throwError(error);
        })
      )
  }

  saveCredentialBlockReason(reasons: CredentialBlockReasonDetail) {
    return this.http.post(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CREDENTIAL_BLOCK_REASON, reasons)
    .pipe(
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  getCredentialBlockReason() : Observable<CredentialBlockReasonDetail> {
    return this.http.get(this.environmentForDomain.webApi2BaseAdmincenterUrl + BASE_URL_CREDENTIAL_BLOCK_REASON)
      .pipe(
        map(body => body as CredentialBlockReasonDetail),
        catchError((error) => {
          return throwError(error);
        })
      );
  }
}
