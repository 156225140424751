import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import {IAuthenticationState} from '../../interfaces/authentication.state';
import {ReLoginRedirect,SetRequiredData,SetRequiredDataPermissions,FetchUserRoles} from './authentication.action';
import {AuthenticationService} from '../../shared/services/auth/authentication.service';
import { combineLatest } from 'rxjs';
import { tap } from 'rxjs/operators';

@State<IAuthenticationState>({
    name: 'auth',
    defaults: {
        omniCloudUserMetadata: [],
        omnicloudLoginData: null,
        omniCloudEntityContents: null,
        omniCloudEntities: []
    }
})
@Injectable()

export class AuthenticationState {

    constructor(private authenticationService: AuthenticationService){}
    
    @Selector() static omnicloudmetadata(authState: IAuthenticationState) {
        return authState.omniCloudUserMetadata;
    }

    @Selector() static omniclouduserdata(authState: IAuthenticationState) {
        return authState.omnicloudLoginData;
    }

    @Selector() static omnicloudEntityContents(authState: IAuthenticationState) {
        return authState.omniCloudEntityContents;
    }

    @Selector() static omnicloudentities(authState: IAuthenticationState) {
        return authState.omniCloudEntities;
    }

    @Action(ReLoginRedirect)
    reLoginRedirect(stateContext: StateContext<IAuthenticationState>) {
        return stateContext.dispatch(new SetRequiredDataPermissions());
    }

    @Action(SetRequiredData)
    SetRequiredData(stateContext: StateContext<IAuthenticationState>) {
        return this.authenticationService.omniCloudUser().pipe(
        tap(userData => {
            stateContext.patchState({
                omnicloudLoginData: userData
            });
        }));
    }

    @Action(SetRequiredDataPermissions)
    setRequiredDataPermissions(stateContext: StateContext<IAuthenticationState>) {
        this.authenticationService.omniCloudMetadata().subscribe( data => {
            stateContext.patchState({
                omniCloudUserMetadata: data.metadata,
                omniCloudEntities: data.entities,
                omniCloudEntityContents: data.entityContents
            });
        });
    }

}