export class ProviderKeyFieldDB {
    public static providerKeyFields = [
        {
            "entity": "Person",
            "fields": [
                "PersonNumber",
                "FirstName",
                "MiddleName",
                "LastName",
                "PhoneNumber",
                "MobileNumber",
                "FreeField1",
                "FreeField2",
                "FreeField3",
                "FreeField4",
                "FreeField5",
                "FreeField6",
                "FreeField7",
                "FreeField8",
                "FreeField9",
                "FreeField10",
                "FreeField11",
                "FreeField12",
                "FreeField13",
                "FreeField14",
                "FreeField15",
                "FreeField16"

            ]
        },
        {
            "entity": "Cardholder",
            "fields": [
                "ProfileNumber",
                "Email"
            ]
        }
    ]
}