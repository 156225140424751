import { Pipe, PipeTransform } from '@angular/core';
import { IMetadata } from '../../interfaces/omni-cloud/user-metadata.interface';
import { SystemLabel } from '../../interfaces/omni-cloud/system-label.interface';
@Pipe({
  name: 'displayLabel'
})
export class DisplayLabelPipe implements PipeTransform {

  transform(attribute: string, usermetadata: Array<IMetadata>, entity: string, systemLabels: Array<SystemLabel>): string {
    const index = usermetadata
        .findIndex(u => u.attribute.name.toLowerCase() === attribute.toLowerCase() &&
            (entity != null ? u.attribute.entity.toLocaleLowerCase() === entity.toLowerCase() : true));
    if (index === -1) {
        return attribute;
    }

    if (systemLabels != null) {
      const systemLabelIndex = systemLabels.findIndex(s => s.attributeId === usermetadata[index].attribute.id);
      if (systemLabelIndex === -1) {
          return usermetadata[index].attribute.translation;
      }
      return systemLabels[systemLabelIndex].label;
    }
    return usermetadata[index].attribute.translation;
  }

}
