<div id="subscribed-apps">
    <mat-card class="p-0">

        <mat-card-title class="">
            <div class="card-title-text"> 
                {{ "TOP_NAV.SUBSCRIPTION.INFO" | translate }} </div>
            <mat-divider></mat-divider>
        </mat-card-title>
        <mat-card-subtitle class="card-subtitle-text">
            {{customer.customerName}}
        </mat-card-subtitle>
        <mat-card-content>

            <ul>
                <li *ngFor="let sub of subscriptions">
                    <!--<a target="_blank">-->
                        <img src="assets/images/logos/{{sub.logo}}" alt="" class="subscription-logo" />
                        <span class="font-weight-bold">{{sub.ProductName}}</span>
                        <span class="ml-1">({{ "TOP_NAV.SUBSCRIPTION.MAX_IDS" | translate}} : {{sub.MaximumActiveIds}})</span>
                    <!--</a>-->
                </li>
            </ul>
        </mat-card-content>
        <mat-card-footer>
          <mat-card-actions class="form-ctrl-wrapper">
            <button 
                mat-raised-button 
                (click)="closePanel(); $event.preventDefault()"> {{ 'FORM_CTRL.CLOSE' | translate }} </button>
          </mat-card-actions>
        </mat-card-footer>

    </mat-card>
</div>