<div id="conditionsDialog">
  <mat-card class="p-0">
    <mat-card-title class="">
        <div class="card-title-text"> New Condition </div>
        <mat-divider></mat-divider>
    </mat-card-title>
    <mat-card-content>
      <ng-container *ngIf="userMetadata$ | async as usermetadata">
        <ng-container *ngIf="entityContents$| async as entityContents">
          <ng-container *ngIf="entitiesList$ | async as entitiesList">
            <form role="form" [formGroup]="conditionsForm" (ngSubmit)="onSubmit()">
              <div class="pb-1">
                <mat-form-field class="full-width">
                    <mat-label>{{ "SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.ATTRIBUTE" | translate }}</mat-label>
                    <mat-select attr.aria-label="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.ATTRIBUTE' | translate }}"
                    [placeholder]="attributeId ? attributeId : 'Attribute'"
                    name="attribute" formControlName="attribute">
                        <mat-optgroup *ngFor="let entity of getEntities(entitiesList)" [label]="entity.translation">
                            <mat-option [disabled]="isDisabled(usermetadata, entity)" class="whiteText matOptions"
                            *ngFor="let data of getAttributes(usermetadata, entity)" [value]="data.attribute.id">
                            {{data.attribute.translation}}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
                <small 
                *ngIf="conditionsForm.controls['attribute'].hasError('required') && conditionsForm.controls['attribute'].touched" 
                class="form-error-msg"> {{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.VALIDATION.ATTRIBUTE_REQUIRED' | translate }} </small>
              </div>
              <div class="pb-1">
                <mat-form-field class="full-width">
                    <input
                    matInput
                    name="value"
                    formControlName="value"
                    placeholder="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.VALUE' | translate }}">
                </mat-form-field>
                <small 
                *ngIf="conditionsForm.controls['value'].hasError('required') && conditionsForm.controls['value'].touched" 
                class="form-error-msg"> {{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.VALIDATION.VALUE_REQUIRED' | translate }} </small>
              </div>
              <div class="pb-1">
                <mat-form-field class="full-width">
                    <input
                    matInput
                    name="prefix"
                    formControlName="prefix"
                    placeholder="{{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.PREFIX' | translate }}">
                </mat-form-field>
                <small 
                *ngIf="conditionsForm.controls['prefix'].hasError('required') && conditionsForm.controls['prefix'].touched" 
                class="form-error-msg"> {{ 'SETUP.CONTROLLER_CMS.CUSTOM_FIELD_LABELS.VALIDATION.PREFIX_REQUIRED' | translate }} </small>
              </div>
            </form>
          </ng-container>
        </ng-container>
      </ng-container>
    </mat-card-content>
    <mat-card-footer>
      <mat-card-actions class="yes-no-wrapper">
        <button mat-raised-button (click)="onSubmit()"> {{ 'FORM_CTRL.SAVE' | translate }} </button>
        <button mat-raised-button (click)="onNoClick()" cdkFocusInitial>  {{ 'FORM_CTRL.CANCEL' | translate }} </button>
      </mat-card-actions>
    </mat-card-footer>
  </mat-card>
</div>